.rating-stars, .no-rating-stars {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rating-value, .no-rating-value {
    color: var(--racecheck-gold);
    font-size: 16px;
    font-weight: 600;
    margin: 0 3px 0 0 !important;
}

.no-rating-value {
    color: var(--text-inactive);
    font-size: 14px;
    font-weight: 500;
}

.rating-out-of {
    font-size: 13px;
    letter-spacing: 2px;
    margin-left: 2px;
    font-weight: 500;
}

.rating-stars > svg, .no-rating-stars > svg {
    margin-right: 2px;
    width: 19px;
    height: 17px;
}

.no-rating-stars > svg {
    filter: grayscale(1);
    opacity: .4;
}

.unrated-rating-stars {
    filter: grayscale();
}