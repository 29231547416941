.modal-backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(128, 128, 128, 0.479);
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(3px);
    z-index: 5000;
}

.modal-padding {
    display: flex;
    height: 70px;
    min-height: 40px;
    flex: 0 1000 70px;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--very-light-grey);
    padding: 15px 0px 0 0px;
}

.modal-container {
    background-color: #fff;
    margin: 0 50px;
    width: 650px;
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.modal-infobox-container {
    width: 750px;
    position: relative;
    border-top: 2px solid var(--racecheck-green);
}

.modal-header {
    padding: 10px 25px 0;
    border-bottom: 1px solid #e8ecee;
    position: relative;
}

.modal-close-btn {
    border: none;
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    margin: 10px 10px 0 0;
    cursor: pointer;
}

.modal-close-btn svg {
    width: 32px;
    height: 32px;
    margin: 0;
    fill: var(--text-inactive);
    stroke: var(--text-inactive);
}

.modal-header-title {
    margin: 0;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 8px;
}

.modal-title-no-nav {
    margin-bottom: 10px;
}

.modal-header-nav {
    display: flex;
    margin-top: 10px;
}

.modal-nav-list {
    display: flex;
}

.modal-nav-item {
    color: var(--text-inactive);
    font-size: 15px;
    font-weight: 500;
}

.modal-nav-item:not(:last-child) {
    margin-right: 30px;
}

.modal-nav-item-link {
    color: var(--text-inactive);
    text-decoration: none;
    position: relative;
    cursor: pointer;
}

.modal-link {
    display: inline-block;
    padding-bottom: 10px;
}

.help-text {
    font-size: 13px;
    line-height: 16px;
    color: rgba(88, 88, 88, 0.8);
}

.help-text a {
    color: var(--racecheck-green);
    font-weight: 500;
}
.modal-link:hover {
    box-shadow: inset 0 -3px #cecece;
}

.modal-link-selected {
    box-shadow: inset 0 -3px var(--racecheck-green);
    color: var(--text-active);
}

.modal-link-selected:hover {
    box-shadow: inset 0 -3px var(--racecheck-green);
}

.modal-scrollable {
    min-height: 1px;
    overflow-y: auto;
}

.modal-section-header {
    font-size: 16px;
    margin: 0 0 10px 0;
    width: 100%;
}

.modal-content {
    margin: 0 25px;
}

.modal-content.modal-contact-us h3 {
    margin: 20px 0 5px;
}
.modal-content.modal-contact-us .modal-section-subheader {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 18px;
}

.modal-content.modal-contact-us textarea {
    min-height: 80px;
    padding: 6px 11px;
}

.modal-content.modal-contact-us textarea::placeholder {
    font-size: 16px;
    line-height: 19px;
    color: rgba(88, 88, 88, 0.5);
}

.modal-content.modal-contact-us .buttons-wrap {
    display: flex;
    justify-content: space-between;
}
.modal-content.modal-contact-us .buttons-wrap .back {
    border: 1px solid #9CA6AF;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 8px 19px;
    background: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}
.modal-content.modal-contact-us .buttons-wrap .submit {
    background: #4ABF6C;
    border-radius: 50px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    border:none;
    text-align: center;
    color: #FFFFFF;
    padding: 8px 40px;
}

.modal-content-section {
    padding: 20px 0;
}

.modal-content-section.cancel {
    padding-top: 13px;
}

.modal-content-section.cancel p {
    font-size: 12px;
}
.modal-content p {
    margin: 5px 0;
}

.modal-content-section:not(:last-child) {
    border-bottom: 1px solid #e8ecee;
}

.modal-content-section.organisation-settings {
    border-bottom: 1px solid #e8ecee;
}

.modal-subtext {
    font-size: 14px;
}

.modal-content-link {
    color: var(--racecheck-green);
    font-weight: 600;
    cursor: pointer;
}

.modal-content-link:hover {
    color: var(--racecheck-green-dark);
    text-decoration: underline;
}

.modal-input-label {
    font-size: 15px;
    color: var(--text-inactive);
    font-weight: 500;
    min-height: 18px;
}

.modal-input-label-disabled {
    opacity: .4;
}

.modal-input {
    height: 32px;
    padding: 0 7px;
    margin-bottom: 10px;
    border: 1px solid #e0e6e8;
}

.modal-input-select {
    height: 32px;
    border: 1px solid #e0e6e8;
    background-position: calc(100% - 20px) 0.8em,
    calc(100% - 15px) 0.8em,
    calc(100% - 1.5em) 1em !important;
    padding: 0 7px;
    margin-bottom: 10px;
}

.modal-input-disabled {
    opacity: .4;
}

.modal-input-row {
    display: flex;
    justify-content: space-between;
}

.modal-input-row:not(:last-child){
    margin-bottom: 10px;
}

.modal-input-group {
    width: 100%;
    margin-bottom: 5px;
}

.modal-input-group-half {
    width: 48%;
    margin-bottom: 5px;
}

.modal-input-group-quarter {
    display: flex;
    justify-content: space-between;
}

.modal-input-group-quarter > .modal-input-group-half {
    width: 47% !important;
}

.modal-btn-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    width: 100%;
}

.modal-collapsed-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.profile-photo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0 20px 0;
}

.profile-photo-loader {
    background-color: #E8ECED;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin: 0 20px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.profile-photo-loader-img {
    height: 100px;
    margin: -1px 0 0 0;
    opacity: .5;
}

.profile-pic-preview {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 20px 0 0;
    background-color: #E8ECED;
}

.modal-form-btn {
    width: auto;
    margin-bottom: 0 !important;
    margin-right: 0;
}

.modal-form-btn:not(:last-child) {
    margin-right: 15px;
}

.modal-centered-intro {
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px !important;
}

.modal-split-container {
    display: flex;
    flex-direction: row;
}

.modal-split-child-half {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px 30px;
}

.msch-date-halves {
    min-height: 270px;
    max-height: 270px;
}

.modal-split-child-half:nth-child(1){
    border-right: 1px solid #e8ecee;
    padding: 10px 30px 10px 0;
    justify-content: flex-start;
}

.modal-split-child-half > .modal-centered-intro {
    margin-bottom: 5px !important;
}

/* NOTIFICATION SETTINGS */

.settings-notifications-container {
    margin: 0;
}

.notification-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settings-notifications-container > .notification-row:not(:last-of-type) {
    margin-bottom: 15px;
}

.notification-name {
    font-size: 15px;
    font-weight: 600;
    color: var(--text-inactive);
    margin: 0 0 1px 0 !important;
}

.notification-desc {
    font-size: 14px;
    color: var(--text-inactive);
    opacity: .75;
    margin: 0 !important;
}

.notification-status {
    display: flex;
    align-items: center;
}

.ns-tag {
    font-size: 15px;
    color: var(--text-inactive);
    margin: 0 10px 0 0 !important;
    font-weight: 600;
}

.ns-toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    margin: 0;
}

.ns-toggle-input {
    display: none;
}

.ns-toggle-slider {
    border-radius: 20px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(214, 214, 214);
    -webkit-transition: .3s;
    transition: .3s;
}

.ns-toggle-slider:hover {
    background-color: rgb(206, 206, 206);
}

.ns-toggle-input:checked + .ns-toggle-slider {
    background-color: var(--racecheck-green);
}

.ns-toggle-input:checked + .ns-toggle-slider:hover {
    background-color: var(--racecheck-green-dark);
}

.ns-toggle-input:checked + .ns-toggle-slider:before {
    transform: translateX(24px);
}

.ns-toggle-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

/* END OF NOTIFICATION SETTINGS */

.renew-modal-date {
    font-weight: 600;
    font-size: 25px;
    margin-top: 0;
    text-align: center;
}

.renew-modal-date-placeholder {
    opacity: .5;
}

.account-profile-image-input {
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.report-review-modal-content {
    margin-bottom: 18px;
}

.report-review-modal-review-details {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #E8ECED;
    padding-bottom: 15px;
}

.report-review-modal-review-details > p {
    margin: 0 0 5px 0;
    color: var(--text-inactive);
}

.report-review-modal-review-details > .bullet {
    margin: 0 8px 5px 8px !important;
}

.report-review-modal-review-data {
    padding-top: 18px;
    padding-bottom: 12px;
    border-top: 1px solid #E8ECED;
}

.rrmrd-row {
    display: flex;
    width: 100%;
}

.rrmrd-row > p {
    margin: 0 0 10px 0;
}

.rrmrd-label {
    width: 30%;
    color: var(--text-inactive)
}

.rrmrd-info {
    width: 70%;
    font-weight: 500;
}

.inline-select-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.inline-select-container > p {
    margin: 0;
}

.inline-select-container > select {
    background-position: calc(100% - 20px) .7em,
                         calc(100% - 15px) .7em,
                         calc(100% - 2.5em) 0.5em;
}

.inline-text-dropdown {
    height: 100%;
    font-weight: 500;
    color: var(--text-normal);
    padding: 5px 35px 5px 10px;
    margin-left: -10px;
    border: 1px solid #e0e6e8;
    border-radius: 3px;
}

.inline-text-dropdown:focus {
    outline: none;
    border: 1px solid var(--racecheck-green);
}

.report-textarea {
    resize: vertical;
    padding: 6px 10px;
    height: 100%;
    margin: 0 0 0 -10px;
    width: 70%;
}

.report-review-modal-review-data > div > label {
    align-items: flex-start;
    padding: 8px 8px;
    line-height: normal;
    font-weight: 500;
    margin-bottom: 0;
}

.report-review-modal-review-data > div > label > .checkmark {
    width: 40px;
}

.report-review-modal-review-data > div > label > .checkmark:after {
    top: 12px;
}

.modal-warning-container {
    margin: 5px 0 10px 0 !important;
}

.modal-warning-container > p {
    font-size: 13px;
}

.org-settings-permissions-warning {
    margin-top: -10px !important;
    margin-bottom: 15px !important;
    align-items: flex-start !important;
    border: 1px solid #e2e5e6;
    border-radius: 4px;
    padding: 5px;
    background-color: #fbfbfb;
}

.org-settings-permissions-warning > p {
    margin: 0;
}

/* ONBOARDING */

.onboarding-modal-container {
    width: 800px;
    padding: 20px;
}

.onboarding-modal-content > .modal-content {
    margin: 0;
}

.onboarding-modal-content > .modal-content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.onboarding-modal-content > .modal-content > .modal-content-section {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.onboarding-step-content {
    text-align: center;
}

.onboarding-step-title {
    font-size: 28px;
    font-weight: 600;
}

.onboarding-img-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 25px;
}

.onboarding-img-caption {
    font-weight: 500;
}

.onboarding-img-caption > span {
    border-bottom: 2px solid #97e4ad;
}

.onboarding-step-copy {
    margin: 10px 0 0 0 !important;
    font-weight: 400;
    color: var(--text-inactive);
    line-height: 18px;
    padding: 0 20px;
}

.onboarding-img {
    width: 80%;
    margin: 20px 0 0 0;
}

.onboarding-modal-btns {
    justify-content: center;
    margin: 15px 0 5px 0;
}

.onboarding-modal-btns > .btn {
    width: 50% !important;
    overflow: hidden;
}

/* END OF ONBOARDING */

/* CONTACT US MODAL */

.contact-modal-section > .modal-section-header {
    color: var(--text-normal);
    font-weight: 500;
    margin: 0 0 10px 0 !important;
}

.contact-modal-section > .modal-section-subheader {
    margin-bottom: 15px;
}

.contact-modal-section > form > .modal-input-row {
    margin: 0 !important;
}

.contact-modal-section > form > .modal-input-row {
    margin: 0 !important;
}

.contact-modal-section > form textarea {
    height: 90px;
    max-width: 100%;
    padding: 5px 7px;
}

/* END OF CONTACT US MODAL */

/* RESPONSIVE */

@media screen and (max-width: 900px) {
  
    /* MODAL */
  
    .modal-container {
        width: 95%;
    }
  
    /* END OF MODAL */
  
  }
  
  /* END OF RESPONSIVE*/
