::selection {
    background: rgba(114, 214, 144, 0.603);
}
::-moz-selection {
    background: rgba(114, 214, 144, 0.603);
}

.org-dash-container {
    display: flex;
}

.create-organisation-wrapper {
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
}

.create-organisation-wrapper img{
    margin: 20px 0;
}

.squared-btn {
    background-color: var(--racecheck-green);
    color: #fff;
    font-weight: 600;
    padding: 10px;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border: 0px solid #000000;
    letter-spacing: .4px;
    transition: background-color .15s,color .15s ease-in-out;
    margin: 0;
    cursor: pointer;
}

.squared-btn:hover {
    background-color: var(--racecheck-green-dark);
}

/* MAIN NAVIGATION */

.main-nav-container {
    background-color: #fff;
    min-width: 210px;
    max-width: 210px;
    height: 100vh;
    padding: 15px 0;
    position: fixed;
    /* -webkit-box-shadow: 0px 2px 4px 0px rgba(195,200,202,0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(195,200,202,0.5);
    box-shadow: 0px 2px 4px 0px rgba(195,200,202,0.5); */
    border-right: 1px solid #f3f3f3;
}

.nav-logo-container {
    margin: 0 0 20px 0;
    padding: 0 20px 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-brand {
    width: 130px;
    height: 100%;
    margin: 0;
}

.nav-logo-container > svg {
    width: 22px;
    height: 25px;
    margin-left: 5px;
    stroke: var(--text-inactive);
    cursor: pointer;
}

.nav-logo-container > svg:hover {
    width: 22px;
    height: 25px;
    stroke: var(--text-active);
    cursor: pointer;
}

.side-nav-links {
    color: var(--text-inactive);
}

.side-nav-item {
    padding: 0 0 0 20px;
    margin: 0 0 5px 0;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 45px;
    border: none;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    background-color: #fff;
    cursor: pointer;
    text-decoration: none;
}

.side-nav-item > p {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: var(--text-inactive);
    margin: 0;
    line-height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.side-nav-item > p > svg {
    height: 17px;
    min-height: 17px;
    max-height: 17px;
    width: 17px;
    min-width: 17px;
    max-width: 17px;
    margin: 0 15px 0 10px;
    color: var(--racecheck-green);
}

.side-nav-item > * {
    pointer-events: none;
}

.side-nav-link {
    text-decoration: none;
    color: var(--text-inactive);
}

.side-nav-active {
    color: var(--text-active);
    border-right: 3px solid var(--racecheck-green);
    font-weight: 600;
}

.side-nav-active p {
    color: var(--text-active);
    font-weight: 600;
}

.side-nav-item:hover {
    background-color: #f2f2f2;
}

.side-nav-item-disabled:hover {
    cursor: not-allowed;
}

@keyframes tilt-shaking {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(10deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-10deg); }
    100% { transform: rotate(0deg); }
}

.side-nav-item-disabled:hover > p > svg {
    animation: tilt-shaking 0.25s 2;
}

.side-nav-item svg {
    width: 25px;
    min-width: 25px;
    height: 25px;
    min-height: 25px;
    margin: -2px 15px 0 0;
    fill: var(--text-inactive);
    stroke: var(--text-inactive) !important;
}

.side-nav-active svg {
    fill: var(--racecheck-green);
    stroke: var(--racecheck-green) !important;
}

.side-nav-item-disabled > p {
    color: var(--racecheck-silver);
}

.side-nav-item-disabled svg {
    fill: var(--racecheck-silver);
    stroke: var(--racecheck-silver) !important;
}

.nav-promotion {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: #E8ECED;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border: 0px solid #000000;
    width: 100%;
    height: fit-content;
    max-height: 160px;
}

.nav-promotion > svg {
    width: 140px;
    height: 100%;
    position: relative;
    margin: -80px auto 0 auto;
    filter: drop-shadow( 0px -2px 3px rgba(122, 122, 122, 0.1));
}

.nav-promotion-copy {
    margin: 15px 0;
    padding: 0 5px;
    font-size: 14px;
}

.nav-promotion-img {
    margin: -60px auto -2px auto;
    width: 110px;
    position: relative;
    filter: drop-shadow( 0px -2px 3px rgba(122, 122, 122, 0.1));
}

.nav-promotion > .btn {
    margin: 0 !important;
    background-color: #f5f5f5 !important;
}

.nav-promotion > .btn:hover {
    background-color: #fff !important;
}

.nav-promotion > a {
    text-decoration: none;
}

.nav-promotion > a > p {
    margin: 0;
}

.main-nav-footer {
    text-align: left;
    padding: 15px 20px;
    position: absolute;
    bottom: 0;
    background-color: #fff;
}

.footer-list {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 11px;
}

.footer-link, .footer-copyright {
    display: inline;
    color: var(--text-inactive);
    opacity: .6;
}

.footer-link-a {
    text-decoration: none;
    cursor: pointer;
    color: var(--text-inactive);
}

.footer-link-a:hover {
    text-decoration: underline !important;
}

.main-nav-closed {
    transform: translateX(-230px) !important;
    width: 0px;
}

.main-nav-overlay {
    z-index: 11000;
}

/* END OF MAIN NAVIGATION */

/* NAV BAR HELPER */

.nav-bar-helper-modal-content {
    margin: 0 !important;
}

.nav-bar-helper-modal-content > .modal-content-section {
    padding: 0 !important;
}

.faq-item {
    padding: 10px 17px;
    cursor: pointer;
}

.faq-item:not(:first-child) {
    border-top: 1px solid #DBDBDB;
}

.faq-q {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
}

.faq-q > svg {
    stroke: var(--text-inactive);
    width: 18px;
    height: 18px;
    opacity: .75;
}

.faq-item:hover > .faq-q > svg {
    stroke: var(--racecheck-green);
    opacity: 1;
}

.faq-a {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
}

.faq-a.open {
    height: auto;
}

.faq-a > p {
    padding-right: 10px;
    font-size: 15px;
}

/* END OF NAV BAR HELPER */

/* MAIN SECTION */

.section-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 940px;
    text-align: left;
    /* overflow-x: hidden; */
    margin-left: 210px;
    position: relative;
    width: calc(100% - 210px);
}

.section-container-main-nav-closed {
    width: 100% !important;
    margin-left: 0 !important;
}

/* SECTION NAVIGATION */

.section-top-container {
    background-color: #fff;
    width: calc(100% - 210px);
    height: 75px;
    padding: 0 0 0 20px;
    -webkit-box-shadow: 2px 0px 1px 0px rgba(195,200,202,0.5);
    -moz-box-shadow: 2px 0px 1px 0px rgba(195,200,202,0.5);
    box-shadow: 2px 0px 1px 0px rgba(195,200,202,0.5);
    display: flex;
    position: fixed;
    z-index: 2000;
}

.section-top-container-main-nav-closed {
    width: 100% !important;
}

.section-top-menu-icon {
    margin: 0 20px 0 -5px;
}

.section-top-menu-icon > svg {
    width: 30px;
    height: 80px;
    stroke: var(--text-inactive);
    cursor: pointer;
}

.section-top-menu-icon > svg:hover {
    stroke: var(--text-active);
}

.section-top-header-and-nav {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 1px;
    height: 100%;
}

.section-header {
    margin: 13px 0 0 0;
    font-size: 22px;
    font-weight: 600;
    width: 100%;
}

.widget-label {
    font-size: 15px;
    font-weight: 600;
    padding: 3px 13px;
    border-radius: 20px;
    text-align: center;
}

.widget-label > span {
    margin: 0;
}

.widget-label.widget-premium {
    color: #14911f;
    background-color:  #81d7946b;
}

.widget-label.widget-free {
    background-color: var(--very-light-grey);
    color: var(--dim-grey);
}

.section-header > .section-header-events {
    display: flex;
    align-items: center;
}

.section-header-events > .separator {
    opacity: 0.3;
    color: var(--text-inactive);
}

.section-header > .section-header-events > span {
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.section-header > .section-header-events > a {
    color: var(--text-normal);
    margin-right: 8px;
}

.section-nav-container {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-start;
    max-width: 100%;
}

.section-nav {
    display: flex;
}

.section-nav-mobile {
    display: none;
}

.section-nav-list {
    display: flex;
}

.section-nav-item {
    color: var(--text-inactive);
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
}

.section-nav-item:not(:last-child) {
    margin-right: 25px;
}

.section-tab-notification {
    width: 15px;
    height: 15px;
    position: absolute;
    background-color: rgb(255, 78, 78);
    top: -6px;
    right: -16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.section-tab-notification > span {
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    margin-top: -2px;
}

.section-nav-item-link {
    color: var(--text-inactive);
    text-decoration: none;
    position: relative;
}

.section-link {
    display: inline-block;
    padding-bottom: 10px;
}

.section-link:hover {
    box-shadow: inset 0 -3px #cecece;
}

.section-link-selected {
    box-shadow: inset 0 -3px var(--racecheck-green);
    color: var(--text-active);
}

.section-link-disabled {
    color: var(--racecheck-silver) !important;
    cursor: not-allowed !important;
}

.section-link-disabled:hover {
    box-shadow: none;
}

.section-link-selected:hover {
    box-shadow: inset 0 -3px var(--racecheck-green);
}

.beta-tag {
    display: inline-block;
    color: rgb(216, 66, 66);
    font-size: 10px;
    font-family: monospace;
    pointer-events: none;
    margin-left: 5px;
    top: 0;
    position: absolute;
    letter-spacing: .5px;
    font-weight: 600;
}

.section-top-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.section-top-profile-img {
    width: 44px;
    height: 44px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0;
}

.section-top-circle-btn {
    border:none;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E8ECED;
    margin: 0 20px 0 0;
    border-radius: 50%;
    cursor: pointer;
}

.section-top-circle-btn:hover {
    background-color: #dddddd;
}

.section-top-circle-btn svg {
    width: 28px;
    height: 28px;
    margin: 0;
    fill: var(--text-inactive);
    stroke: var(--text-inactive);
}

.section-top-user {
    border: none;
    height: 46px;
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
    border-radius: 50px;
}

.section-top-user-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 15px;
}

.section-top-user-details h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #3E3E3E;
    margin: 0 0 5px;
}

.stud-name {
    margin: 0;
    font-weight: 500;
}

.stud-org {
    margin: 0;
}

.user-label {
    font-size: 11px;
    font-weight: 600;
    padding: 3px 8px;
    border-radius: 25px;
    text-align: center;
    width: fit-content;
}

.user-label.label-free {
    background-color: var(--very-light-grey);
    color: var(--dim-grey);
}

.user-label.label-premium {
    color: #14911f;
    background-color: rgba(129, 215, 148, 0.4);;
}

.nav-bar-dropdown {
    height: 45px;
    width: initial;
    border-radius: 0 50px 50px 0;
    background-color: #E8ECED;
    border: none;
    margin: 0 15px 0 0;
    padding: 0 35px 0 20px;
    font-weight: 500;
    color: var(--text-normal);
    font-size: 16px;
    box-sizing: border-box;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #E8ECED, #E8ECED);
    background-position: calc(100% - 20px) 1.35em,
    calc(100% - 15px) 1.35em,
    calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px,
    5px 5px,
    1px 1.5em;
    background-repeat: no-repeat;
}

.nav-bar-dropdown:hover {
    background-color: #dddddd;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #dddddd, #dddddd);
}

.section-top-logout-btn > svg {
    margin: 0 0 0 3px !important;
}

.section-top-settings-btn {
    margin-right: 15px !important;
}

.section-top-dropdown-btn > svg {
    width: 20px;
    height: 20px;
    margin-top: 3px;
}

.section-top-dropdown-close-btn > svg {
    width: 20px;
    height: 20px;
    margin-left: -2px;
    margin-top: 2px;
}

.section-top-dropdown-close-btn {
    transform: rotate(180deg);
    transform-origin: center;
}

.section-top-notification-btn > svg {
    width: 25px;
    height: 25px;
}

.section-top-user-separator {
    border-right: 1px solid #e0e0e0;
    height: 80%;
    width: 1px;
}

/* .section-top-org-switcher {
    max-width: 200px;
    overflow: hidden;
    margin: 0 15px 0 0;
    border-radius: 0 50px 50px 0;
} */

/* END OF SECTION NAVIGATION */

/* PROFILE SIDEBAR */

.profile-sidebar-container {
    display: none;
    width: 320px;
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 25px;
    transform: translateX(100%);
    transition: transform 0.2s ease-out;
}

.profile-sidebar-open {
    transform: translateX(0);
    -webkit-box-shadow: 0px -5px 10px 5px rgba(195,200,202,0.5);
    -moz-box-shadow: 0px -5px 10px 5px rgba(195,200,202,0.5);
    box-shadow: 0px -5px 10px 5px rgba(195,200,202,0.5);
}

.profile-utility-bar {
    display: flex;
    justify-content: space-between;
}

.profile-utility-bar-btn {
    cursor: pointer;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
}

.profile-utility-bar-btn p {
    font-size: 16px;
    line-height: 32px;
    margin: 0 0 0 7px;
    font-weight: 600;
    color: var(--text-inactive);
}

.profile-utility-bar-btn svg {
    width: 32px;
    height: 32px;
    margin: 0;
    fill: var(--text-inactive);
    stroke: var(--text-inactive);
}

.profile-summary {
    text-align: center;
    margin-top: 30px;
}

.profile-summary-pic {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    margin-bottom: 20px;
    object-fit: cover;
}

.profile-summary-username {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.profile-summary-details {
    margin-top: 5px;
    color: var(--text-inactive);
}

.profile-notifications {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.profile-notifications-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    font-weight: 600;
    font-size: 16px;
}

.notifications-count {
    color: var(--text-inactive);
    opacity: .6;
    font-weight: 500;
    font-size: 15px;
}

/* END OF PROFILE SIDEBAR */

.section-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 95px;
    padding-bottom: 20px;
}

.section-content-with-banner {
    padding-top: 135px !important;
}

.section-content-no-bottom-pad {
    padding-bottom: 0px !important;
}

.section-loading-container {
    width: 100%;
    height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.centered-loading-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 40px 0;
}

.fullpage-loading-container {
    width: 100%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* END OF MAIN SECTION */

/* START OF NO DATA PLACEHOLDER */

.no-data-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
}

.sentiment-copy > .no-data-container {
    margin-top: 20px !important;
}

.no-data-img {
    width: 250px;
    margin-bottom: 30px;
}

.no-data-header {
    font-size: 25px;
    font-weight: 600;
    color: var(--text-inactive);
    margin: -15px 0 10px 0;
    text-align: center;
}

.no-data-copy {
    color: var(--text-inactive);
    margin: 0 0 5px 0;
    text-align: center;
    max-width: 600px;
}

.no-data-inline-link {
    text-decoration: none;
    color: var(--racecheck-green);
    font-weight: 500;
    cursor: pointer;
}

.no-data-inline-link:hover {
    text-decoration: underline !important;
}

/* END OF NO DATA PLACEHOLDER */

/* LOGGED IN INVITE */

.team-invite {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
    text-align: left;
    overflow-x: hidden;
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: var(--background-color);
    text-align: center;
    padding: 2rem;
}

.team-invite .log_in_container {
    margin-top: 2rem;
}

/* END OF LOGGED IN INVITE */

/* NAV BAR DROPDOWN MENU */

.nav-bar-dropdown-menu {
    width: 300px;
    background-color: #fff;
    border: 1px solid #f3f3f3;
    position: absolute;
    right: 15px;
    top: 70px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px -2px rgba(184, 184, 184, 0.5);
    padding: 8px 0;
    max-height: 80vh;
    overflow-y: scroll;
}

.nbdm-item {
    padding: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.nbdm-item:hover {
    background-color: #f2f2f2;
}

.nbdm-title-item {
    padding: 8px;
    display: flex;
    align-items: center;
}

.nbdm-a {
    text-decoration: none;
}

.nbdm-item-icon {
    border: none;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #E8ECED; */
    margin: 0 15px 0 5px;
    border-radius: 50%;
    cursor: pointer;
}

.nbdm-item-icon > svg {
    width: 25px;
    height: 25px;
    fill: var(--text-inactive);
    stroke: var(--text-inactive);
}

.nbdm-item-selected {
    position: absolute;
    right: 13px;
}

.nbdm-item-selected > svg {
    width: 22px;
    height: 22px;
    stroke: var(--racecheck-green);
}

.nbdm-back-icon {
    border: none;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #E8ECED; */
    margin: 0 15px 0 5px;
    border-radius: 50%;
    cursor: pointer;
}

.nbdm-back-icon:hover {
    background-color: #E8ECED;
}

.nbdm-back-icon > svg {
    width: 23px;
    height: 23px;
    fill: var(--text-normal);
    stroke: var(--text-normal);
}

.nbdm-item-settings > .nbdm-item-icon > svg {
    margin-top: -1px;
}

.nbdm-item-logout > .nbdm-item-icon > svg {
    margin-left: 3px;
}

.nbdm-item-title {
    font-weight: 500;
    color: var(--text-normal);
    font-size: 16px;
    margin: 0;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nbdm-section-title {
    font-weight: 600;
    color: var(--text-normal);
    font-size: 19px;
    margin: 0;
}

.nbdm-separator {
    width: 100%;
    height: 1px;
    background-color: #E8ECED;
    margin: 8px 0;
}

.nbdm-profile {
    display: flex;
    padding: 8px;
    align-items: flex-start;
}

.nbdm-profile-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 15px 0 5px;
}

.nbdm-profile-name {
    margin: 0 0 2px 0;
    color: var(--text-normal);
    font-size: 17px;
    font-weight: 500;
}

.nbdm-profile-org {
    margin: 0;
    color: var(--text-normal);
    font-size: 15px;
}

.nbdm-profile-edit-btn {
    color: var(--racecheck-green);
    font-weight: 500;
    font-size: 15px;
    margin: 8px 0 0 0;
    cursor: pointer;
}

.nbdm-profile-edit-btn:hover {
    text-decoration: underline;
}

/* END OF NAV BAR DROPDOWN MENU */

/* SECTION NAV MORE MENU */

.section-more-link {
    position: relative;
}

.section-nav-more-menu {
    background-color: #fff;
    border: 1px solid #f3f3f3;
    position: absolute;
    left: 0;
    top: 28px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px -2px rgba(184, 184, 184, 0.5);
    padding: 8px 0;
}

.section-nav-more-menu-item {
    text-decoration: none;
}

.section-nav-more-menu > .section-nav-more-menu-item > p {
    margin: 0;
    padding: 8px 40px 8px 25px;
    cursor: pointer;
    color: var(--text-normal);
    font-size: 15px;
    text-decoration: none;
}

.section-nav-more-menu > .section-nav-more-menu-item > p:hover {
    background-color: #E8ECED;
}

.section-nav-more-menu-item-active > p {
    border-right: 3px solid var(--racecheck-green);
    color: var(--text-active) !important;
}

/* END OF SECTION NAV MORE MENU */

/* RESPONSIVE */

@media screen and (max-width: 900px) {

    .section-nav {
        display: none;
    }
    
    .section-nav-mobile {
        display: flex;
    }

    .section-top-user {
        display: none;
    }

    .section-content {
        width: 100vw;
        padding: 105px 10px 25px 10px;
    }

    .section-container {
        min-width: 100%;
    }

    .section-header {
        font-size: 23px;
        width: 100%;
    }

    .unpublished-banner, .free-trial-banner {
        padding: 75px 0px 10px 0px !important;
    }

    .ub-extra-copy, .ft-extra-copy {
        display: none;
    }

    .unpublished-banner > p, .free-trial-banner > p {
        justify-content: center;
        padding: 10px 0 2px 0 !important;
    }

    .section-header-events > a {
        font-size: 22px;
    }

    .section-header-events > span {
        font-size: 23px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .section-header-events > .separator, .section-header-events > .widget-label {
        display: none;
    }

}

/* END OF RESPONSIVE */

/* UNPUBLISHED/FREE TRIAL BANNER */

.unpublished-banner, .free-trial-banner {
    background-color: #fff;
    padding: 75px 10px 10px 0;
    margin-bottom: 20px;
    position: fixed;
    width: 100%;
    z-index: 4;
    box-shadow: 2px 0px 1px 0px rgba(125, 128, 129, 0.5);
}

.ft-link {
    color: inherit;
}

.unpublished-banner > p, .free-trial-banner > p {
    margin: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 10px 0 2px 25px;
    border-top: 1px solid #DBDBDB;
}

.ub-title, .ft-title {
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.ft-title {
    color: var(--racecheck-green-dark);
}

.ub-title {
    color: var(--text-error);
}

.ub-icon, .ft-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.ub-icon > svg {
    width: 20px;
    height: 20px;
    stroke: var(--text-error);
    fill: var(--text-error);
}

.ft-icon > svg {
    width: 20px;
    height: 20px;
    stroke: var(--racecheck-green);
    fill: var(--racecheck-green);
}

.ub-extra-copy, .ft-extra-copy {
    margin-left: 3px;
}

.ft-get-started {
    font-weight: 600;
    margin-left: 15px;
    background-color: var(--racecheck-green);
    border-radius: 20px;
    padding: 3px 15px;
    color: #fff;
}

.ft-get-started:hover {
    background-color: var(--racecheck-green-dark);
}

/* END OF UNPUBLISHED/FREE TRIAL BANNER */

/* ERROR BOUNDARY */

.eb-error-container {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 105px;
    margin-bottom: 25px;
    padding: 20px;
    background-color: #e1e4e4;
    border: 1px solid #cfd3d4;
    border-radius: 5px;
}
.eb-subscription-error {
    margin-top: 0;
}

.eb-title {
    margin: 0 0 5px 0;
    color: var(--text-inactive);
    font-weight: 500;
    font-size: 17px;
}

.eb-copy {
    margin: 0 0 5px 0;
    color: var(--text-inactive);
}

.eb-copy-last {
    margin: 0 0 10px 0;
    color: var(--text-inactive);
}

.eb-link {
    color: var(--racecheck-green);
    font-weight: 500;
    cursor: pointer;
}

.eb-link:hover {
    text-decoration: underline;
}

.eb-details {
    color: var(--text-inactive);
    white-space: pre-wrap;
}

.eb-details > summary {
    font-weight: 500;
    cursor: pointer;
}

.eb-details > p {
    margin: 10px 0 0 0;
    font-family: monospace;
    font-size: small;
}

.eb-section-error-container {
    margin: 0;
    padding: 20px;
    background-color: #e1e4e4;
    border: 1px solid #cfd3d4;
    border-radius: 5px;
}

/* END OF ERROR BOUNDARY */

/* RESPONSIVE */

@media screen and (max-height: 650px) {

    .nav-promotion > svg {
        display: none;
    }

    .nav-promotion-copy {
        margin-top: 10px !important;
    }

}

@media screen and (max-height: 550px) {

    .nav-promotion {
        display: none;
    }

}

/* END OF RESPONSIVE */