.subscription-plans-container {
    display: flex;
    margin: 0 auto;
    max-width: 1000px;
    flex-direction: column;
}

.subscription-plans-container .trial-banner {
    margin-bottom: 16px;
}

/* FREE ACCOUNT VIEW */

.subscription-modal-payment-form .expiry-date-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 10px;
    margin-bottom: 0;
    justify-content: space-between;
}



.subscription-modal-payment-form .expiry-date-container .modal-input {
    margin-right: 5px;
    height: 32px;
    border: 1px solid #e0e6e8;
    background-position: calc(100% - 20px) 0.8em,
                         calc(100% - 15px) 0.8em,
                         calc(100% - 1.5em) 1em !important;
}


.expdate-month-container {
    display: flex;
    width: 45%;
}

.expdate-separator {
    line-height: 28px;
    font-size: 28px;
    margin: 0;
    font-weight:100;
}

.expdate-year-container {
    display: flex;
    width: 55%;
}

.subscription-modal-payment-form .expdate-year {
    margin-left: 5px!important;
    width: 100%;
}

.subscription-modal-payment-form .expdate-month > option:first-child {
    color: #999;
}

.modal-input-group-quarter > .modal-input-group-half {
    width: 50%;
    margin-bottom: 0;
    display: block;
}

.modal-input-group-quarter > .modal-input-group-half.expiry-date-half {
    width: 100%!important;
}

.modal-input-group-quarter > .modal-input-group-half.cvv-half {
    max-width: 30%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.subscription-trial-message {
    width: 995px;
    margin: 0 auto 30px auto;
    background-color: #eff8f2e7;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    padding: 16px 18px;
    box-shadow: 0 0 0 2px var(--racecheck-green);
}

.stm-copy {
    margin: 0;
    display: flex;
    align-items: center;
}

.stm-copy-highlight {
    font-weight: 500;
    margin-right: 5px;
    display: flex;
    align-items: center;
}

.subscription-trial-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.subscription-trial-icon > svg {
    width: 24px;
    height: 24px;
    stroke: var(--racecheck-green);
    fill: var(--racecheck-green);
}

.subscription-plan-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.subscription-plan-card {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 20px 15px 0;
    z-index: 2;
    padding: 18px;
}

.spc-premium {
    /* box-shadow: 0 0 0 2px var(--racecheck-green); */
    width: 575px;
    margin-right: 0;
}

.spc-free {
    max-width: 400px;
    width: 400px;
}

.spc-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 1px solid #DBDBDB;
}

.spc-header-left {
    margin-right: 60px;
}

.spc-header-right {
    margin-left: 100px;
}

.spc-header-intro {
    margin: 0;
    color: var(--text-inactive);
    opacity: .6;
    font-size: 14px;
    text-transform: uppercase;
}

.spc-plan-title {
    font-weight: 600;
    margin: 5px 0 15px 0;
}

.spc-pt-currency {
    font-size: 16px;
    vertical-align: super;
    margin-right: 3px;
}

.spc-pt-month {
    font-size: 15px;
    color: var(--text-inactive);
    opacity: .7;
    margin-left: 2px;
    font-weight: 400;
}

.spc-pt-paid-now {
    border-bottom: 2px solid var(--racecheck-green);
}

.spc-perks-perk {
    margin: 8px 0;
    display: flex;
    align-items: center;
}

.sp-perk-icon {
    padding: 6px 0 !important;
}

.spc-perks-perk-img {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.spc-perks-perk-img > svg {
    width: 20px;
    height: 20px;
    stroke: var(--racecheck-green);
    margin: 0 auto;
}

.spc-perks-perk-img-cross > svg {
    width: 20px;
    height: 20px;
    stroke: var(--text-error);
    margin: 0 auto;
}

.spc-perks-perk-title {
    margin: 0;
}

.spc-perks-coming-soon {
    font-size: 10px;
    color: var(--text-error);
    font-family: monospace;
    letter-spacing: 0px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-left: 2px;
}

.spc-btn {
    justify-content: center !important;
}

.spc-btn > .btn {
    padding: 0 10px !important;
    width: 85%;
}

.sp-table {
    width: 100%;
    border-collapse: collapse;
}

/* .sp-table > thead, .sp-perk-row {
    border-bottom: 1px solid var(--background-color);
} */

.sp-table > tbody {
    background-color: #fff;
    border-top: 1px solid var(--background-color);
}

.sp-perk-header-row > td {
    padding: 15px 10px 0 13px;
    /* display: flex;
    align-items: flex-start; */
}

.sp-perk-header-row > td > .sp-phr-subheader {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    display: flex;
    align-items: flex-start;
}

.sp-perk-header-row > td > .sp-phr-subheader > .sp-phr-icon {
    width: 25px;
    margin: 0 9px 0 0;
}

.sp-title {
    font-size: 13px;
    font-weight: 600;
    padding: 3px 12px;
    border-radius: 25px;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    text-transform: uppercase;
    margin: 15px auto 10px auto;
}

.sp-title-free {
    background-color: var(--very-light-grey);
    color: var(--dim-grey);
}

.sp-title-premium {
    color: #14911f;
    background-color: rgba(129, 215, 148, 0.4);
}

.sp-table-plan {
    vertical-align: top;
    text-align: center;
    position: relative;
    margin: 0 10px 0 26px;
    background: #fff;
    min-width: 200px;
    padding: 0 0 16px;
}

.sp-table-plan-recommended {
    /* border-top: 1px solid var(--background-color); */
    border-left: 1px solid var(--background-color);
    border-right: 1px solid var(--background-color);
}

/* .sp-recommended-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.sp-recommended {
    position: absolute;
    color: #fff;
    background-color: var(--racecheck-green);
    border-radius: 15px;
    padding: 2px 10px;
    top: -11px;
    font-weight: 500;
    margin: 0;
} */

.sp-table-perks-header {
    font-size: larger;
    vertical-align: bottom;
    padding-bottom: 10px;
    padding-left: 15px;
}

.sp-plan-title-copy {
    font-size: 15px;
    color: var(--text-inactive);
    opacity: .6;
    margin: 0;
    font-weight: 400;
    text-align: center;
}

.sp-perk-row > td {
    padding: 10px 15px;
}

.sp-perk-divider {
    padding: 5px 0;
}

/* .sp-perk-row:not(:last-child) {
    border-bottom: 1px solid var(--background-color);
} */

.sp-perk-row:hover {
    background-color: #fafafa;
}

.sp-perk-title {
    width: 100%;
    padding: 0 10px 0 48px !important;
    opacity: .75;
    transition: all ease .2s;
    white-space: nowrap;
}

.sp-perk-desc {
    margin: 5px 0 0 0;
    font-size: smaller;
    width: 75%;
}

.sp-perk-coming-soon {
    color: var(--text-error);
    border: 1px solid var(--text-error);
    border-radius: 2px;
    font-size: smaller;
    padding: 0 2px;
    display: inline-block;
    margin: 0 5px 0 0;
    text-transform: uppercase;
}

.sp-table-responsive-perk > .sp-perk-coming-soon {
    float: none;
    margin-left: 10px;
}

/* .sp-perk-included {
    border-right: 1px solid var(--background-color);
    border-left: 1px solid var(--background-color);
} */

/* .sp-perk-included-last {
    border-bottom: 1px solid var(--background-color);
} */

.spc-table-spacer {
    width: 5px;
    background-color: var(--background-color);
}

.sp-plan-title {
    font-weight: 700;
    font-size: 33px;
    margin: 5px 0;
}

.sp-custom-pricing-title {
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    padding: 0 15px;
    margin: 5px 0;
}

.sp-custom-pricing-desc {
    font-weight: 500;
    font-size: 13px;
    text-align: left;
    padding: 0 15px;
    margin: 5px 0;
    color: var(--text-inactive);
}

.sp-table-responsive-row, .sp-responsive-get-started-btn {
    display: none;
}

.sp-table-responsive-perk {
    padding: 5px;
    text-align: center;
    border-top: 1px solid var(--background-color);
    border-bottom: 1px solid var(--background-color);
    font-size: 14px;
}

.subscription-start-trial-banner {
    padding: 8px 8px 8px 12px !important;
}

/* END OF FREE ACCOUNT VIEW */

/* PREMIUM ACCOUNT VIEW */

.subscription-info-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px 10px;
    background-color: #f6fdf8;
    border-radius: 7px;
    border: 1px solid #abe2a6;
    margin: 0 0 20px 0;
}

.subscription-info-text {
    display: flex;
    align-items: center;
    margin: 0;
    color: var(--racecheck-green-dark);
    font-weight: 500;
}

.subscription-info-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.subscription-info-icon > svg {
    width: 23px;
    height: 23px;
    stroke: var(--racecheck-green);
    fill: var(--racecheck-green);
}

.subscription-warning-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px 15px 15px;
    background-color: #ffe7e7;
    border-radius: 7px;
    border: 1px solid #ffc4c4;
    margin: 0 0 20px 0;
}

.subscription-warning-text {
    display: flex;
    align-items: center;
    margin: 0;
    color: var(--text-error);
    font-weight: 500;
}

.subscription-warning-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.subscription-warning-icon > svg {
    width: 23px;
    height: 23px;
    stroke: var(--text-error);
    fill: var(--text-error);
}

.subscription-co-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px 10px;
    border-radius: 7px;
    margin: 0 0 20px 0;
    background-color: #f3e3a3ae;
    border: 1px solid rgb(235, 182, 85);
}

.subscription-co-text {
    display: flex;
    align-items: center;
    margin: 0;
}

.subscription-co-icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.subscription-co-icon > svg {
    width: 23px;
    height: 23px;
    stroke: rgb(228, 151, 8);
    fill: rgb(228, 151, 8);
}

.subscription-warning-btn, .subscription-info-btn {
    font-weight: 500;
    cursor: pointer;
    margin: 0;
    color: var(--text-inactive);
}

.subscription-warning-btn:hover, .subscription-info-btn:hover {
    text-decoration: underline;
}

.manage-subscription-card {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    margin: 0 0 20px 0;
    z-index: 2;
    padding: 18px 25px;
}

.msc-premium-features {
    margin-top: 10px;
}

.msc-premium-features > li {
    display: flex;
    align-items: center;
}

.msc-premium-features > li:not(:last-child) {
    margin-bottom: 5px;
}

.msc-premium-features > li > svg {
    width: 20px;
    height: 20px;
    stroke: var(--racecheck-green);
    margin: 0 5px 0 0;
}

.msc-invoices {
    display: block;
}

.msc-invoice-card {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    margin: 0 0 10px 0;
    z-index: 2;
    padding: 18px 25px;
}

.msc-i-item:not(:last-child) {
    border-right: 1px solid #e8ecee;
}

.msc-i-item > .msc-i-item-val {
    font-size: 19px;
    margin: 0 0 2px 0;
    font-weight: 600;
}

.msc-i-item > .msc-i-item-title {
    margin: 0;
    color: var(--text-inactive);
    opacity: .8;
    font-size: 14px;
}

.msc-i-status-container {
    width: 40%;
}

.msc-i-status > p {
    font-size: 12px;
    font-weight: 600;
    padding: 3px 15px;
    border-radius: 25px;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 80px;
    text-transform: uppercase;
    margin: 0;
}

.msc-i-status-open, .msc-i-status-Due {
    color: #DB4E4E;
    background-color: #db4e4e2a;
}

.msc-i-status-paid, .msc-i-status-Paid {
    color: #14911f;
    background-color: rgba(129, 215, 148, 0.4);
}

.msc-title {
    font-weight: 500;
    color: var(--text-inactive);
    margin: 0;
    opacity: .8;
    font-size: 15px;
    text-transform: uppercase;
}

.msc-highlight {
    font-weight: 600;
    margin: 5px 0 8px 0;
}

.msc-description {
    margin: 0 0 8px 0;
}

.msc-payment-info {
    display: flex;
    align-items: center;
    margin-top: 15px;
    flex-wrap: wrap;
}

.msc-payment-info > .msc-payment-paycycle {
    font-weight: 500;
}

.msc-payment-paycycle > .msc-payment-period {
    font-size: 12px;
    color: var(--text-inactive);
}

.msc-payment-info > .msc-payment-card {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #DBDBDB;
    border-radius: 3px;
    padding: 2px 5px 2px 2px;
    margin-right: 8px;
}

.msc-payment-info > .msc-payment-edit-card {
    height: 11px;
    width: 11px;
    cursor: pointer;
}

.msc-payment-card > .msc-payment-card-number {
    font-size: 15px;
    font-family: monospace;
}

.msc-payment-info > .msc-payment-next-date {
    margin: 0;
    color: var(--text-inactive);
}

.msc-payment-warning > p {
    color: var(--text-error);
    margin: 10px 0 0 0;
    font-weight: 600;
    cursor: pointer;
}

.msc-payment-warning > p:hover {
    text-decoration: underline;
}

.msc-renewal-status, .msc-payment-method {
    display: flex;
    align-items: center;
}

.msc-renewal-status > .msc-renewal-title {
    color: var(--text-inactive);
}

.msc-renewal-badge {
    width: 11px;
    height: 11px;
    display: block;
    margin-right: 8px;
    border-radius: 50%;
}

.msc-renewal-badge-green {
    background-color: rgb(43, 146, 43);
}

.msc-renewal-badge-orange {
    background-color: rgb(233, 161, 27);
}

.msc-renewal-badge-red {
    background-color: var(--text-error);
}

.msc-renewal-badge-gray {
    background-color: rgb(146, 146, 146);
}

.msc-btn {
    margin: 0;
}

.msc-btn > .btn {
    padding: 0 20px !important;
    margin-right: 5px;
}

.msc-payment-icon {
    margin-left: 10px;
    border: 1px solid #DBDBDB;
    padding: 3px 5px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.msc-invoice-table {
    width: 100%;
    margin-top: 15px;
    border-collapse: collapse;
}

.msc-invoice-table th {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-inactive);
    font-size: 14px;
    padding: 0 5px 5px 5px;
    opacity: .5;
}

.msc-invoice-table > thead > tr {
    border-bottom: 2px solid #e8ecee;
}

.msc-invoice-item-open > td {
    background-color: #f8686813;
}

.msc-invoice-item:not(:last-child) {
    border-bottom: 1px solid #e8ecee;
}

.msc-invoice-item > td {
    padding: 8px 5px;
}

.msc-invoice-details {
    border-top: 1px solid #DBDBDB; 
}

.msc-invoice-details > td {
    padding: 10px 0;
}

.msc-invoice-btn {
    width: 172px;
}

.invoice-action-btn {
    margin: 0 !important;
    height: 30px;
    min-height: 30px !important;
    font-size: 14px;
    width: 95%;
    min-width: 130px;
}

/* .subscription-events-list {
    padding-top: 15px;
    border-top: 1px solid #C7C7C7;
} */

.sel-loading-container {
    height: 32vh !important;
}

.sel-lazy-loader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border: 2px dashed #C7C7C7;
    border-radius: 5px;
    height: 120px;
}

.sel-lazy-loader > img {
    width: 70px;
    height: 70px;
    margin: 0 -15px 0 0;
}

.subscription-events-list > p {
    color: var(--text-normal);
}

.subscription-events-list > .sel-header {
    display: flex;
    width: 100%;
}

.sel-header > span {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-inactive-2);
    padding: 0 0 7px 0;
    cursor: pointer;
    margin: 0;
}

.sel-header > span:not(:last-child) {
    margin-right: 15px;
}

.sel-header > .disabled { 
    cursor: not-allowed;
}

.sel-header > span.active {
    color: var(--text-active);
    box-shadow: inset 0 -3px var(--racecheck-green);
}

.sel-header > span > .sel-counter {
    font-size: 12px;
    color: var(--text-inactive);
    margin-left: 6px;
}

.sel-body {
    width: 100%;
    margin-top: 15px;
}

.sel-body > div {
    width: 100%;
}

.sel-body .sel-list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

.sel-card {
    display: flex;
    overflow: hidden;
    max-height: 120px;
    border-radius: 5px;
}

.downgrade-event-card {
    margin-bottom: 20px;
    border: 2px solid #e8ecee;
    display: flex;
    overflow: hidden;
    max-height: 110px;
    border-radius: 5px;
}

.sel-card > .sel-card-info, 
.dec-info {
    width: 100%;
    padding: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sel-card > .sel-card-info > .sel-card-info-bottom, 
.dec-info-bottom {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sel-card-info-bottom > .sel-card-rating {
    border-bottom: 1px solid #e8ecee;
    padding-bottom: 6px;
}

.sel-card-info > .sel-card-title,
.dec-title {
    margin: 0;
    font-weight: 500;
}

.sel-card > .sel-card-image {
    height: 120px;
    width: 150px;
    flex-shrink: 0;
    overflow: hidden;
}

.sel-card > .sel-card-image-free > img {
    filter: grayscale(1);
}

.dec-image {
    min-width: 140px;
    max-width: 140px;
    overflow: hidden;
}

.dec-image-free {
    filter: grayscale(1);
}

.sel-card > .sel-card-image > img,
.dec-image > img {
    max-height: 100%;
    object-fit: cover;
}

.sel-card-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.dec-info {
    padding: 10px 14px;
    border-left: 2px solid #e8ecee;
    border-radius: 0 5px 5px 0;
}

.dec-label {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

.dec-switch {
    height: 15px;
    margin: 0 5px;
}

.dec-label-crossed {
    position: relative;
}

.dec-red-line {
    position: absolute;
    top: 50%;
    left: -4%;
    right: 0;
    height: 2px;
    background-color: var(--text-error);
    z-index: 100;
    transform: translateY(-50%);
    width: 108%;
}

.dec-label .event-label {
    position: relative;
    width: min-content;
    inset: auto;
    margin: 0;
}

.dec-label .event-label-line {
    display: none;  
}

.sel-card-info .sel-card-button {
    display: block;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;
    margin: 8px 0 0 0;
    min-height: auto;
    height: 29px;
}

.sel-card-info .sel-card-button.sel-card-button-upgrade {
    background-color: var(--racecheck-green);
    color: #fff;
    width: 100%;    
}

.sel-card-info .sel-card-button.sel-card-button-upgrade:hover {
    background-color: var(--racecheck-green-dark);
}

.sel-card-info .disabled-sel-card-btn {
    cursor: not-allowed !important;
    border: 1px solid #e8ecee !important;
    color: rgb(165, 165, 165) !important;
    background-color: #fff !important;
}

.sel-card-info .sel-card-button.sel-card-button-manage {
    border: 1px solid #9ca6af;
    color: var(--text-inactive);
    background-color: #fff;
    width: 80%;
    text-align: center;
}

.sel-card-info .sel-card-button.sel-card-button-manage:hover {
    background-color: #f5f5f5;
}

.sel-card-info .sel-card-button.sel-card-button-downgrade {
    width: 17%;
    border: 1px solid var(--text-error);
    padding: 0;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    color: var(--text-error);
    background-color: #fff;
}

.sel-card-info .sel-card-button.sel-card-button-downgrade:hover {
    background-color: #fdf4f4;
}

.sel-card-button.sel-card-button-downgrade > img {
    width: 12px;
    height: 14px;
}

.downgrade-event-modal {
    padding: 0;
}

.downgrade-event-modal .downgrade-event-modal__body {
    margin-bottom: 14px;
}

.downgrade-event-modal .downgrade-event-modal__info {
    margin-top: 15px;
}

.downgrade-event-modal .downgrade-event-modal__info > p {
    font-weight: 500;
    font-size: 13px;
    color: var(--text-inactive);
}

.downgrade-event-modal__info > .downgrade-event-modal__list {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.downgrade-event-modal__list > .downgrade-event-modal__list-item {
    font-weight: 700;
    font-size: 13px;
    color: var(--text-active);
    height: 2em;
    display: inline-flex;
    align-items: center;
}

.downgrade-event-modal__list-item > svg {
    height: 15px;
    width: 15px;
    fill: var(--persian-red);
    stroke: var(--persian-red);
    border: 1px solid var(--persian-red);
    border-radius: 50%;
    margin-right: 6px;
}

.downgrade-event-modal__event {
    border-bottom: 1px solid var(--very-light-grey);
    padding-bottom: 19.5px;
}

.downgrade-event-modal__event > .downgrade-event-modal__title {
    margin-bottom: 9px !important;
}

.downgrade-event-modal__summary {
    margin-top: 12.5px;
}

/* END OF PREMIUM ACCOUNT VIEW */

.sub-no-events-placeholder > .no-data-header {
    display: flex;
    align-items: center;
}

.sub-no-events-placeholder > .btn {
    margin-top: 25px;
    padding: 0 30px !important;
}

.status-label {
    font-size: 22px;
    font-weight: 600;
    padding: 3px 13px;
    border-radius: 20px;
    text-align: center;
    text-transform: uppercase;
    margin: 5px 7px 0 7px;
}

.sl-premium {
    color: #14911f;
    background-color:  #81d7946b;
}

.sl-free {
    background-color: var(--very-light-grey);
    color: var(--dim-grey);
}

/* MODAL */

.modal-btn-row .modal-button {
    font-family: Lato;
    font-size: 13px;
    color: var(--text-inactive);
    border: 1px solid var(--very-light-grey);
    border-radius: 13.5px;
    outline: none;
    background: none;
    padding: 5.38px 14.5px 5.63px 15.5px;
    font-weight: 700;
    cursor: pointer;
}

.modal-btn-row .modal-button.active {
    background-color: var(--racecheck-green);
    color: white;
}

/* END OF MODAL  */

/* UPGRADE EVENTS MODAL */

.upgrade-event-modal .modal-header-title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 600;
}

.upgrade-event-modal__content, .downgrade-event-modal__content, .change-plan-modal__content {
    padding-bottom: 18px;
    overflow-y: visible;
    padding-top: 13px;
}

.upgrade-event-modal__head {
    padding: 0;
}

.upgrade-event-modal__head > button {
    margin: 0;
}

.upgrade-events-list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 60vh;
    border-bottom: 1px solid #e8ecee;
    border-top: 1px solid #e8ecee;
    padding: 5px 0;
}

.upgrade-events-list-event {
    display: flex;
    padding: 5px 10px;
    border: 2px solid #e8ecee;
    border-radius: 5px;
    align-items: center;
    margin: 3px 0;
}

.upgrade-events-list-event:hover {
    cursor: pointer;
}

.uele-selected {
    border: 2px solid var(--racecheck-green);
}

.upgrade-events-list-event > input {
    margin-right: 10px;
}

.upgrade-events-list-event > p {
    font-weight: 500;
}

.uele-selected > p {
    color: var(--text-active);
}

.smro-payment-header {
    margin-bottom: 5px !important;
}

.upgrade-event-modal__events-list .lds-roller {
    align-self: center;
}

.upgrade-event-modal__info {
    margin-bottom: 16px;
    font-family: Lato;
    padding-bottom: 11px;
    border-bottom: 1px solid var(--very-light-grey);
}

.upgrade-event-modal__info-text, .change-plan-modal__info-text {
    font-size: 12px;
    font-weight: 500;
}

.upgrade-event-modal__button-submit {
    padding: 5.38px 31px 5.38px 37px !important;
}

.upgrade-event-modal__info-text b {
    font-weight: 700;
}

.upgrade-event-modal .plan-card, .change-plan-modal .plan-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 10px 12px 14px 14px;
    border: 1px solid var(--very-light-grey);
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
}

.change-plan-modal__button-submit {
    min-width: 123px;
}

.change-plan-modal .plan-card.disable {
    border: 1px solid #dddddd;
}

.change-plan-modal .plan-card.disable p,
.change-plan-modal .plan-card.disable span  {
    color: var(--very-light-grey);
}

.upgrade-event-modal .plan-card__info, .change-plan-modal .plan-card__info {
    width: 100%;
}

.plan-card__price {
    justify-self: flex-end;
    display: flex;
    flex-shrink: 0;
    font-family: Lato;
    font-weight: 700;
    font-size: 20px;
    align-items: flex-end;
}

.plan-card.disable {
    color: var(--very-light-grey);
}

.plan-card__currency {
    font-size: 11px;
    font-weight: 600;
    align-self: flex-start;
}

.plan-card__month {
    font-size: 11px;
    font-weight: 600;
    color: var(--dim-grey-2);
    margin-left: 4px;
}


.upgrade-event-modal .plan-card.checked, .change-plan-modal .plan-card.checked {
    border: 1px solid var(--racecheck-green);
}

.plan-card:last-child {
    margin-bottom: 0;
}

.plan-card__info {
    margin-left: 12px;
    font-family: Lato;
}

.plan-card__name {
    font-size: 17px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
}

.plan-card__description {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.change-plan-modal input[type='radio'],
.upgrade-events-list input[type='radio'],
.cancel-subscription-modal input[type='radio'],
.subscription-modal-radio-option input[type='radio'] {
  position: relative;
  height: 22px;
  width: 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
}

.change-plan-modal input[type='radio']::before,
.upgrade-events-list input[type='radio']::before,
.cancel-subscription-modal input[type='radio']::before,
.subscription-modal-radio-option input[type='radio']::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #e8ecee;
}

.change-plan-modal input[type='radio']:checked::before,
.upgrade-events-list input[type='radio']:checked::before,
.cancel-subscription-modal input[type='radio']:checked::before,
.subscription-modal-radio-option input[type='radio']:checked::before {
    border: 2px solid var(--racecheck-green);
}

.change-plan-modal .plan-card.disable input[type='radio']::before {
    border: 2px solid var(--very-light-grey);
}

.change-plan-modal input[type='radio']:checked::after,
.upgrade-events-list input[type='radio']:checked::after,
.cancel-subscription-modal input[type='radio']:checked::after,
.subscription-modal-radio-option input[type='radio']:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--racecheck-green);
    transform: translate(-50%, -50%);
    visibility: visible;
}


.upgrade-event-modal__event {
    display: flex;
    padding: 7px 9px;
    border: 1px solid var(--dim-grey);
    border-radius: 4px;
    align-items: center;
    margin-bottom: 5px;
}

.upgrade-event-modal__event:last-child {
    margin-bottom: 0;
}

.upgrade-event-modal__event input {
    margin: 0;
}

.upgrade-event-modal__event p {
    font-family: Lato;
    font-size: 14px;
    color: var(--text-inactive);
    margin: 0;
    margin-left: 9px;
}

.upgrade-event-modal__head {
    padding-bottom: 8.25px;
}

.upgrade-event-modal__head h2 {
    margin: 0;
}

.upgrade-event-modal__field-title:first-child {
    margin-top: 7px;
}

.upgrade-event-modal__field-title {
    font-size: 13px;
    font-family: Lato;
    color: var(--text-inactive);
    font-weight: 400;
    margin: 0;
    margin-bottom: 4px;
    margin-top: 2px !important;
}

.upgrade-event-modal__field-value {
    color: var(--text-normal);
    font-size: 13px;
    font-family: Lato;
    font-weight: 400;
    margin: 0;
    margin-bottom: 4px;
}

.summary-card {
    padding: 6px 11px;
    border: 1px solid var(--very-light-grey);
    border-radius: 4px;
    font-family: Lato;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 9px;
}

.summary-card p {
    margin: 3px 0px;
}

.summary__title {
    font-size: 17px;
    color: var(--text-active);
    font-weight: 700;
}

.summary__events {
    display: flex;
}

.summary__events p {
    font-size: 13px;
    color: var(--text-inactive);
    font-weight: 500;
}

.summary__tooltip {
    position: relative;
    margin: 0;
    margin-left: 5px;
    border: 1px solid var(--very-light-grey);
    color: var(--dim-grey);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.summary__tooltip:hover > .summary__tooltip-container {
    display: block;
}

.summary__tooltip-container {
    display: none;
    position: absolute;
    bottom: 32px;
    z-index: 3000;
    background-color: #fff;
    box-shadow: #b4b4b4 0px 0px 4px 0px;
    border-radius: 5px;
    padding: 4px 10px;
    white-space: nowrap;
    z-index: 10000;
}

.summary__tooltip-container:after {
    content: '';
	position: fixed;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 5px solid transparent;
	border-top-color: var(--background-color);
	border-bottom: 0;
	margin-left: -5px;
	margin-bottom: -5px;
}

.summary__payment {
    font-size: 11px;
    font-weight: 500;
    color: var(--text-inactive);
}

.smro-tooltip {
    margin-top: -5px;
    margin-bottom: 15px;
}

.smro-tooltip p {
    font-size: 15px;
}


.accordion-summary {
    color: var(--racecheck-green) !important;
    font-weight: 700;
    margin-bottom: 4px;
    cursor: pointer;
}

.accordion-details {
    max-height: 0;
    overflow: hidden;
    transition: max-height ease .3s;
}

.accordion-details.open {
    max-height: 500px;
}

.accordion-details > p > span {
    font-weight: 500;
}

.change-plan-modal__cancel-subscription {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: var(--persian-red);
    font-size: 13px;
    font-family: Lato;
}

.change-plan-modal__footer {
    justify-content: space-between !important;
    margin: 0;
    padding-top: 15px;
    border-top: 1px solid var(--very-light-grey);
}

.change-plan-modal__body {
    padding: 0 0 12px 0;
}

.change-plan-modal__error {
    background-color: var(--misty-rose);
    border: 1px solid var(--persian-red);
    border-radius: 5px;
    padding: 7px 44px 8px 12px;
    margin-bottom: 8px;
}

.change-plan-modal__error > p {
    color: var(--persian-red);
    display: flex;
    align-items: center;
    font-weight: 500;
    margin: 0;
    display: flex;
}

.change-plan-modal__error > p > p {
    font-family: Lato;
    font-size: 12px;
    margin: 0;
}

.change-plan-modal__error > p > b {
    font-weight: 700;
}

.change-plan-modal__error > p > span {
    border: 1px solid var(--persian-red);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 8px;
    margin: 0;
    flex-shrink: 0;
    margin-right: 10px;
}

.change-plan-modal-links {
    margin-top: 17px;
    padding-top: 9px;
    border-top: 1px solid #e8ecee;
    display: flex;
    align-items: center;
}

.change-plan-modal-links > p {
    font-size: 15px;
}

.change-plan-modal-links > p > span {
    color: var(--text-inactive);
    font-weight: 500;
    margin-left: 5px;
}

.change-plan-modal-links > p > span:hover {
    text-decoration: underline;
}

.change-plan-links-disabled > p > span {
    opacity: .5;
    cursor: not-allowed;
}

.change-plan-links-disabled > p > span:hover {
    text-decoration: none;
}

/* END OF UPGRADE EVENTS MODAL  */

/* CREATE SUBSCRIPTION MODAL */

.choose-plan-modal-section,  
.payment-method-modal-section, 
.summary-modal-section, 
.payment-method-modal-section,
.change-plan-immediate-cost-modal-section,
.cancellation-explainer-modal-section,
.cancellation-step2-modal-section
.cancellation-reason-modal-section,
.upgrade-prorated-modal-section,
.downgrade-preview-modal-section {
    margin-top: -5px;
    border-bottom: 1px solid #e8ecee;
}

.change-plan-new-plan-modal-section,
.cancellation-perks-modal-section,
.cancellation-form-modal-section,
.downgrade-new-plan-modal-section,
.resume-summary-modal-section {
    padding-top: 15px;
    margin-bottom: 25px;
}

.resume-payment-method-modal-section {
    padding-top: 15px;
    border-bottom: 1px solid #e8ecee;
}

.billing-details-modal-section {
    padding-top: 15px;
}

.billing-details-modal-section > .checkbox-container {
    justify-content: flex-end;
    background: none;
    padding: 0;
}

.cancellation-explainer-modal-section,
.cancellation-step2-modal-section
.cancellation-reason-modal-section,
.downgrade-summary-modal-section,
.create-subscription-plan-section,
.change-plan-immediate-cost-modal-section {
    padding-bottom: 10px;
}

/* .to-pay-modal-section, .sub-change-modal-section, .payment-method-modal-section {
    margin-top: 0;
    padding-top: 15px;
} */

.customer-details-modal-section,
.choose-payment-change-modal-section,
.upgrade-events-modal-section,
.downgrade-summary-modal-section,
.create-subscription-plan-section {
    margin-top: -5px;
}

.change-plan-new-plan-trial-modal-section {
    margin-top: -5px;
    padding-top: 0;
}

.coupon-code-section,
.to-pay-modal-section {
    border-bottom: 1px solid #e8ecee;
    padding-top: 15px;
}

.subscription-modal-header {
    color: var(--text-normal);
    font-weight: 500;
    margin: 0 0 10px 0 !important;
}

.proration-value {
    font-weight: 600;
}

.cancellation-perks-modal-section > ul > li > label {
    font-size: 13px;
    color: var(--text-normal);
    display: flex;
    align-items: center;
    margin: 0;
    font-weight: 400;
}

.cancel-subscription-modal .modal-form-btn {
    font-family: Lato;
    font-size: 13px;
    line-height: 13px;
    height: 27px;
    box-sizing: border-box;
    position: relative;
}

.cancel-subscription-modal .modal-form-btn > .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
}

.cancel-subscription-modal .modal-btn-row {
    margin: 15px 18px 17px 0;
}

.upgrade-event-modal .subscription-modal-header {
    font-size: 13px;
    font-family: Lato;
    color: var(--text-active)
}

.cancel-subscription-options {
    margin-top: 17px;
    padding-top: 13px;
    border-top: 1px solid var(--very-light-grey);
    font-family: Lato;
}

.cancel-subscription-options h2 {
    color: var(--text-active);
    font-size: 13px;
    margin: 0;
    font-weight: 500;
}

.cancel-subscription-options p {
    color: var(--text-inactive);
    font-size: 12px;
    margin: 3px 0 12px;
    font-weight: 500;
}

.cancellation-perks-list {
    margin-top: 15px;
}

.cancellation-perks-list > li {
    font-weight: 600;
    color: var(--text-active);
    margin: 8px 0;
    display: flex;
    align-items: center;
}

.cancellation-perks-list > li > div {
    margin-right: 6px;
    color: var(--text-error);
    border: 2px solid var(--text-error);
    border-radius: 50%;
    width: 23px;
    height: 23px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.cancellation-perks-list > li > div > svg {
    color: var(--text-error);
    stroke: var(--text-error);
    width: 100%;
    height: 100%;
}

.cancellation-q {
    font-size: 15px;
    color: var(--text-inactive);
    font-weight: 500;
    margin: 0 0 8px 0 !important;
}

.cancellation-reasons > li > label {
    display: flex;
    align-items: center;
    color: var(--text-normal) !important;
    cursor: pointer;
}

.cancellation-reasons > li > label > input {
    margin-right: 8px;
}

.cancellation-textarea {
    width: 100%;
    min-height: 80px;
    outline: none;
    padding: 5px;
    border-radius: 2px;
    max-width: 100%;
}

.cs2-explainer {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 10px 0 25px !important;
}

.cs2-title {
    font-weight: 600;
    display: flex;
    align-items: center;
    color: var(--text-error);
}

.cs2-icon {
    display: flex;
    align-items: center;
    margin-right: 5px
}

.cs2-icon > svg {
    width: 20px;
    height: 20px;
    stroke: var(--text-error);
    fill: var(--text-error);
}

@media screen and (max-width: 680px) {
    .cs2-important {
        display: none !important;
    }
}

.cs2-extra-copy {
    margin-bottom: 25px !important;
}

.cs2-extra-copy > p {
    margin-bottom: 15px;
}

.cs2-extra-copy > p > a {
    color: var(--racecheck-green);
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
}

.cs2-modal-btns {
    display: flex;
}

.upgrade-event-modal .smsg-title {
    font-family: Lato;
    font-size: 13px;
}

.upgrade-event-modal .smsg-info {
    font-size: 13px;
    font-family: Lato;

}

.smro-payment-header {
    margin-bottom: 5px !important;
}

.subscription-modal-radio-option {
    width: 100%;
    border: 2px solid #e8ecee;
    border-radius: 5px;
    margin-bottom: 15px;
}

.subscription-modal-radio-option:hover {
    cursor: pointer;
}

.smro-active {
    border: 2px solid var(--racecheck-green);
}

.smro-label {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 15px 10px;
}

.smro-disabled, .smro-label-disabled {
    cursor: not-allowed !important;
    opacity: .7;
}

.subscription-modal-plan-summary > .smro-label {
    justify-content: space-between;
}

.subscription-modal-plan-summary > .smro-label > .smro-price {
    margin-left: 0;
}

.smro-label:hover {
    cursor: pointer;
}

.smro-copy {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.smro-title {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 5px 0 !important;
    color: var(--text-normal);
    display: flex;
    align-items: center;
}

.smro-resume-card-preview {
    display: flex;
    align-items: center;
}

.smro-resume-card-preview > .smro-title {
    margin-bottom: 0 !important;
}

.smro-current-tag {
    color: #14911f;
    background-color: #81d7946b;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 10px;
    border-radius: 20px;
    text-align: center;
    margin-left: 10px;
    text-transform: uppercase;
}

.smro-quantity {
    margin-bottom: 10px !important;
    color: #000000;
}

.smro-desc {
    margin: 0 !important;
    display: flex;
    align-items: center;
}

.smro-desc > p {
    margin: 0 !important;
}

.smro-desc-extra {
    font-size: 15px;
    color: var(--text-inactive);
    opacity: .8;
    margin-top: 5px !important;
}

.smro-desc-extra > span {
    font-weight: 500;
}

.smro-desc > span, .smro-tooltip-highlight{
    font-weight: 600;
}

.smro-payment-summary-copy {
    margin: 10px 10px 0 10px !important;
    padding-top: 6px;
    border-top: 1px solid #e8ecee;
}

.smro-tooltip-link {
    color: var(--racecheck-green);
    cursor: pointer;
    margin: 0 !important;
    padding: 0;
    font-size: 15px;
    font-weight: 500;
}

.smro-tooltip-link > span > svg {
    width: 10px;
    fill: var(--racecheck-green);
}

.smro-tooltip-link-open > span > svg {
    transform: rotate(180deg);
    transform-origin: center;
}

.smro-tooltip-content {
    margin: 0 0 0 10px !important;
    padding: 5px 0 0 0;
}

.smro-tooltip-content > p {
    font-size: 15px;
}

.smro-tooltip-inline-link {
    color: var(--racecheck-green);
    cursor: pointer;
    font-weight: 500;
    margin-left: 5px;
    font-size: small;
}

.smro-disabled-form {
    opacity: .4;
}

.max-warning-email {
    font-weight: 600;
    cursor: pointer;
    color: var(--racecheck-green);
    display: contents;
}

.smro-price {
    margin-left: auto;
    margin-right: 10px;
    flex: 1 0 auto;
    text-align: right;
}

.smro-button {
    margin-left: auto;
    margin-right: 10px;
}

.smro-price-title {
    margin: 0 5px 0 0 !important;
}

.upgrade-country-select {
    height: 32px;
    border: 1px solid #e0e6e8;
    background-position: calc(100% - 20px) 0.8em,
                         calc(100% - 15px) 0.8em,
                         calc(100% - 1.5em) 1em !important;
}

.subscription-modal-customer-form > .modal-input-row {
    margin: 0 !important;
}

.subscription-modal-billing-form > .modal-input-row {
    margin: 0 !important;
}

.subscription-modal-payment-form > .modal-input-row {
    margin: 0 !important;
}

.subscription-modal-payment-form > .modal-input-row > .modal-input-group {
    width: 96%;
}

.subscription-modal-payment-form {
    margin-bottom: 10px;
    width: 50%;
}

.payment-method-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.payment-method-card-preview {
    width: 48%;
    padding-top: 26px;
}

.payment-card {
    width: 100%;
    height: 158px;
    border-radius: 8px;
    padding: 10px 20px;
    font-family: "Lucida Console", Monaco, monospace;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: 0px 0px 3px 0px rgba(60,106,122,0.5);
    position: relative;
}

.payment-card-bg1 {
    background: rgb(129,196,225);
    background: linear-gradient(58deg,rgba(74,145,191,1) 0%, rgba(129,196,225,1) 100%);
}

.payment-card > p {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
}

.payment-card-chip {
    width: 50px;
    height: 35px;
    border-radius: 6px;
    background: rgb(201,201,201);
    background: linear-gradient(58deg, #efdbab 0%, #e1cb94 100%);
    display: block;
    border: 1px solid rgb(170, 170, 170);
    position: relative;
    overflow: hidden;
}

.payment-card-chip-line {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgb(185, 163, 108);
    z-index: 2;
}

.payment-card-chip-line:nth-child(1) {
    top: 8px;
}

.payment-card-chip-line:nth-child(2) {
    top: 16px;
}

.payment-card-chip-line:nth-child(3) {
    top: 24px;
}

.payment-card-chip-line:nth-child(4) {
    left: 24px;
    width: 1px;
    height: 50px;
}

.payment-card-chip-center {
    position: absolute;
    width: 18px;
    height: 22px;
    border: 1px solid rgb(185, 163, 108);
    background: linear-gradient(58deg, #efdbab 0%, #e1cb94 100%);
    border-radius: 2px;
    z-index: 3;
    top: 6px;
    left: 15px;
}

.payment-card-number {
    font-size: 17px;
    letter-spacing: 1.5px;
    margin: 10px 0 5px 0 !important;
    height: 22px;
}

.payment-card-expiry-container {
    display: flex;
    color: #fff;
    text-transform: uppercase;
    align-items: center;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    height: 16px;
}

.payment-card-expires-header {
    font-size: 9px;
    margin: 0 5px 0 0 !important;
}

.payment-card-expiry {
    margin: 0 !important;
    font-size: 12px;
}

.payment-card-name {
    margin: 3px 0 0 0 !important;
    font-size: 12px;
    height: 16px;
}

.payment-card-logo {
    position: absolute;
    top: 15px;
    right: 15px;
}

.payment-card-logo > svg {
    transform: scale(1.5);
    transform-origin: top right;
}

.payment-card-disclaimer {
    font-size: 13px;
    color: var(--text-inactive);
    opacity: .7;
    margin: 0;
    display: flex;
    align-items: center;
}

.payment-card-disclaimer a {
    color: var(--racecheck-green);
}

.payment-warning-icon {
    display: flex;
    align-items: center;
    margin-right: 3px;
}

.payment-warning-icon > svg {
    width: 20px;
    height: 20px;
    stroke: var(--text-error);
    fill: var(--text-error);
    opacity: 1 !important;
}

.subscription-modal-plan-summary {
    margin-bottom: 20px;
    padding: 10px 5px !important;
}

.coupon-code-section > form > .modal-input-row {
    display: block !important;
}

.smro-coupon-row {
    display: flex;
}

.smro-coupon-row > .modal-form-btn {
    height: 32px;
    width: 20%;
    min-height: 32px!important;
    max-height: 32px;
    margin-left: 10px;
}

.smro-coupon-copy {
    margin-bottom: 15px !important;
    margin-top: -8px !important;
    margin-left: 5px !important;
    font-size: 14px;
}

.smro-coupon-copy-invalid {
    color: var(--text-error);
}

.smro-coupon-name {
    font-weight: 600;
}

.smro-coupon-remove {
    color: var(--text-error);
    cursor: pointer;
    font-size: smaller;
    font-weight: 600;
    margin-left: 5px;
}

.smro-coupon-remove:hover {
    text-decoration: underline;
}

.sms-group {
    margin-bottom: 10px;
}

.sms-group:last-child {
    margin-bottom: 20px;
}

.smsg-title {
    color: var(--text-inactive);
    opacity: .7;
    margin: 0 0 3px 0 !important;
    font-size: 15px;
}

.smsg-info {
    margin: 0 !important;
}

.smsg-info-span {
    color: var(--text-inactive);
    opacity: .7;
    font-style: italic;
}

.smsg-link {
    color: var(--racecheck-green);
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
    margin-bottom: 15px !important;
}

.smsg-link:hover {
    text-decoration: underline;
}

.sm-confirmation-title {
    font-size: 27px;
    font-weight: 600;
    margin: 0 0 10px 0 !important;
}

.sm-confirmation-copy {
    margin: 0 !important;
}

.sm-confirmation-text, .sm-confirmation-loading {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sm-confirmation-loader {
    height: 90px !important;
    margin-top: -20px;
}

.events-list-loader {
    height: 150px !important;
}

.sm-loading-copy {
    margin: 15px 0 0 0 !important;
}

.confetti-container-right {
    position: absolute;
    bottom: 40px;
    right: 40px;
}

.confetti-container-left {
    position: absolute;
    bottom: 40px;
    left: 40px;
}

.sm-modal-btn-row {
    justify-content: space-between !important;
    align-items: center;
}

.payment-method-logo > svg {
    width: 50px;
    height: 40px;
    margin-right: 10px;
}

.add-new-payment-btn {
    width: 100%;
    padding: 15px 10px 16px 10px;
    border: 2px dashed #e8ecee;
    border-radius: 5px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    color: var(--text-inactive);
}

.add-new-payment-btn > img {
    margin: -18px 0;
}

.add-new-payment-btn:hover {
    background-color: #fafafa;
    cursor: pointer;
}

.payment-method-inline-logo > svg {
    width: 30px;
    height: 14px;
    margin: 0 3px 0 3px;
    position: relative;
}

.payment-method-inline-logo {
    box-sizing: border-box;
    display: inline-flex;
}

.pm-visa-logo {
    width: 40px;
    display: inline;
    margin: 0 0 0 5px;
}

.pm-visa-logo-inline {
    width: 40px;
    display: inline;
    margin: 0 5px 0 2px;
}

.delete-card-circle-btn {
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px 0 0;
    border-radius: 50%;
    cursor: pointer;
    background: none;
}

.delete-card-circle-btn:hover {
    background-color: #e8ecee;
}

.delete-card-circle-btn > svg {
    width: 22px;
    height: 22px;
    margin: 0;
    fill: var(--text-inactive);
    stroke: var(--text-inactive);
}

.card-deletion-confirmation {
    height: 76px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cdc-copy {
    margin: 0 !important;
    position: relative;
}

.cdc-buttons {
    display: flex;
    justify-content: center;
}

.cdc-card-btn {
    font-weight: 500;
    cursor: pointer;
    margin-top: 8px !important;
    margin-bottom: 0 !important;
    color: var(--text-inactive);
}

.cdc-card-btn:first-child {
    margin-right: 10px;
}

.cdc-card-btn:last-child {
    margin-left: 10px;
}

.cdc-card-btn:hover {
    text-decoration: underline;
}

.create-subscription-terms-container {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    margin-top: 15px;
}

.create-subscription-terms-container .checkbox-container {
    background: none;
    padding: 0;
    margin: 0 10px 0 0;
}

.create-subscription-terms-container .checkbox-container > .checkmark {
    margin-right: 0;
}

.create-subscription-terms-container .checkbox-container > .checkmark:after {
    top: 6px;
    width: 5px;
    height: 10px;
    border-width: 0 2px 2px 0;
}

.cstc-label {
    margin: 0 5px 0 0 !important;
}

.cstc-label > span > a {
    color: var(--racecheck-green);
    cursor: pointer;
    font-weight: 500;
}

.cstc-label > span:hover {
    text-decoration: underline;
}

.deleting-card-loader {
    height: 50px;
    margin: -1px 0 0 0;
    opacity: .9;
    position: absolute;
    right: -38px;
    top: -15px;
}

.subscription-modal-trial-message {
    width: 100%;
    margin: 0 0 12px 0;
    background-color: #f5fcf7e7;
    border-radius: 5px;
    padding: 2px 8px;
    border: 2px solid var(--racecheck-green);
    font-size: 12px;
    display: flex;
    align-items: center;
}

.subscription-modal-trial-message > .stm-copy {
    font-size: 15px;
    color: var(--racecheck-green);
}

.subscription-modal-trial-message > .stm-copy > .stm-copy-highlight {
    margin-right: 5px;
}

.subscription-modal-trial-message > .stm-copy > .stm-copy-highlight > .subscription-trial-icon > svg {
    width: 21px;
    height: 21px;
}

.subscription-modal-error-message {
    width: 100%;
    margin: 0 0 12px 0;
    background-color: #ffe7e7;
    border-radius: 5px;
    padding: 2px 8px;
    border: 2px solid #ffc4c4;
    font-size: 12px;
}

.subscription-error-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.subscription-modal-error-message > .stm-copy > .stm-copy-highlight {
    color: var(--text-error);
}

.subscription-modal-error-message > .stm-copy > .stm-copy-highlight > .subscription-error-icon > svg {
    width: 21px;
    height: 21px;
    stroke: var(--text-error);
}

.subscription-modal-build-plan, .subscription-modal-info-card {
    padding: 10px 5px !important;
    cursor: default !important;
}

.subscription-modal-info-card > .smro-label {
    padding: 0;
}

.subscription-modal-warning-message {
    width: 100%;
    margin: 0 0 15px 0;
    background-color: #f8e8a0e7;
    border-radius: 5px;
    padding: 2px 8px;
    border: 2px solid rgb(235, 182, 85);
}

.subscription-modal-warning-message > .stm-copy, .subscription-modal-error-message > .stm-copy {
    font-size: 15px;
    align-items: flex-start;
    line-height: 21px;
}

.subscription-modal-warning-message > .stm-copy > .stm-copy-highlight > .subscription-warning-icon > svg {
    width: 21px;
    height: 21px;
    stroke: rgb(228, 151, 8);
}

.smro-counter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 2px solid #e8ecee;
    overflow: hidden;
}

.smro-counter-left, .smro-counter-right, .smro-counter-count {
    padding: 10px 15px;
    font-weight: bold;
    text-align: center;
}

.smro-counter-left, .smro-counter-right {
    width: 45px;
    user-select: none;
    cursor: pointer;
    background-color: var(--racecheck-green);
    color: #fff;
}

.smro-counter-left:hover, .smro-counter-right:hover {
    background-color: var(--racecheck-green-dark);
}

.smro-counter-count {
    margin: 0 !important;
    width: 55px;
    height: 40px;
    border-right: 2px solid #e8ecee;
    border-left: 2px solid #e8ecee;
    cursor: default;
}

.smro-build-plan-label {
    border-bottom: 2px dashed #e8ecee;
    padding-bottom: 10px;
    cursor: default;
}

.smro-build-plan-desc {
    margin: 0 0 15px 0 !important;
    font-size: 15px;
}

.smro-build-plan-desc > span {
    font-weight: 600;
}

.smro-counter-disabled {
    cursor: not-allowed !important;
    opacity: .3;
}

.smro-counter-disabled:hover {
    background-color: unset !important;
}

.sm-summary-bold, .sm-summary-trial-warning > span {
    font-weight: 600;
}

.sm-summary-trial-warning {
    margin-bottom: 20px !important;
}

.sm-summary-trial-warning > span:first-child {
    color: var(--text-error);
}
 
/* END OF CREATE SUBSCRIPTION MODAL*/

.modal-btn-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
}

.modal-btn-col > .btn:first-child {
    margin-bottom: 10px !important;
}

.subscription-cancel-btn {
    cursor: pointer;
    margin: 0;
    outline: none;
    border: none;
    background: none;
    color: var(--text-error);
    padding: 0;
    font-size: 14px;
    font-weight: 500;
}

.subscription-cancel-btn:hover {
    text-decoration: underline;
    opacity: 1;
}

.sub- {
    width: 200px;
}

.sub-fixed-width-btn-large {
    width: 250px;
}

.sub-fixed-width-btn-medium {
    width: 200px;
}

.sub-fixed-width-btn-small {
    width: 150px;
}

.subscription-btn-loader {
    height: 65px;
    margin: -1px 0 0 0;
    opacity: .9;
}

.ms-expiry-format {
    font-size: 12px;
    opacity: .7;
}

/* PAY INVOICE MODAL */

.pay-invoice-modal-section {
    padding-top: 20px;
}

.pay-invoice-modal-section > .add-new-payment-btn {
    margin-bottom: 20px !important;
}

.invoice-paid-confirmation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 0;
}

.invoice-paid-confirmation > p {
    margin: 0 0 20px 0;
}

.invoice-paid-confirmation > svg {
    width: 20%;
    height: 100%;
    margin: 20px 0;
}

.payment-method-modal-section > .subscription-modal-header {
    margin-bottom: 9px !important;
}

.pm-card {
    width: 100%;
    border: 1px solid var(--very-light-grey);
    border-radius: 4px;
    padding: 6px 11px;
    margin-bottom: 7px;
}

.pm-card__number {
    font-size: 13px;
    font-family: Lato;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.pm-card__logo {
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pm-card__logo > img {
    max-height: 11px;
    max-width: 34px;
}

.pm-card__expires {
    font-size: 11px;
    font-family: Lato;
    font-weight: 500;
    color: var(--text-inactive);
    margin-bottom: 0 !important;
}

.restart-subscription-modal__footer {
    margin-top: 18px;
    padding-top: 15px;
    border-top: 1px solid var(--very-light-grey);
}

/* END OF PAY INVOICE MODAL*/

/* RESPONSIVE */

@media screen and (max-width: 900px) {

    .subscription-trial-message {
        width: 100%;
    }

    .subscription-start-trial-banner {
        flex-direction: column;
    }

    .subscription-start-trial-banner > .btn {
        width: 100% !important;
        margin-top: 10px !important;
    }

    .stm-copy {
        display: block;
    }

    .stm-copy-highlight {
        margin-bottom: 5px;
    }

    .subscription-plan-cards {
        flex-direction: column-reverse;
    }

    .spc-free, .spc-premium {
        width: 100%;
        max-width: 100%;
    }

    .spc-header-left {
        margin-right: 0;
    }

    .spc-header-right {
        margin-left: 0;
    }

    .manage-subscription-card {
        display: block;
    }

    .msc-btn {
        margin: 20px 0 0 0;
    }

    .msc-btn > .btn {
        width: 100%;
    }

    .sp-table {
        width: 100%;
    }

    .sp-table-plan {
        width: 50%;
        min-width: none;
    }

    .sp-table-perks-header {
        display: none;
    }

    .sp-responsive-get-started-btn {
        display: none;
    }

    .sp-table-responsive-row {
        display: table-row;
    }

    .sp-perk-row:hover {
        background-color: #fff;
    }

    .sp-perk-title {
        display: none;
    }

    .sp-perk-included {
        border-right: none;
        border-left: 1px solid var(--background-color);
    }

    .sp-perk-included-last {
        border-bottom: none;
    }

    .sp-table-plan-recommended {
        border: 1px solid var(--racecheck-green);
    }

    .sel-body .sel-list {
        grid-template-columns: 1fr;
    }

    .sel-card > .sel-card-image {
        width: 0px;
    }

    .sp-perk-header-row > td {
        padding: 5px;
    }

    .sp-perk-header-row > td > .sp-phr-subheader {
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: 16px;
    }

    .sp-perk-header-row > td > .sp-phr-subheader > .sp-phr-icon {
        width: 20px;
        margin: 0 5px 0 0;
    }

    .sp-perk-divider {
        display: none;
    }

    /* UPGRADE MODAL */

    .smro-label {
        justify-content: space-between;
    }

    .smro-build-plan-label {
        flex-direction: column;
    }

    .smro-build-plan-label > .smro-price {
        margin: 10px auto 0 auto !important;
    }

    .smro-check {
        display: none;
    }

    .smro-copy {
        margin-left: 5px;
    }

    .spc-pt-month {
        display: block;
        font-size: 8px;
    }

    .payment-method-container {
        display: block;
    }

    .subscription-modal-payment-form {
        width: 100%;
    }

    .subscription-modal-payment-form > .modal-input-row > .modal-input-group {
        width: 100%;
    }

    .payment-method-card-preview {
        display: none;
    }

    .subscription-modal-plan-summary > .smro-label {
        display: block;
    }

    .subscription-modal-plan-summary > .smro-label > .smro-price {
        margin-left: 5px;
        margin-top: 10px;
    }

    .subscription-modal-plan-summary > .smro-label > .smro-price > .smro-price-title > .spc-pt-month {
        display: inline;
        font-size: 12px;
    }

    /*  END OF UPGRADE MODAL */

    .cancellation-mbr {
        flex-direction: column;
    }

    .cancellation-mbr > .btn {
        width: 100%;
    }

    .cancellation-mbr > .btn:first-child {
        margin: 0 0 10px 0 !important;
    }

}

/* END OF RESPONSIVE */

/* SIZE TIER MODAL */

.size-tier-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;
}

.stl-item {
    width: 30%;
    background: #FFFFFF;
    border: 2px solid #E8ECEE;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;
}

.stl-item.selected {
    border: 2px solid var(--racecheck-green);
}

.stl-item-title {
    font-size: 20px;
    font-weight: 600;
    margin: 0 !important;
}

.stl-item > .built-icon {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.stl-item > .built-icon > svg {
    width: 28px;
}

.stl-item.selected > .built-icon > svg {
    color: var(--racecheck-green);
}

.stl-item-range {
    font-size: 15px;
    font-weight: 500;
    margin: 0 !important;
    color: var(--text-inactive);
    text-align: center;
}

.stl-item-copy {
    font-size: 13px;
    margin: 0 !important;
    color: var(--text-inactive);
    opacity: .7;
    text-align: center;
}

/* END OF SIZE TIER MODAL */