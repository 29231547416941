.analytics-events-report-table-container {
    margin: 0 0 20px 0;
    width: 100%;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    overflow-x: scroll;
    overflow-y: visible;
}

.er-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 15px;
}

.er-t-h-title {
    display: flex;
    align-items: baseline;
}

.er-t-h-title > h2 {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
}

.er-t-h-title > p {
    font-size: 14px;
    margin: 0 0 0 10px;
    color: var(--text-inactive-2);
}

.er-t-h-btns {
    display: flex;
    align-items: center;
}

.er-t-h-checkbox {
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
}

.er-t-h-checkbox > p {
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--text-inactive);
    font-size: 15px;
    position: relative;
}

.er-t-h-checkbox > p > svg {
    margin: 0 0 0 3px;
    width: 17px;
    height: 17px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
    cursor: pointer;
    opacity: .4;
}

.er-t-h-checkbox > .checkbox-container {
    background: none;
    margin: 0 5px 0 0;
    padding: 0;
}

.er-t-h-checkbox > .checkbox-container > .checkmark {
    margin: 0;
    height: 20px;
    width: 20px;
}

.er-t-h-checkbox > .checkbox-container > .checkmark:after {
    top: 3px;
    width: 6px;
    height: 11px;
    border-width: 0 2px 2px 0;
}

.er-t-h-btn {
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 7px;
    border: 1px solid #E0E6E8;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    background: none;
    height: auto;
    width: auto;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-inactive);
}

select.er-t-h-btn {
    padding: 0 25px 0 0;
    background: inherit;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #fff, #fff) !important;
    background-position: calc(100% - 15px) .5em,
        calc(100% - 10px) .5em,
        calc(100% - 1.5em) 0.1em !important;
    background-size: 5px 5px,
        5px 5px,
        1px 1.5em !important;
    background-repeat: no-repeat !important;
    border: none
}

.er-t-h-select-container {
    padding: 0;
    border: 1px solid #E0E6E8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 5px 0 5px 7px;
}

.er-t-h-btn:hover, .er-t-h-select-container:hover {
    background-color: #FBFBFB;
}

.er-t-h-btn > svg, .er-t-h-select-container > svg {
    height: 18px;
    width: 18px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
    opacity: .6;
    margin: 0 5px 0 0;
}

.er-t-h-btn > span {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-inactive);
}

.er-table {
    background-color: #fff;
    width: 100%;
    border-radius: 0 0 7px 7px;
    border-spacing: 0;
    border-collapse: collapse;
    overflow-x: scroll;
    overflow-y: visible;
}

.er-table > .er-t-main-header {
    background-color: #F9F9F9;
}

.er-table > .er-t-sub-header {
    background-color: #F6F6F6;
}

.er-table > tr > td {
    padding: 5px 7px;
    border: 1px solid #E8ECED;
}

.er-table > tr > th {
    padding: 10px 10px;
    border: 1px solid #E8ECED;
}

.er-table > .er-t-main-header > th {
    font-size: 13px;
    font-weight: 600;
    color: var(--text-inactive);
}

.er-table > .er-t-sub-header > td {
    vertical-align: bottom;
    font-size: 13px;
    font-weight: 400;
    color: var(--text-inactive);
}

.er-table > .er-t-sub-header > .er-t-sh-cell {
    position: relative;
}

.er-table > .er-t-sub-header > .er-t-sh-cell > .er-tooltip {
    width: 210px;
    margin-left: -105px;
}

.er-table > .er-t-sub-header > .er-t-sh-cell > .er-tooltip-far-left {
    margin-left: -75px;
}

.er-table > .er-t-sub-header > .er-t-sh-cell > .er-tooltip-far-left::after {
    left: 39%;
}

.er-table > .er-t-sub-header > .er-t-sh-cell > .er-tooltip-far-right {
    margin-left: -185px;
}

.er-table > .er-t-sub-header > .er-t-sh-cell > .er-tooltip-far-right::after {
    left: 87%;
}

.er-table > .er-t-sub-header > .er-t-sh-cell > .er-tooltip > .er-tooltip-desc-b {
    font-weight: 600;
}

.er-table > .er-t-sub-header > .er-t-sh-cell:hover > .er-tooltip {
    display: block;
}

.er-table > tr > td:first-child {
    width: 150px;
}

.er-table > .er-t-data-row > td {
    font-weight: 500;
    font-size: 13px;
}

.er-table > .er-t-data-row > td:first-child > .er-t-event-name, .er-table > .er-t-totals-row > td:first-child > .er-t-event-name {
    display: flex;
    align-items: center;
    max-width: 150px;
    position: relative;
}

.er-table > .er-t-totals-row > td:first-child > .er-t-event-name {
    justify-content: flex-end;
}


.er-table > .er-t-data-row > td:first-child > .er-t-event-name > .er-tooltip, .er-table > .er-t-totals-row > td:first-child > .er-t-event-name > .er-tooltip {
    margin-left: -75px;
}

.er-tooltip, .er-table > .er-t-totals-row > td:first-child > .er-t-event-name > .er-tooltip {
    margin-top: 0;
    top: -470%;
}

.er-tooltip, .er-table > .er-t-totals-row > td:first-child > .er-t-event-name > .er-tooltip::after {
    border-color: #e8ecee transparent transparent transparent;
    top: 100%;
}

.er-table > .er-t-data-row > td:first-child > .er-t-event-name > .er-tooltip::after, .er-table > .er-t-totals-row > td:first-child > .er-t-event-name > .er-tooltip::after {
    left: 30%;
}

.er-table > .er-t-data-row > td:first-child > .er-t-event-name:hover > .er-tooltip, .er-table > .er-t-totals-row > td:first-child > .er-t-event-name:hover > .er-tooltip {
    display: block;
}

.er-table > .er-t-data-row > td:first-child > .er-t-event-name:hover > a {
    text-shadow: .5px 0px 0px var(--text-normal);
    cursor: pointer;
}

.er-table > .er-t-data-row > td:first-child > .er-t-event-name > a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-decoration: none;
    color: var(--text-normal);
}

.er-table > .er-t-data-row > td:first-child > .er-t-event-name > svg, .er-table > .er-t-totals-row > td:first-child > .er-t-event-name > svg {
    width: 14px;
    min-width: 14px;
    height: 14px;
    min-height: 14px;
    color: #e1b71d;
    stroke: #e1b71d;
    fill: #e1b71d;
    margin: 0 3px 0 0;
}

.er-table > .er-t-data-row > .er-t-status, .er-table > .er-t-totals-row > .er-t-status {
    padding: 0;
    text-align: center;
    vertical-align: middle;
}

.er-table > .er-t-totals-row > .er-t-status > span {
    opacity: .3;
}

.er-table > .er-t-data-row > .er-t-status > svg {
    width: 15px;
    height: 15px;
    margin-top: 2px;
}

.er-table > .er-t-data-row > .er-t-status > .er-t-status-tick {
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    fill: var(--racecheck-green);
}

.er-table > .er-t-data-row > .er-t-status > .er-t-status-cross {
    color: var(--text-error);
    stroke: var(--text-error);
    fill: var(--text-error);
}

.er-table > .er-t-data-row > .er-t-estimate-cell {
    background-color: #FDFAED;
    position: relative;
    font-style: italic;
}

.er-table > .er-t-data-row > .er-t-estimate-cell:hover {
    background-color: #FCF7E0;
}

.er-table > .er-t-data-row > .er-t-estimate-cell:hover > .er-tooltip {
    display: block;
    width: 220px;
    margin-left: -110px;
    font-style: normal;
}

.er-table > .er-t-data-row > .er-t-estimate-cell:hover > .er-tooltip-far-right {
    margin-left: -190px;
}

.er-table > .er-t-data-row > .er-t-estimate-cell:hover > .er-tooltip-far-right::after {
    left: 86%;
}

.er-table > .er-t-data-row > .er-t-event-name-container:hover ~ .er-t-estimate-cell {
    background-color: #FCF7E0;
}

.er-table > .er-t-totals-row {
    background-color: #f5fcf7;
}

.er-table > .er-t-totals-row > td {
    padding: 12px 10px;
    font-weight: 600;
    font-size: 14px;
}

.er-table > .er-t-totals-row > td:first-child > .er-t-event-name > p {
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    margin: 0;
}

.er-t-rating-stars {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.er-t-rating-stars > .er-t-rs-value {
    color: var(--racecheck-gold);
    font-size: 14px;
    font-weight: 500;
    margin: 0 2px 0 0 !important;
}

.er-t-rating-stars > .er-t-rs-v-unrated {
    color: var(--text-inactive);
    opacity: .4;
}

.er-t-rating-stars > svg {
    width: 15px;
    height: 15px;
}

.er-t-rating-stars > .er-t-rs-count {
    font-size: 11px;
    color: var(--text-inactive);
    margin: 0 0 -2px 2px;
    opacity: .5;
}

.analytics-events-report-key {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    width: fit-content;
    margin-bottom: 20px;
}

.er-k-img {
    width: 60px;
    height: 40px;
    background-color: #FDFAED;
    margin: 0 15px 0 0;
    border-radius: 3px;
    border: 1px solid #E8ECED;
    display: flex;
    align-items: center;
    justify-content: center;
}

.er-k-img > span {
    color: var(--text-inactive);
    font-style: italic;
    font-size: 13px;
}

.er-k-copy {
    padding: 0 10px 0 0;
}

.er-k-copy > p {
    font-size: 14px;
}

.er-k-copy > p:first-child {
    margin: 0 0 2px 0;
    font-weight: 600;
}

.er-k-copy > p:last-child {
    color: var(--text-inactive-2);
    margin: 0;
}

.er-k-copy > p:last-child > a {
    color: var(--racecheck-green);
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

.er-k-copy > p:last-child > a:hover {
    text-decoration: underline !important;
}


.er-tooltip {
    display: none;
    width: 240px;
    background-color: #fff;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    text-align: center;
    border-radius: 6px;
    border: 1px solid #e8ecee;
    position: absolute;
    margin: 5px 0 0 0;
    z-index: 100;
    top: 100%;
    left: 50%;
    margin-left: -120px;
}

.er-tooltip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #e8ecee transparent;
}

.er-tooltip-desc {
    color: var(--text-inactive);
    opacity: .75;
    font-size: 13px;
    text-align: center;
    display: block;
    padding: 10px;
    font-weight: 400;
}

.er-tooltip-extra-row {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px dashed #e8ecee;
}

.er-tooltip-extra-row > a {
    font-weight: 600;
    color: var(--racecheck-green);
    cursor: pointer;
    text-decoration: none;
}

.er-tooltip-extra-row > a:hover {
    font-weight: 600;
    color: var(--racecheck-green);
    cursor: pointer;
    text-decoration: underline !important;
}

.er-tooltip-extra-row > svg {
    width: 18px;
    margin: 0 5px 0 10px;
}

.er-tooltip-extra-row > .er-icon-info {
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    fill: var(--racecheck-green);
}

.er-tooltip-extra-row > .er-icon-warning {
    color: #F4D11B;
    stroke: #F4D11B;
    fill: #F4D11B;
}

.er-t-h-checkbox > p > svg:hover ~ .er-tooltip {
    display: block;
}

.er-t-cost-edit {
    display: none;
}

.er-t-cost-cell {
    min-width: 83px;
    max-width: 83px;
    position: relative;
}

.er-t-cost-cell:hover > .er-t-cost-edit {
    display: inline;
}

.er-t-cost-edit > svg {
    width: 13px;
    height: 13px;
    stroke: var(--racecheck-green);
    margin: 1px 0 -1px 3px;
    cursor: pointer;
}

.er-t-cost-currency {
    font-size: 13px;
    font-weight: 500;
    margin: 0 2px 0 0;
}

.er-t-cost-input {
    font-size: 13px;
    color: var(--text-normal);
    font-weight: auto;
    margin: 0;
    text-align: center;
    width: 80%;
    padding: 0;
    height: 24px;
    -moz-appearance: textfield !important;
    appearance: none !important;
}

.er-t-cost-input::-webkit-outer-spin-button,
.er-t-cost-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.er-t-input-row > .disabled-primary-btn > svg {
    color: var(--text-inactive-2);
    stroke: var(--text-inactive-2);
    opacity: .3;
}

.er-t-cost-btn {
    width: 10px;
    height: 10px;
    padding: 0 !important;
    margin: 0 0 0 2px;
    min-height: auto !important;
    line-height: auto;
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
}

.er-t-cost-btn:hover {
    background: none;
}

.er-t-cost-btn > svg {
    width: 10px;
    height: 10px;
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
}

.er-t-cost-cell .er-cost-tooltip {
    display: block !important;
    font-style: normal;
    width: 200px;
    margin-left: -100px;
}

.er-t-cost-cell .er-cost-tooltip > .er-tooltip-extra-row > a {
    color: var(--text-inactive);
}

.er-t-cost-cell .er-cost-tooltip > .er-tooltip-extra-row > .er-tooltip-desc-btn {
    color: var(--racecheck-green);
    transition: all ease-out .1s;
}

.er-t-cost-cell .er-cost-tooltip > .er-tooltip-extra-row > .er-tooltip-desc-btn-disabled {
    opacity: .3;
    color: var(--text-inactive);
    cursor: not-allowed;
    text-decoration: none;
}

.er-t-cost-cell .er-cost-tooltip > .er-tooltip-extra-row > .er-tooltip-desc-btn-disabled:hover {
    text-decoration: none !important;
}

@media screen and (max-width: 820px) {
    .er-table-header > .er-t-h-title {
        flex-direction: column;
    }

    .er-table-header > .er-t-h-title > h2 {
        font-size: 20px;
    }

    .er-table-header > .er-t-h-title > p {
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .er-table-header > .er-t-h-btns {
        flex-direction: column;
    }

    .er-table-header > .er-t-h-btns > .er-t-h-checkbox {
        margin: 0 0 5px 0;
    }
}