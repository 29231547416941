:root {
    --facebook: #1877F2;
    --facebook-dark: rgb(21, 105, 216);
    --twitter: #1DA1F2;
    --twitter-dark: rgb(26, 153, 231);
    --strava: #FC6100;
    --strava-dark: rgb(240, 92, 0);
    --google: rgb(253, 253, 253);
    --google-dark: rgb(248, 248, 248);
}

h2 {
    font-size: 30px;
    font-weight: 600;
    margin: 30px 0;
}

.logged_out_logo {
    margin: 0 auto 35px auto;
    display: block;
    box-sizing: border-box;
}

.logged_out_container {
    padding-top: 35px;
}

.log_in_container {
    width: 500px;
    margin: 0 auto 20px auto;
    padding: 1px 50px;
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
}

.log_in_divider, .sign_up_divider {
    color: #9ca6af;
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
    text-align: center;
}

.log_in_divider:before, .log_in_divider:after, .sign_up_divider:before, .sign_up_divider:after {
    border-top: 1px solid #d5dce0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 13%;
    z-index: 0;
}

@media screen and (max-width: 575px) {

    .log_in_divider, .sign_up_divider {
        margin-left: 10px;
        margin-right: 10px;
    }

    .log_in_divider:before, .log_in_divider:after, .sign_up_divider:before, .sign_up_divider:after {
        width: 6%;
    }
}

@media screen and (max-width: 350px) {

    .log_in_divider:before, .log_in_divider:after, .sign_up_divider:before, .sign_up_divider:after {
        width: 0;
    }
}

.sign_up_divider:before, .sign_up_divider:after {
    width: 40% !important;
}

.log_in_divider:before, .sign_up_divider:before {
    left: auto;
    right: 0;
}

.divider-spaced {
    margin-top: 1em;
    margin-bottom: 1em;
}

.log-in-form {
    margin-top: 15px;
}

.log-in-btn {
    background-color: var(--racecheck-green);
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    min-height: 40px;
    max-height: 40px;
    padding: 0 16px;
    text-align: center;
    width: 100%;
    border: none;
}

.log-in-btn-loading {
    background-color: #E8ECED;
}

.log-in-btn-loading:hover {
    background-color: #E8ECED !important;
    cursor: default;
}

.margin-top-btn {
    margin-top: 30px;
}

.log-in-btn:hover {
    background-color: var(--racecheck-green-dark);
}

.log-in-sign-up-link {
    margin: 20px 0;
}

.log-in-sign-up-link span, .log-in-sign-up-link a {
    color: var(--racecheck-green);
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
}

.log-in-sign-up-link span:hover, .log-in-sign-up-link a:hover, .account-support-link a:hover {
    text-decoration: underline !important;
}

.account-support-link {
    margin: 10px 0 30px 0;
    padding: 20px 0 0 0;
    border-top: 1px solid #d5dce0;
    line-height: 19px;
}

.account-support-link a {
    color: var(--racecheck-green);
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
}

.create-organisation-step {
    margin: 15px 0;
    font-size: 12px;
    color: var(--text-inactive);
    font-weight: 500;
    opacity: .6;
}

.forgotten-password-link {
    display: block;
    margin-top: 5px;
    font-size: 13px;
    color: #A3A3A3;
    cursor: pointer;
    text-decoration: none;
    width: fit-content;
}

.forgotten-password-link:hover {
    text-decoration: underline !important;
}

.sign-up-disclaimer {
    display: block;
    margin: 5px auto 0 auto;
    font-size: 13px;
    color: #5e5e5e;
    text-align: center;
    width: 90%;
}

.sign-up-disclaimer > a {
    color: rgb(31, 31, 31);
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    margin: 0;
}

.sign-up-disclaimer > a:hover {
    text-decoration: underline;
}

.invite-disclaimer {
    margin-top: 30px;
    margin-bottom: 20px;
}

.invite-link {
    color: rgb(31, 31, 31);
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

.invite-link:hover {
    text-decoration: underline;
}

.sign-up-name-row {
    display: flex;
    justify-content: space-between;
}

.sign-up-name-row .input-group {
    width: 48%;
}

.btn {
    border-radius: 3px;
    min-height: 40px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding-left: 10px;
    text-decoration: none;
}

.grayscale {
        filter: grayscale(1);
      }

.facebook_btn {
    background-color: var(--facebook);
    color: #fff;
    background-image: url('../../../src/images/facebook_logo_white.svg');
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 24px;
    width: 100%;
    border: none;
}

.facebook_btn:hover {
    background-color: var(--facebook-dark);
}

.twitter_btn {
    background-color: var(--twitter);
    color: #fff;
    background-image: url('../../../src/images/twitter_logo_white.svg');
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 24px;
    width: 100%;
    border: none;
}

.twitter_btn:hover {
    background-color: var(--twitter-dark);
}

.strava_btn {
    background-color: var(--strava);
    color: #fff;
    background-image: url('../../../src/images/strava_logo_white.svg');
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 20px;
    width: 100%;
    border: none;
}

.strava_btn:hover {
    background-color: var(--strava-dark);
}

.google_btn {
    background-color: var(--google);
    color: var(--text-inactive);
    background-image: url('../../../src/images/google_logo.svg');
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 22px;
    width: 100%;
    border: 1px solid rgb(207, 207, 207);
}

.google_btn:hover {
    background-color: var(--google-dark);
}

.social-login-loading-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.log-in-error {
    text-align: center;
    color: var(--text-error);
    margin-bottom: 25px;
    padding: 15px 10px;
    background-color: #fff4f4;
    border-radius: 5px;
    font-weight: 600;
}

.org-address-double-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.org-address-input:not(:last-child) {
    margin-bottom: 13px;
}

.org-address-input-half {
    width: 48%;
}

.organisation-list-copy {
    color: var(--text-inactive);
}

.pending-requests-to-join {
    border: 1px solid #e0e6e8;
    background-color: #f4f6f78e;
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px;
    max-height: 200px;
    overflow-y: scroll;
}

.pending-requests-to-join > h4 {
    margin: 0 0 10px 0;
}

.pending-requests-to-join > p {
    margin: 0 0 5px 0;
}

.wrap-checkboxes {
    display: flex;
    flex-wrap: wrap;
}

.checkbox-container {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    position: relative;
    padding: 0 8px;
    margin-bottom: 15px;
    margin-right: 15px;
    border-radius: 5px;
    font-weight: 500;
    line-height: 35px;
    background-color: #F2F2F2;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    height: 24px;
    width: 24px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid #979797;
}

.checkbox-container:hover input ~ .checkmark {
    background-color: rgb(231, 231, 231);
}

.checkbox-container input:checked ~ .checkmark {
    background-color: var(--racecheck-green);
    border: 1px solid var(--racecheck-green);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    top: 12px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.organisation-sports-container {
    margin: 1em 0;
}

.input-phone-with-code {
    display: flex;
    justify-content: space-between;
}

.modal-input-group-half .country-code-input, .modal-input-group-half .org-number-input {
    width: 100%;
}

.country-code-input {
    width: 48%;
    padding-right: 35px;
}

.org-number-input {
    width: 48%;
}

.create-org-back-btn:hover {
    text-decoration: underline;
    cursor: pointer;
}

.create-org-bullet {
    margin: 0 5px;
}

.invite-bold {
    font-weight: 600;
    color: var(--text-normal)
}

.rp-label {
    text-align: left;
}

.rp-title {
    margin: 30px 0 15px 0 !important;
}

.rp-copy {
    color: var(--text-inactive);
    margin-bottom: 30px;
}

.rp-btn-loader {
    height: 70px;
    margin: -1px 0 0 0;
    opacity: .9;
}

.rp-inline-link {
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
}

.rp-error-copy {
    margin: 5px !important;
}

.rp-return-link:hover {
    text-decoration: none;
}

.rp-return-link > .btn {
    margin-bottom: 25px;
}

.rp-fb-warning-container {
    padding: 15px;
    background-color: #E8ECED;;
    border: 2px solid #316FF6;
    border-radius: 5px;
    margin: 20px 0 20px 0;
    text-align: center;

}

.rp-fb-warning-title {
    font-size: 18px;
    margin: 10px 0 20px;
    font-weight: 600;
}

.rp-fb-warning-copy {
    font-size: 14px;
    margin: 5px 0 20px 0;
}

.rp-fb-warning-btn {
    border-radius: 5px;
    width: 100%;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #fff;
    border: none;
    outline: none;
    padding: 6px 0;
    margin-top: 5px;
    background-color: #343a40;
}

.rp-fb-warning-btn:hover {
    background-color: #000;
}

.book-demo-container {
    width: 925px;
    min-height: 465px;
    margin: 0 auto 20px auto;
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#hubspotForm {
    min-height: 418px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#hubspotForm > iframe {
    min-height: 418px !important;
}

.bdc-left, .bdc-right {
    width: 50%;
    padding: 30px 30px 15px 30px;
}

.bdc-right {
    border-left: 1px solid #E8ECED;
    height: 100%;
}

.bwc-left {
    border-right: 1px solid #E8ECED;
}

.bwc-right {
    border-left: none;
}

.demo-intro {
    line-height: 19px;
}

.bwc-icon-p {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin: 0;
}

.bwc-icon-p:first-of-type {
    margin-bottom: 5px !important;
}

.bwc-icon-p > svg {
    margin-right: 5px;
    width: 18px;
    height: 18px;
    color: #4ABF6C;
    stroke: #4ABF6C;
    fill: #4ABF6C;
}

.bdc-left h2, .bdc-left p {
    text-align: left;
}

.bdc-left h2 {
    margin: 0 0 20px 0;
    font-weight: 700;
    font-family: Poppins;
    line-height: 35px;
}

.bdc-left > .testimonial-images {
    justify-content: left;
    margin-top: 25px;
}

.bdc-left > .testimonial-images > .testimonial-img {
    max-height: 30px;
    max-width: 100px;
    margin: 0 10px 10px 0;
}

.bdc-left > .testimonial-images > p {
    margin: 0 0 15px 0;
    opacity: .4;
    font-size: 13px;
}

@media screen and (max-width: 925px) {
    .book-demo-container {
        width: 95%;
    }

    .bdc-left, .bdc-right {
        padding: 20px 20px 5px 20px;
    }
}

@media screen and (max-width: 550px) {
    .book-demo-container {
        flex-direction: column;
    }

    .bdc-left, .bdc-right {
        width: 100%;
    }

    .bdc-right {
        border-top: 1px solid #E8ECED;
        border-right: none;
    }
}

/* RESPONSIVE */

@media screen and (max-width: 900px) {

    .logged_out_container {
        padding-top: 25px;
    }

    .logged_out_logo {
        margin: 0 auto 25px auto;
    }

    .log_in_container {
        width: 95%;
        padding: 1px 20px;
    }

    h2 {
        font-size: 25px;
        font-weight: 500;
        margin: 20px 0;
    }

    .wrap-checkboxes {
        justify-content: center;
    }

}

/* END OF RESPONSIVE */