.team-container {
    display: flex;
    margin: 0 auto;
    max-width: 1375px;
}

.team-audit > .horizontal-dropdowns {
    margin-bottom: 15px;
}

.team-audit-title {
    font-size: 24px;
    margin: 0 0 15px 0;
    font-weight: 600;
}

.audit-feed-titles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 15px;
    /* min-width: 1050px; */
}

.audit-feed-titles > p {
    text-transform: uppercase;
    font-weight: 600;
    color: var(--text-inactive);
    font-size: 14px;
    margin: 0 0 10px 0;
}

.audit-feed-titles > p:nth-child(1) {
    width: 11%;
}

.audit-feed-titles > p:nth-child(2) {
    width: 520px;
}

.audit-feed-titles > p:nth-child(3) {
    width: 10%;
}

.audit-feed-titles > p:nth-child(4) {
    width: 150px;
}

.audit-feed-cards {
    height: 100%;
    max-height: calc(100vh - 205px);
    overflow-y: auto !important;
    overflow-x: hidden;
    /* border-top: 1px solid #DBDBDB; */
    background-color: #fff;
    border-radius: 7px;
}

.audit-feed-cards > div > .no-data-container > .no-data-img {
    width: 200px !important;
}

.audit-count {
    font-size: 14px;
    font-weight: 600;
    margin-left: 3px;
    color: var(--text-inactive);
    opacity: .7;
}

.team-audit-filter-icon {
    float: right;
    margin-top: 2px;
    display: none;
    cursor: pointer;
}

.team-audit-filter-icon > svg {
    width: 30px;
    height: 30px;
}

/* TEAM MEMBER MODALS */

.add-team-member-modal .modal-btn-row {
    justify-content: space-between;
}

.tm-section {
    margin-bottom: 20px;
}

.tm-section-header {
    color: var(--text-normal);
    font-weight: 500;
    margin: 0 0 10px 0 !important;
}

.tm-invite-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 20px;
}

.tm-invite-success-container > svg {
    width: 70px;
    height: 70px;
    margin: 0 0 10px 0;
}

.tm-invite-success-container > h3 {
    font-size: 23px;
    font-weight: 600;
    margin: 0 0 10px 0;
    text-align: center;
}

.tm-invite-success-container > p {
    color: var(--text-inactive);
    margin: 0;
    text-align: center;
}

.tm-role-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tm-role {
    width: 50%;
    border: 1px solid #E0E6E8;
    border-radius: 3px;
    padding: 10px 12px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
    transition: all ease-in-out .15s;
}

.tm-role:first-child {
    margin-right: 10px;
}

.tm-role:hover {
    background-color: #fcfcfc;
}

.tm-role-active:hover {
    background-color: #fff;
}

.tm-role > .tm-role-icon {
    width: 25px;
    height: 25px;
    margin: 0 7px 0 0;
}

.tm-role > .tm-role-icon > svg {
    width: 25px;
    height: 25px;
    color: var(--text-inactive);
    fill: var(--text-inactive);
    stroke: var(--text-inactive);
    opacity: .75;
}

.tm-role > .tm-role-copy > h3 {
    font-size: 15px;
    margin: 0 0 4px 0;
    color: var(--text-active);
    font-weight: 500;
}

.tm-role > .tm-role-copy > p {
    font-size: 13px;
    margin: 0;
    color: var(--text-inactive);
    opacity: .75;
}

.tm-role-active {
    border-color: var(--racecheck-green);
}

.tm-role-disabled {
    cursor: not-allowed;
    opacity: .4;
}

.tm-role-disabled > .tm-role-icon {
    opacity: .75;
}

.tm-role-disabled > .tm-role-copy > h3 {
    opacity: .75;
}

.tm-role-check {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--racecheck-green);
    border-radius: 10px;
    opacity: 0;
    transition: opacity ease-in .2s;
}

.tm-role-check > svg {
    color: #fff;
    stroke: #fff;
    width: 14px;
}

.tm-role-active > .tm-role-check {
    opacity: 1;
}

.tm-events-list-container {
    width: 100%;
    border: 1px solid #E0E6E8;
    border-radius: 3px;
    position: relative;
    transition: all ease-in-out .15s;
    max-height: 215px;
    overflow-y: scroll;
}

.tm-elc-empty {
    border: 1px dashed #E0E6E8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.tm-elc-empty > p {
    font-weight: 500;
    color: var(--text-inactive);
    opacity: .6;
    font-size: 15px;
}

.tm-el-event {
    padding: 5px;
}

.tm-el-event:not(:last-child) {
    border-bottom: 1px solid #EDEDED;
}

.tm-el-event-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border-radius: 2px;
    transition: all ease-in .15s;
    cursor: pointer;
}

.tm-el-event-inner:hover {
    background-color: #fcfcfc;
}

.tm-el-event-inner > .event-btn {
    width: 28px;
    height: 28px;
    background-color: #fff;
    border: 1px solid #E0E6E8;
    cursor: pointer;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all ease-in .1s;
    margin: 0 10px 0 0;
    position: relative;
}

.tm-el-event-inner:hover > .event-btn {
    opacity: 1;
}

.tm-el-event-inner > .event-btn > svg {
    width: 20px;
    height: 20px;
}

.tm-el-event-inner > .event-btn > .event-btn-remove {
    color: var(--text-error);
    stroke: var(--text-error);
    fill: var(--text-error)
}

.tm-el-event-inner > .event-btn > .event-btn-add {
    color: var(--forest-green);
    stroke: var(--forest-green);
    fill: var(--forest-green)
}

.tm-el-event-inner > .event-btn > .tm-eb-tooltip {
    display: none;
    width: 90px;
    background-color: #fff;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    text-align: center;
    border-radius: 6px;
    border: 1px solid #e8ecee;
    position: absolute;
    margin: 5px 0 0 0;
    z-index: 100;
    top: -5px;
    left: 50%;
    margin-left: -110px;
}

.tm-el-event-inner > .event-btn > .tm-eb-tooltip::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #e8ecee;
}

.tm-el-event-inner > .event-btn > .tm-eb-tooltip > p {
    font-size: 14px;
    margin: 4px;
    color: var(--text-inactive);
    opacity: .6;
}

.tm-el-event-inner > .event-btn:hover > .tm-eb-tooltip {
    display: block;
}

.tm-el-event-inner > .event-details {
    display: flex;
    align-items: center;
}

.tm-el-event-inner > .event-details > .event-img {
    background-color: #e9e9e9;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.tm-el-event-inner > .event-details .event-c-title {
    font-size: 16px;
    margin: 0 0 2px 0;
    color: var(--text-active);
    font-weight: 500;
}

.tm-el-event-inner > .event-details .event-c-info {
    display: flex;
    align-items: center;
}

.tm-el-event-inner > .event-details .event-c-info > p {
    margin: 0;
    color: var(--text-inactive);
    opacity: .8;
    font-size: 15px;
}

.tm-el-event-inner > .event-details .event-c-info > .bullet {
    opacity: .25 !important;
    margin: 0 6px !important;
    color: var(--text-inactive);
    font-size: 15px;
}

.tm-el-event-inner > .event-details .event-c-info > .rating-count {
    margin: 0 0 -2px 2px;
    font-size: 12px;
    color: var(--text-inactive);
    opacity: .45;
}

.tm-el-event-inner > .event-details .event-c-info > .rating-stars > .rating-value {
    font-size: 15px;
}

.tm-el-event-inner > .event-details .event-c-info > .rating-stars > svg {
    margin-right: 1px;
    width: 17px;
    height: 16px;
}

.tm-el-event-inner > .event-details .event-c-info > .rs-unreviewed {
    filter: grayscale(1);
}

.tm-el-event-inner > .event-details .event-c-info > .rs-unreviewed > .rating-value {
    font-size: 14px;
}

/* END OF TEAM MEMBER MODALS */

/* START OF AUDIT CARD */

.audit-card {
    /* width: 1050px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: #fff; */
    padding: 10px 15px;
}

.audit-card:hover {
    background-color: #fcfcfc;
}

.audit-card:not(:last-child) {
    border-bottom: 1px solid #EDEDED;
}

.ac-Events {
    border-left: 4px solid #81c3d76b;
}

.ac-Team {
    border-left: 4px solid #e2c5876b;
}

.ac-Subscription {
    border-left: 4px solid #d781816b;
}

.ac-Reviews {
    border-left: 4px solid #81d7946b;
}

.ac-Widgets {
    border-left: 4px solid #d781cc6b;
}

.ac-details {
    display: flex;
    align-items: flex-start ;
}

.ac-log {
    margin: 0 0 0 5px;
}

.ac-extra-info {
    font-size: 15px;
    color: var(--text-inactive);
    opacity: .5;
    margin: 0 0 5px 0;
}

.ac-extra-info > .bullet {
    opacity: .3;
}

.ac-action {
    margin: 0;
}

/* .ac-team-member {
    width: 11%;
    display: flex;
    align-items: center;
} */

.ac-team-member-name {
    margin: 0;
}

.ac-card-profile-img-container {
    width: 41px;
    height: 41px;
    margin-right: 10px;
}

.audit-card-profile-img {
    width: 41px !important;
    height: 41px !important;
    object-fit: cover;
    border-radius: 50%;
}

.audit-card-profile-img-placeholder {
    width: 41px !important;
    height: 41px !important;
    background-color: var(--background-color);
    border-radius: 50%;
    margin: 0 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.audit-card-profile-img-placeholder > svg {
    width: 41px !important;
    height: 41px !important;
    opacity: .2;
}

/* .ac-action {
    width: 520px;
    overflow: hidden;
} */

.audit-action-bold {
    font-weight: 600;
}

.ac-tag-content {
    padding: 2px 15px;
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
    display: inline-block;
    font-size: 14px;
}

.ac-tag-Events {
    background-color: #81c3d76b;
    color: #3161ad;
}

.ac-tag-Team {
    background-color: #e2c5876b;
    color: #cc8f1f;
}

.ac-tag-Reviews {
    background-color: #81d7946b;
    color: #14911f;
}

.ac-tag-Widgets {
    background-color: #d781cc6b;
    color: #7c1491;
}

.ac-tag-Subscription {
    background-color: #d781816b;
    color: #911414;
}

.ac-timestamp {
    width: 150px;
}

.ac-timestamp-time {
    margin-right: 10px;
}

.ac-mobile-header {
    display: none;
}

/* END OF AUDIT CARD */

.team-cards {
    border-left: 1px solid #DBDBDB;
    margin-left: 15px; 
    padding-left: 15px;
}

.team-member-cards-container {
    height: 100%;
}

.team-member-cards {
    border-top: 1px solid #DBDBDB;
    height: fit-content;
    max-height: calc(100vh - 140px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 15px;
    padding-right: 2px;
    margin-bottom: 15px;
}

/* START OF BUTTON TEAM MEMBER CARD */

.button-team-member-card {
    max-width: 300px;
    min-width: 300px;
    height: 55px;
    border: 2px dashed #C7C7C7;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    margin: 0 0 15px 0;
    transition: all ease-in .15s;
}

.button-team-member-card:hover {
    background-color: #eaeff0;
}

.button-team-member-card > p {
    color: var(--text-inactive);
    text-align: center;
    font-weight: 500;
    margin: 0;
}

.button-team-member-card > svg {
    width: 22px;
    height: 22px;
    fill: var(--text-active);
    stroke: var(--text-active);
    opacity: .6;
    margin-right: 8px;
}

/* END OF BUTTON TEAM MMEMBER CARD */

/* START OF TEAM MEMBER CARD */

.team-member-card {
    min-width: 300px;
    max-width: 300px;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    margin: 0 0 15px 0;
    padding: 12px 15px;
}

.team-member-card-top {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.team-member-card-right {
    margin: 0 0 0 10px;
    width: calc(100% - 55px);
    overflow: hidden;
}

.team-member-card-right > svg {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 23px;
    height: 23px;
    opacity: .7;
}

.team-member-card-right > svg:hover {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 23px;
    height: 23px;
    opacity: .7;
    cursor: pointer;
}

.team-member-card-left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-member-card-profile-img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 0 0 0;
}

.team-member-card-profile-img-placeholder {
    width: 40px;
    height: 40px;
    background-color: var(--background-color);
    border-radius: 50%;
    margin: 0 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-member-card-profile-img-placeholder > svg {
    width: 55px;
    height: 55px;
    opacity: .2;
}

.tm-card-name {
    margin: 0 0 2px 0;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.tm-card-name > svg {
    width: 15px;
    height: 15px;
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    margin: 0 0 0 3px;
    opacity: .7;
    transition: transform ease-out .12s;
}

.tm-card-name:hover > svg {
    opacity: 1;
}

.tm-cn-open > svg {
    transform: rotate(180deg);
}

.tm-card-extra-details {
    font-size: 15px;
    color: var(--text-inactive);
    margin: 5px 0 0 0;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative; */
    word-break: break-all;
    display: flex;
    align-items: center;
}

.tm-card-extra-details > svg {
    width: 18px;
    height: 18px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
    opacity: .5;
    margin: 0 5px -1px 0;
}

.tm-card-extra-details:first-of-type {
    border-top: 1px solid #EDEDED;
    padding-top: 8px;
    margin: 10px 0 0 0;
}

.tm-card-extra-details:not(:last-child) {
    margin-bottom: 3px;
}

.tm-card-label {
    font-weight: 400;
    margin: 0 0 5px 0;
    font-size: 14px;
    color: var(--text-inactive);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.tm-card-label > .bullet {
    opacity: .2;
    margin: 0 4px !important;
}

.tm-card-label > .tm-cl-header {
    opacity: .75;
}

.tm-card-label > .tm-cl-btn > svg {
    width: 14px;
    height: 14px;
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    opacity: .75;
    margin: 0 2px 0 0;
}

.tm-card-label > .tm-cl-btn {
    font-weight: 500;
    font-size: 13px;
    color: var(--text-inactive);
    cursor: pointer;
    height: 14px;
    /* width: 14px; */
    margin: 0 0 0 0;
    display: flex;
    align-items: center;
}

.tm-card-label > .tm-cl-btn:hover {
    text-decoration: underline;
}

.tm-card-edit-btn {
    margin: 10px 0 2px 0;
    border-top: 1px solid #EDEDED;
    padding-top: 12px;
}

.tm-card-edit-btn > p {
    margin: 0;
    width: 100%;
    padding: 5px 8px;
    border: 1px solid #e0e6e8;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    color: var(--text-inactive);
    font-size: 15px;
}

.tm-card-edit-btn > p:hover {
    background-color: #fcfcfc;
}

.tm-card-events-list {
    width: 100%;
    border: 1px solid #E0E6E8;
    border-radius: 3px;
    position: relative;
    transition: all ease-in-out .15s;
    /* max-height: 300px; */
    overflow-y: scroll;
    margin-bottom: 10px;
}

.tm-cel-empty {
    border: 1px dashed #E0E6E8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 5px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: all ease-in .15s;
}

.tm-cel-empty:hover {
    background-color: #fcfcfc;
}

.tm-cel-empty > p {
    margin: 0;
    color: var(--text-inactive);
    opacity: .75;
    font-size: 14px;
    font-weight: 500;
}

.tm-cel-empty:hover > p {
    opacity: .8;
}

.tm-c-event {
    padding: 5px 7px;
}

.tm-c-event:not(:last-child) {
    border-bottom: 1px solid #EDEDED;
}

.tm-c-e-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
    transition: all ease-in .15s;
}

.tm-c-e-inner > .event-details {
    display: flex;
    align-items: center;
}

.tm-c-e-inner > .event-details > .event-img {
    background-color: #e9e9e9;
    border-radius: 5px;
    height: 35px;
    width: 35px;
    margin-right: 7px;
}

.tm-c-e-inner > .event-details .event-c-title {
    font-size: 14px;
    margin: 0 0 2px 0;
    color: var(--text-active);
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 255px;
    overflow: hidden;
}

.tm-c-e-inner > .event-details .event-c-info {
    display: flex;
    align-items: center;
}

.tm-c-e-inner > .event-details .event-c-info > p {
    margin: 0;
    color: var(--text-inactive);
    opacity: .8;
    font-size: 14px;
}

.tm-c-e-inner > .event-details .event-c-info > .rating-stars > .rating-value {
    font-size: 14px;
}

.tm-c-e-inner > .event-details .event-c-info > .rating-stars > .rating-count {
    margin: 0 0 -1px 2px;
    font-size: 12px;
    color: var(--text-inactive);
    opacity: .45;
}

.tm-c-e-inner > .event-details .event-c-info > .rating-stars > svg {
    margin-right: 1px;
    width: 16px;
    height: 15px;
}

.tm-c-e-inner > .event-details .event-c-info > .rs-unreviewed {
    filter: grayscale(1);
}

.tm-c-e-inner > .event-details .event-c-info > .rs-unreviewed > .rating-value {
    font-size: 13px;
}

.tm-c-link {
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    color: var(--racecheck-green);
    margin: 5px 0 8px 0;
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 8px;
}

.tm-c-link:hover {
    text-decoration: underline;
}

.tm-card-btns {
    display: flex;
}

.tm-card-btns > p {
    font-weight: 400;
    cursor: pointer;
    margin: 0;
    color: var(--text-inactive);
    font-size: 14px;
    opacity: .75;
}

.tm-card-btns > .bullet {
    margin: 0 5px !important;
    opacity: .2;
}

.tm-card-btns > p > svg {
    width: 14px;
    height: 14px;
    margin: 0 0 -2px 2px;
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    opacity: .8;
    transition: transform ease-out .15s;
}

.tm-card-btns > p:hover > svg {
    opacity: 1;
}

.tm-card-btns > p > .tm-cb-open-svg {
    transform: rotate(180deg);
}

.tm-card-request-btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 15px 0 0 0;
}

.tm-card-request-btns > button {
    width: 47%;
    cursor: pointer;
    outline: none;
    border: 1px solid #DBDBDB;
    background-color: #fff;
    padding: 4px 0;
    text-align: center;
    border-radius: 5px;
    font-size: smaller;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .15s ease-in-out;
}

.tm-card-request-btns > button > svg {
    height: 15px;
    width: 15px;
    margin-right: 3px;
    margin-left: -7px;
}

.tm-card-request-btns > .tm-crb-accept {
    border-color: var(--racecheck-green);
}

.tm-card-request-btns > .tm-crb-accept:hover {
    background-color: #4abf6b0d;
}

.tm-card-request-btns > .tm-crb-decline {
    border-color: var(--text-error);
}

.tm-card-request-btns > .tm-crb-decline:hover {
    background-color: #bf4a4a0d;
}

.tm-card-request-btns > .tm-crb-accept > svg {
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
}

.tm-card-request-btns > .tm-crb-decline > svg {
    color: var(--text-error);
    stroke: var(--text-error);
}

.tm-card-request-btns-expanded {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #DBDBDB;
}

.team-member-card-bottom {
    margin-top: 12px;
    /* padding-top: 10px;
    border-top: 1px solid #DBDBDB; */
}

.team-member-card-priorities {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 12px;
}

.tm-card-priority {
    background-color: var(--background-color);
    color: rgb(94, 96, 97);
    padding: 2px 13px;
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
    display: inline-block;
    font-size: 14px;
}

.tm-card-priority:not(:last-child) {
    margin-right: 10px;
}

/* END OF TEAM MEMBER CARD */

.button-team-member-card-mobile {
    display: none;
}

.team-members-mobile {
    display: none;
}

.team-members-title {
    font-size: 25px;
    font-weight: 600;
    color: var(--text-normal);
    margin: 0 15px 15px 0;
    display: inline-block;
}

.team-members-secondary-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-normal);
    margin: 0 15px 10px 0;
    display: inline-block;
}

/* RESPONSIVE */

@media screen and (max-width: 900px) {

    .team-container {
        display: block;
    }

    /* AUDIT */

    .team-audit {
        width: 100%;
    }

    .audit-feed-titles {
        display: none;
    }

    .team-audit-filter-icon {
        display: block;
    }

    .audit-card {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
    }

    .ac-action {
        width: 100%;
        margin-top: 10px;
    }

    .ac-action > p {
        margin: 15px 0 0 0;
    }

    .ac-team-member, .ac-tag, .ac-timestamp {
        display: none;
    }

    .ac-mobile-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .ac-mobile-header-left {
        display: flex;
    }

    .acm-team-member-name {
        margin: 0 0 5px 0;
    }

    .acm-timestamp {
        margin: 0;
        font-size: small;
    }

    .acm-tag {
        display: block !important;
        width: auto;
    }

    .ac-details >   .ac-card-profile-img-container {
        display: none;
    }

    .ac-log {
        margin: 0;
        width: 100%;
    }

    .ac-extra-info {
        display: none;
    }

    /* END OF AUDIT */

    /* TEAM MEMBERS */

    .team-cards {
        display: none;
    }

    .team-members-mobile {
        display: flex;
        margin: 0 25px 20px -25px;
        overflow-x: scroll;
        width: 100vw;
        padding: 0 25px;
    }

    .tmm-member, .button-team-member-card-mobile {
        width: 60px;
        margin-right: 15px;
    }

    .tmm-member > img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
        margin: 0 0 0 0;
    }

    .tmm-name {
        margin: 5px 0 0 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: small;
        text-align: center;
    }

    .button-team-member-card-mobile {
        display: block;
    }

    .btmcm-icon {
        border: 2px dashed #C7C7C7;
        border-radius: 50px;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .btmcm-icon:hover {
        background-color: #eff2f3;
    }

    .btmcm-icon > svg {
        width: 30px;
        height: 30px;
        fill: var(--text-inactive);
        stroke: var(--text-inactive);
    }

    .button-team-member-card {
        display: none;
    }

    /* END OF TEAM MEMBERS */

    /* TEAM MEMBER MODAL */

    .tm-role-container {
        flex-direction: column;
    }

    .tm-role {
        width: 100%;
    }

    .tm-role:first-child {
        margin: 0 0 10px 0;
    }

    /* END OF TEAM MEMBER MODAL */

}

/* END OF RESPONSIVE */