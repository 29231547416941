/* START OF REVIEW FEED */

.review-feed-container {
    display: flex;
    margin: 0 auto;
    max-width: 1230px;
    flex-direction: column;
}

.review-feed-cols {
    display: flex;
}

.review-feed-left {
    width: 100%;
}

.review-feed-right {
    margin-left: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
    align-self: flex-start;
}

.rfr-inner-container {
    overflow-y: auto;
    height: calc(100vh - 91px);
    padding-bottom: 15px;
}

/* START OF REVIEW CARD */

.review-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.review-card {
    width: 100%;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    display: flex;
    flex-direction: column;
    margin: 0 0 12px 0;
    z-index: 0;
    padding: 15px 0;
}

.review-card:hover {
    z-index: 3;
}

.review-card-main {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
}

.review-card-left {
    width: 115px;
    border-right: 1px solid #DBDBDB;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    overflow: hidden;
}

.review-card-author-avatar {
    position: relative;
    width: 45px;
    height: 45px;
}

.avatar-laurel-frame-left {
    position: absolute;
    left: -5px;
    bottom: 0px;
    height: 100%;
    transform: scale(-1, 1);
}

.avatar-laurel-frame-right {
    position: absolute;
    right: -5px;
    bottom: 0px;
    height: 100%;
}

.review-card-profile-img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 0 0 0;
}

.review-card-mobile-profile {
    display: none;
}

.review-card-profile-img-placeholder {
    width: 45px;
    height: 45px;
    background-color: var(--background-color);
    border-radius: 50%;
    margin: 0 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review-card-profile-img-placeholder > svg {
    width: 45px;
    height: 45px;
    opacity: .2;
}

.review-card-author-details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.review-card-author {
    margin: 7px 0 0 0;
    text-decoration: none;
    color:var(--text-normal);
    /* max-width: 110px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
    font-weight: 500;
    font-size: 14px;
}

.review-card-author-link {
    cursor: pointer;
}

.review-card-author-link:hover {
    text-decoration: underline !important;
}

.review-card-author-review-count {
    margin: 1px 0 0 0;
    color: var(--text-inactive);
    opacity: .6;
    font-size: 12px;
}

.review-card-bib-no {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    text-align: center;
    width: fit-content;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    margin-top: 7px;
    position: relative;
    max-width: 110px;
}

.review-card-bib-no > svg {
    width: 13px;
    height: 13px;
    color: #14911F;
    stroke: #14911F;
    fill: #14911F;
}

.review-card-bib-no > p {
    margin: 0 0 0 3px;
    font-size: 11px;
    font-weight: 600;
    /* font-family: monospace; */
    overflow: hidden;
}

.review-card-inline-btn {
    font-weight: 500;
    cursor: pointer;
    margin: 0;
    color: var(--text-inactive);
}

.review-card-btn-disabled {
    opacity: .5;
    cursor: default;
    pointer-events: none;
}

.review-card-btn-disabled:hover {
    cursor: default;
    text-decoration: none;
}

.review-card-reported-flag {
    color: var(--text-error) !important;
    font-size: 22px;
    line-height: 16px;
}

.review-card-negative-tag {
    font-size: 11px;
    font-weight: 600;
    padding: 3px 8px;
    border-radius: 25px;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--text-error) !important;
    background-color: #ff4e4e16;
    text-transform: uppercase;
}

.review-card-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 5px 0 15px;
    width: 100%;
}

.review-card-review-data {
    display: flex;
    flex-direction: column;
}

.review-card-details-header {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    margin-bottom: 5px;
}

.review-card-review-details {
    display: flex;
}

.review-card-review-details > .mobile-bullet {
    display: none;
}

.review-card-review-details > p, .review-card-review-details > a  {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--text-inactive);
    opacity: .8;
    font-size: 15px;
}

.review-card-review-details > .bullet {
    opacity: .25 !important;
    margin: 0 6px !important;
}

.review-card-rating-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.review-card-rating-container:hover > .review-card-rating-btn {
    transform: rotate(180deg);
}

.review-card-rating-container:hover > .review-card-rating-tooltip {
    display: block;
}

.review-card-review-details > .review-card-rating-container > .rating-stars > .rating-value {
    font-size: 15px;
    font-weight: 700;
}

.review-card-review-details > .review-card-rating-container > .rating-stars > svg {
    width: 16px;
    height: 16px;
}

.review-card-rating {
    color: var(--racecheck-gold);
    font-weight: 500;
}

.review-card-rating-btn {
    width: 13px;
    height: 13px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    opacity: .5;
    margin-left: 1px;
    margin-top: 2px;
}

.review-card-rating-tooltip {
    display: none;
    background-color: #fff;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    text-align: center;
    border-radius: 6px;
    border: 1px solid #e8ecee;
    position: absolute;
    margin: 0;
    z-index: 100;
    top: 120%;
    left: -10px;
    padding: 12px 15px;
}

.review-card-rating-tooltip:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 72px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #e8ecee transparent;
}

.rcrt-criteria-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 15px 20px;
}

.rcrt-criteria {
    width: 110px;
}

.rcrt-criteria > p {
    font-size: 13px;
    text-align: left;
    color: var(--text-inactive);
    opacity: .7;
    margin: 0 0 3px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rcrt-criteria > .rating-stars > .rating-value {
    display: none;
}

.rcrt-criteria > .rating-stars > svg {
    width: 15px;
    height: 15px;
}

.rc-na {
    display: flex;
    align-items: center;
    padding: 3px 7px 3px 3px;
    background-color: #eeeeee8f;
    border-radius: 10px;
    width: fit-content;
}

.rc-na > svg {
    width: 13px;
    height: 13px;
    color: var(--text-error);
    stroke: var(--text-error);
    fill: var(--text-error);
    margin: 0 3px 0 0;
}

.rc-na > span {
    font-size: 11px;
    color: var(--text-inactive);
    opacity: .7;
    margin: 0;
    font-weight: 500;
}

.review-card-review-text > p {
    margin: 0 0 12px 0;
    font-size: 14px;
}

.review-card-review-text > p > .review-card-inline-btn:hover {
    text-decoration: underline !important;
}

.review-card-review-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    border-top: 1px solid #DBDBDB;
}

.review-card-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
    margin: 0;
    color: var(--text-inactive);
    position: relative;
}

.review-card-btn > svg {
    width: 18px;
    height: 18px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    opacity: .85;
}

.review-card-btn > span {
    margin-left: 5px;
    opacity: .85;
    font-size: 14px;
}

.review-card-btn-bookmarked > svg {
    stroke: var(--racecheck-green);
    opacity: 1;
}

.review-card-btn-reported > svg {
    stroke: var(--text-error);
    opacity: 1;
}

.review-card-btn:hover > span, .review-card-btn:hover > svg {
    opacity: 1;
}

.review-card-btns-right > .review-card-btn {
    margin-left: 10px;
}

.review-card-btns-right > .review-card-btn-bookmark {
    margin-left: 7px;
}

.review-card-btns-right {
    display: flex;
    align-items: center;
}

.review-card-btn-tooltip {
    display: none;
    background-color: #fff;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    text-align: center;
    border-radius: 6px;
    border: 1px solid #e8ecee;
    position: absolute;
    margin: 0;
    z-index: 100;
    top: -192%;
    padding: 1px 10px 5px 10px;
}

.review-card-bib-no > .review-card-btn-tooltip {
    top: -195%;
}

.review-card-btn-tooltip:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #e8ecee transparent transparent transparent;
}

.review-card-btn:hover > .review-card-btn-tooltip, .review-card-bib-no:hover > .review-card-btn-tooltip {
    display: block;
}

.rc-btn-tooltip-desc {
    font-size: 12px;
    color: var(--text-inactive);
    white-space: nowrap;
}

.review-card-reply-container {
    border-top: 1px solid #DBDBDB;
    padding: 15px 15px 0 15px;
    margin-top: 15px;
}

.review-card-reply-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 0 0;
}

.review-card-reply-footer > .modal-btn-row {
    margin: 0;
}

.review-card-reply-footer > .modal-btn-row > .btn {
    height: 30px !important;
    min-height: 30px !important;
    line-height: 30px !important;
    border-radius: 5px !important;
    font-size: 15px;
}

.review-card-reply-footer > .modal-btn-row > .secondary-btn {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    margin-right: 10px !important;
}

.review-card-reply-footer > .modal-btn-row > .secondary-btn > svg {
    width: 18px;
    height: 18px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
}

.rc-review-count-container {
    display: flex;
    align-items: center;
    width: fit-content;
    white-space: nowrap;
}

.review-count-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.review-count-icon > svg {
    width: 40px;
    height: 40px;
    margin-bottom: -8px;
    stroke-width: 10px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
    opacity: .2;
    transform: scaleX(-1);
}

.review-count-icon > span {
    position: absolute;
    font-weight: 600;
    font-size: 18px;
}

.review-count-right {
    margin: 0 0 0 5px;
}

.rc-replies-copy {
    margin: 0 0 1px 0;
    font-size: 13px;
}

.rc-replies-link {
    display: flex;
    align-items: center;
    margin: 0;
    color: var(--racecheck-green);
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
}

.rc-replies-link > svg {
    width: 12px;
    height: 12px;
    margin: 0 0 0 1px;
    transition: all 0.1s ease-in-out;
}

.rc-replies-link:hover > svg {
    margin: 0 0 0 2px;
}

.reply-textarea {
    resize: vertical;
    padding: 6px 10px;
    height: 65px;
    margin: 0;
    font-size: 14px;
}
@-moz-document url-prefix() {
    .reply-textarea {
        height: 6em;
    }
}

.review-reply-copy {
    margin: 0;
    font-style: italic;
}

.review-card-reply-inline {
    margin: 12px 0 12px 10px;
    border-left: 3px solid var(--racecheck-green);
    padding: 6px 5px 6px 10px;
    background-color: #F6F6F6;
    border-radius: 0 7px 7px 0;
}

.review-reply-inline {
    font-size: 14px;
    font-style: italic;
    margin: 0;
}


.review-card-published-container {
    display: flex;
    position: relative;
}

.review-published-timer {
    margin: 0;
    color: var(--racecheck-green);
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 13px;
}

.review-published-timer-explainer .review-card-btn-tooltip {
    top: -145%;
}

.review-published-timer-explainer {
    font-size: 12px;
    color: var(--text-inactive);
}

.review-published-timer svg {
    width: 20px;
    height: 20px;
    fill: var(--racecheck-green);
}

.review-published-timer svg.unpublished {
    fill: rgb(165, 165, 165) ;
}

.review-card-photos {
    margin: 0 0 15px 0;
    display: flex;
}

.review-photo-container {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
}

.review-photo-container:hover {
    box-shadow: 0 0 0 2px var(--racecheck-green);
}

.review-photo-container:not(:last-child) {
    margin-right: 15px;
}

.review-photo-container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rpc-modal {
    margin-bottom: 15px;
}

.reply-remaining-disclaimer {
    display: flex;
    align-items: center;
    border: 1px solid #e0e6e8;
    background-color: #fafafa;
    border-radius: 0 0 4px 4px;
    padding: 0 5px;
    margin-top: -5px;
    max-height: 0;
    transition: all .2s ease;
    overflow: hidden;
}

.reply-remaining-disclaimer-open {
    max-height: 50px;
    padding: 6px 5px;
}

.reply-remaining-disclaimer > p {
    margin: 0;
    font-size: 14px;
    color: var(--text-inactive);
    line-height: 0;
    opacity: 0;
    transition: all .3s ease;
}

.reply-remaining-disclaimer-open > p {
    line-height: 1em;
    opacity: 1;
}

.reply-remaining-disclaimer > svg {
    width: 16px;
    height: 16px;
    color: var(--text-error);
    stroke: var(--text-error);
    fill: var(--text-error);
    margin: 0 3px 0 0;
}

/* END OF REVIEW CARD*/

/* START OF SMART QUESTION CARD */

.sq-divider {
    z-index: revert-layer;
    background: #F3F3F3;
    height: 20px;
    width: 98%;
    margin-top: -20px;
}

.review-smart-question-card {
    width: 100%;
    background: -webkit-linear-gradient(268deg, #FFD80E 0.51%, #DB6DD6 53.67%, #41E2EC 98.64%);
    background: linear-gradient(268deg, #FFD80E 0.51%, #DB6DD6 53.67%, #41E2EC 98.64%);
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    display: flex;
    flex-direction: column;
    margin: -5px 0 17px 0;
    z-index: 0;
    padding: 1px;
    background-clip: padding-box;
    position: relative;
    box-sizing: border-box;    
}

.review-smart-question-card:hover {
    z-index: 1;
}

.sq-inner {
    padding: 10px 12px 12px 12px;
    background-color: rgba(255, 255, 255, 0.97);
    border-radius: 6px;
    position: relative;
}

.sq-top-banner {
    text-align: left;
    height: 28%;
    border-radius: 6px;
    border: 1px solid #f7bf80;
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: #fff9f2;
    margin: 0 -8px 8px -8px;
    position: relative;
}

.sq-explainer-label {
    text-align: left;
    height: 22px;
    border-radius: 6px;
    border: 1px solid #f7bf80;
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: #fff9f2;
    padding: 0 4px 0 0;
}
.sq-top-banner > svg, .sq-explainer-label > svg {
    width: 16px;
    height: 16px;
    margin: 0 3px 0 3px;
}

@media screen and (max-width: 490px) {
    .sq-top-banner > svg, .sq-explainer-label > svg {
        width: 35px;
        height: 35px;
    }
}

.sq-top-banner > p, .sq-explainer-label > p {
    font-size: 13px;
}

@media screen and (max-width: 490px) {
    .sq-top-banner > p, .sq-explainer-label > p {
        font-size: 0.75rem;
    }
}

.sq-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 5px 0;
}

.sq-header > .sq-h-question {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sq-header > .sq-h-question > svg {
    width: 16px;
    height: 16px;
    margin: 0 3px 0 0;
}

.sq-header > .sq-h-question > p {
    font-size: 14px;
    color: var(--text-inactive);
    font-weight: 500;
    margin: 0;
}

.sq-header > .sq-h-question > .sq-h-chevron {
    width: 8px !important;
    height: 8px !important;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    opacity: 0;
    transition: all ease-in .1s;
    margin: 2px 0 0 2px !important;
}

.sq-header > .sq-h-question:hover > .sq-h-chevron {
    opacity: .3;
}

.sq-inner:hover > .sq-header > .sq-h-help, .sq-inner:hover > .sq-top-banner > .sq-h-help {
    opacity: 1;
}

.sq-header > .sq-h-help {
    margin: -3px -5px 0 0;
    transition: all ease .1s;
    position: relative;
}

.sq-top-banner > .sq-h-help {
    opacity: 0;
    transition: all ease .1s;
    position: absolute;
    right: 6px;
    top: 4px;
}

.sq-header > .sq-h-help:hover > .sq-h-tooltip, .sq-top-banner > .sq-h-help:hover > .sq-h-tooltip {
    display: block;
}

.sq-header > .sq-h-help > svg, .sq-top-banner > .sq-h-help > svg {
    width: 16px;
    height: 16px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
    opacity: .4;
    cursor: help;
}

.sq-header > .sq-h-help > svg:hover, .sq-top-banner > .sq-h-help > svg:hover {
    opacity: .6;
}

.sq-h-tooltip {
    display: none;
    background-color: #fff;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    text-align: center;
    border-radius: 6px;
    border: 1px solid #e8ecee;
    position: absolute;
    margin: 0;
    z-index: 100;
    top: 105%;
    right: -5px;
    padding: 0;
    width: 250px;
}

.sq-h-tooltip:after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 8px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #e8ecee transparent;
}

.sq-h-tooltip > .sq-h-tooltip-extra-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px dashed #e8ecee;
}

.sq-h-tooltip > .sq-h-tooltip-desc, .sq-h-tooltip > .sq-h-tooltip-extra-row > .sq-h-tooltip-desc {
    color: var(--text-inactive);
    opacity: .75;
    font-size: 12px;
    text-align: center;
    display: block;
    padding: 8px;
}

.sq-h-tooltip-link {
    font-size: 12px;
    font-weight: 600;
    color: var(--racecheck-green);
    margin: -3px 0 8px 0;
    display: flex;
    align-items: center;
}

.sq-h-tooltip-link:hover {
    text-decoration: underline !important;
}

.sq-h-tooltip-link > svg {
    width: 12px;
    height: 12px;
    margin: 0 3px 0 0;
}

.sq-answer {
    font-size: 14px;
    margin: 0;
}

.sq-answer-blurred {
    color: transparent;
    text-shadow: 0 0 7px var(--text-inactive);
    opacity: .7;
}

.sq-upgrade {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%, -15%);
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 5px 10px rgba(255, 255, 255, 0.5);
    padding: 5px;
    border-radius: 15px;
    text-align: center;
}

@keyframes tilt-shaking {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(10deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-10deg); }
    100% { transform: rotate(0deg); }
}

.sq-card-locked:hover > .sq-inner > .sq-upgrade > svg {
    animation: tilt-shaking 0.25s 2;
}

.sq-upgrade > svg {
    width: 14px;
    height: 14px;
    margin: 0 4px 0 0;
}

.sq-upgrade > a {
    font-size: 14px;
    color: var(--racecheck-green);
    cursor: pointer;
    font-weight: 600;
    white-space: nowrap;
}

.sq-upgrade > a:hover {
    text-decoration: underline !important;
}

.sq-upgrade > span {
    font-size: 14px;
    color: var(--text-inactive);
    white-space: nowrap;
}

@media screen and (max-width: 530px) {
    .sq-upgrade {
        width: 90%;
        bottom: 10%;
        flex-direction: column;
    }

    .sq-upgrade > span {
        white-space: normal;
    }
}

/* END OF SMART QUESTION CARD */

/* SMART QUESTION REPORT */

.sq-report-btn {
    background: linear-gradient(268deg, #FFD80E 0.51%, #DB6DD6 53.67%, #41E2EC 98.64%);
    padding: 1px ;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: inherit;
    height: 40px;
    border-radius: 7px;
    margin: 0 0 10px 0;
}

.sq-r-btn-inner {
    background: #fff;
    padding: 9px;
    width: 100%;
    border-radius: 6px;
    height: 38px;
    display: flex;
    align-items: center;
    transition: all ease-in .15s;
}

.sq-report-btn:hover > .sq-r-btn-inner {
    background: rgba(255, 255, 255, 0.95);
}

.review-checklist-copy-info > svg {
    width: 17px;
    height: 17px;
    margin-right: 8px;
    margin-left: 5px;
}

.smart-question-report-container {
    display: flex;
    margin: 0 auto;
    max-width: 860px;
    flex-direction: column;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    padding: 15px;
}

.sq-r-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 15px 0;
}

.sq-r-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sq-r-top > a {
    color: var(--text-inactive);
    opacity: .75;
    transition: all ease-in .15s;
    cursor: pointer;
}

.sq-r-top > a > span {
    color: var(--racecheck-green);
}

.sq-r-top > a:hover {
    opacity: 1;
}

.sq-r-h-title {
    margin: 0;
    display: flex;
    align-items: center;
}

.sq-r-h-title > h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: var(--text-normal);
}

.sq-r-h-title > a {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-normal);
    margin-right: 5px;
    cursor: pointer;
    opacity: .7;
    transition: all ease-out .15s;
}

.sq-r-h-title > a:hover {
    opacity: 1;
}

.sq-r-filters {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin: 15px 0 20px 0;
    border-bottom: 1px solid #E8ECED;
    border-top: 1px solid #E8ECED;
    padding: 10px 0;
}

.sq-r-filters > select {
    margin: 0 0 0 10px !important;
    height: 35px;
    width: initial;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #E8ECED;
    padding: 6px 28px 6px 10px;
    font-size: inherit;
    box-sizing: border-box;
    appearance: none;
    display: flex;
    align-items: center;
    color: var(--text-inactive);
    font-weight: 500;
    font: inherit;
    box-shadow: 0px 0px 6px -2px rgba(185, 185, 185, 0.2);
    background-position: calc(100% - 15px) .85em,
        calc(100% - 10px) 0.85em,
        calc(100% - -0.5em) 0.5em;
    background-image: linear-gradient(45deg, transparent 50%, rgb(204, 204, 204) 50%),
        linear-gradient(135deg, rgb(204, 204, 204) 50%, transparent 50%),
        linear-gradient(to right, #fff, #fff);
}

.sq-r-filters > select:focus {
    border: 1px solid var(--racecheck-green);
    outline: none;
}

.rs-report-card > .sq-inner {
    padding: 10px 15px 15px 13px !important;
}

.rs-rc-header {
    margin: 0 0 15px 0;
}

.rs-rc-question {
    font-size: 16px;
    margin: 0 0 1px 0 !important;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--text-normal);
}

.rs-rc-question > svg {
    width: 20px;
    height: 20px;
    margin: 6px 7px 0 0;
}

.rs-rc-q-chevron {
    width: 10px !important;
    height: 10px !important;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    opacity: 0;
    transition: all ease-in;
    margin: 1px 0 0 3px !important;
}

.rs-rc-question:hover > .rs-rc-q-chevron {
    opacity: .3;
}

.rs-rc-count {
    color: var(--text-inactive);
    opacity: .75;
    font-size: 12px;
    margin: -3px 0 0 0 !important;
    padding: 0 0 0 27px;
}

.rs-rc-responses {
    padding: 0 10px 0 27px;
}

.rs-rc-response {
    margin: 0 0 10px 0 !important;
    padding: 0 0 10px 0;
    font-size: 14px;
}

.rs-rc-response:not(:last-of-type) {
    border-bottom: 1px solid #EDEDED;
}

.rs-rc-btn {
    opacity: .75;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-inactive);
    margin: 0;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all ease-out .15s;
    background: none;
    padding: 0;
}

.rs-rc-btn:hover {
    opacity: 1;
}

.sq-modal {
    background: -webkit-linear-gradient(268deg, #FFD80E 0.51%, #DB6DD6 53.67%, #41E2EC 98.64%);
    background: linear-gradient(268deg, #FFD80E 0.51%, #DB6DD6 53.67%, #41E2EC 98.64%);
    padding: 1px;
}

.sq-modal > .modal-scrollable {
    background: #fff;
    border-radius: 4px;
}

.sq-modal > .modal-scrollable > .modal-content {
    margin: 10px 20px;
}

.sq-modal > .modal-scrollable > .modal-content > .rs-rc-header {
    position: relative;
}

.sq-modal > .modal-scrollable > .modal-content > .rs-rc-header > .rs-rc-question {
    cursor: initial;
}

.sq-modal > .modal-scrollable > .modal-content > .rs-rc-header > .modal-close-btn {
    margin: 0;
    right: -10px;
    top: -2px;
    opacity: .5;
    transition: opacity ease-in .1s;
}

.sq-modal > .modal-scrollable > .modal-content > .rs-rc-header > .modal-close-btn:hover {
    opacity: 1;
}

.sq-modal > .modal-scrollable > .modal-content > .rs-rc-header > .modal-close-btn > svg {
    width: 25px !important;
    height: 25px !important;
}

.sq-modal > .modal-scrollable > .modal-content > .modal-content-section {
    padding: 0 27px;
}

/* END OF SMART QUESTION REPORT */

/* SOCIAL IMAGE GENERATION */

.rig-modal-content {
    margin: 0 !important;
}

.rig-modal-content-section {
    margin: 0 30px !important;
}

.rig-modal-content-section:not(:last-child) {
    border-bottom: none;
}

.rig-section-header {
    color: var(--text-normal);
    font-weight: 500;
    margin: 0 0 10px 0 !important;
}

.rig-review-details {
    align-items: center;
    margin-bottom: 10px;
}

.rig-review-details > .review-card-author-avatar {
    width: 22px;
    height: 22px;
    margin-right: 6px;
}

.rig-review-details > .review-card-author-avatar > .review-card-profile-img {
    width: 22px;
    height: 22px;
}

.rig-review-details > .review-card-author-avatar > .avatar-laurel-frame-left {
    left: -2px;
}

.rig-review-details > .review-card-author-avatar > .avatar-laurel-frame-right {
    right: -2px;
}

.rig-sentence {
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-radius: 3px;
    line-height: 20px;
    padding: 1px 0;
    position: relative;
    display: inline;
}

.rig-sentence:hover {
    background-color: #f4f4f4a9;
    border-left: 2px solid #4ABF6C;
    border-right: 2px solid #4ABF6C;
    cursor: pointer;
}

.rig-sentence-selected {
    background-color: #4abf6b1a;
    border-left: 2px solid #4ABF6C;
    border-right: 2px solid #4ABF6C;
}

.rig-sentence-selected:hover {
    background-color: #ff4e4e16;
    border-left: 2px solid #DB4E4E;
    border-right: 2px solid #DB4E4E;
}

.rig-sentence-tooltip {
    display: none;
    background-color: #fff;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    text-align: center;
    border-radius: 5px;
    border: 1px solid #e8ecee;
    position: fixed;
    margin: 0;
    z-index: 100;
    /* top: -170%;
    left: 50%; */
    padding: 3px 7px;
    width: fit-content;
    font-size: 13px;
    margin-left: -60px;
    align-items: center;
    justify-content: center;
}

.rig-sentence-tooltip:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #e8ecee transparent transparent transparent;
}

.rig-sentence-tooltip > svg {
    height: 14px;
    margin-right: 3px;
}

.rig-st-remove {
    color: var(--text-error);
}

.rig-st-add {
    color: var(--forest-green);
}

.rig-sentence:hover > .rig-sentence-tooltip {
    display: flex;
    position: fixed;
}

.social-content-preview {
    background-color: #dfdfdf;
    padding: 20px 20px 7px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.social-post-canvas {
    background-color: #212B3B;
    max-height: 270px;
    min-height: 270px;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    color: #fff;
    background-size: cover;
    background-position: center;
    font-family: 'Poppins', sans-serif;
    aspect-ratio: 1.91 / 1;
}

.spc-landscape {
    aspect-ratio: 1.91 / 1 !important;
}

.spc-portrait {
    aspect-ratio: 9 / 16 !important;
}

.spc-square {
    aspect-ratio: 1 / 1 !important;
}

.spc-green {
    background-color: #37AE5A;
}

.spc-dark {
    background-color: #212B3B;
}

.spc-green.spc-square.spc-confetti {
    background-image: url("../../images/social-images/square-green-confetti.svg");
}

.spc-dark.spc-square.spc-confetti {
    background-image: url("../../images/social-images/square-dark-confetti.svg");
}

.spc-green.spc-square.spc-icons {
    background-image: url("../../images/social-images/square-green-icons.svg");
}

.spc-dark.spc-square.spc-icons {
    background-image: url("../../images/social-images/square-dark-icons.svg");
}

.spc-green.spc-square.spc-dots {
    background-image: url("../../images/social-images/square-green-dots.svg");
}

.spc-dark.spc-square.spc-dots {
    background-image: url("../../images/social-images/square-dark-dots.svg");
}

.spc-green.spc-landscape.spc-confetti {
    background-image: url("../../images/social-images/landscape-green-confetti.svg");
}

.spc-dark.spc-landscape.spc-confetti {
    background-image: url("../../images/social-images/landscape-dark-confetti.svg");
}

.spc-green.spc-landscape.spc-icons {
    background-image: url("../../images/social-images/landscape-green-icons.svg");
}

.spc-dark.spc-landscape.spc-icons {
    background-image: url("../../images/social-images/landscape-dark-icons.svg");
}

.spc-green.spc-landscape.spc-dots {
    background-image: url("../../images/social-images/landscape-green-dots.svg");
}

.spc-dark.spc-landscape.spc-dots {
    background-image: url("../../images/social-images/landscape-dark-dots.svg");
}

.spc-green.spc-portrait.spc-confetti {
    background-image: url("../../images/social-images/portrait-green-confetti.svg");
}

.spc-dark.spc-portrait.spc-confetti {
    background-image: url("../../images/social-images/portrait-dark-confetti.svg");
}

.spc-green.spc-portrait.spc-icons {
    background-image: url("../../images/social-images/portrait-green-icons.svg");
}

.spc-dark.spc-portrait.spc-icons {
    background-image: url("../../images/social-images/portrait-dark-icons.svg");
}

.spc-green.spc-portrait.spc-dots {
    background-image: url("../../images/social-images/portrait-green-dots.svg");
}

.spc-dark.spc-portrait.spc-dots {
    background-image: url("../../images/social-images/portrait-dark-dots.svg");
}

.spc-loading > .section-loading-container {
    height: 100%;
}

.spc-loading > .section-loading-container > .lds-roller > div:after {
    background: var(--racecheck-green);
    opacity: .3;
}

.spc-event-title-container {
    width: 100%;
}

.spc-event-title {
    font-weight: 600;
    font-style: italic;
    font-size: 15px;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    height: 20px;
}

.spc-review-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 216px;
    width: 100%;
    position: relative;
}

.spc-review-container > .speech-mark {
    position: absolute;
    height: 50%;
    opacity: .5;
}

.spc-review-container > .sp-left {
    left: 0;
    top: 20px;
}

.spc-review-container > .sp-right {
    right: 0;
    bottom: 20px;
}

.spc-dark > .spc-review-container > .speech-mark {
    color: var(--racecheck-green);
    fill: var(--racecheck-green);
    opacity: .7;
}

.spc-green > .spc-review-container > .speech-mark {
    color: #131B27;
    fill: #131B27;
    opacity: .5;
}

.spc-review-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    padding: 0 20px;
}

.spc-portrait > .spc-review-container > .spc-review-content {
    padding: 0 10px;
}

.spc-portrait > .spc-review-container > .speech-mark {
    height: 30%;
}

.spc-portrait > .spc-review-container > .sp-left {
    left: -15px;
    top: 10px;
}

.spc-portrait > .spc-review-container > .sp-right {
    right: -15px;
    bottom: 10px;
}

.social-text-container {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    margin: 5px 0;
    position: relative;
    text-align: center;
}

.spc-review-author {
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    font-style: italic;
    font-weight: 500;
    font-size: 13px;
}

.spc-portrait > .spc-review-container > .spc-review-content > .spc-review-author {
    font-size: 9px;
}

.review-share-text {
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    font-weight: 600;
    position: relative;
}

/* .review-share-text:before { 
    content: url("../../images/social-images/speech-mark-left.svg"); 
    font-size: 200%;
    font-family: Times New Roman;
    position: absolute;
    top: 0;
    left: 0;
}

.review-share-text:after { 
    content: '"'; 
    font-size: 200%;
    font-family: Times New Roman; 
    position: absolute;
    bottom: 0;
    right: 0;
} */

.social-post-canvas p {
    margin: 0;
}

.spc-stars > .rating-stars > .rating-value {
    font-size: 16px;
}

.spc-stars > .rating-stars > svg {
    width: 16px;
}

.spc-portrait > .spc-review-container > .spc-review-content > .spc-stars > .rating-stars > .rating-value {
    font-size: 12px;
}

.spc-portrait > .spc-review-container > .spc-review-content > .spc-stars > .rating-stars > svg {
    width: 13px;
}

.spc-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.spc-footer > p {
    font-size: 10px;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.spc-footer > img {
    margin-left: 5px;
    width: 100px;
}

.spc-portrait > .spc-footer > p {
    font-size: 7px;
}

.spc-portrait > .spc-footer > img {
    margin-left: 3px;
    width: 60px;
}

.rig-modal-btn-row {
    padding: 20px 0;
    margin: 0 30px;
    width: auto;
    justify-content: space-between;
}

.hidden-btn {
    opacity: 0;
}

.rig-modal-btn-row > .btn {
    padding: 0 20px !important;
}

.rig-modal-btn-row > .sub-fixed-width-btn {
    width: 117px;
}

.rig-footer-stepper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.rig-footer-stepper > .rig-stepper-step {
    width: 25px;
    height: 25px;
    border-radius: 20px;
    border: 1px solid #9CA6AF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
}

.rig-footer-stepper > .rig-stepper-step > p {
    color: #A5A5A5;
    font-size: 12px;
    font-weight: 500;
}

.rig-footer-stepper > .rig-stepper-connector {
    height: 1px;
    background-color: #9CA6AF;
    width: 20%;
    transition: all 0.2s ease;
}

.rig-footer-stepper > .rig-stepper-completed-step {
    border: 1px solid var(--racecheck-green);
}

.rig-footer-stepper > .rig-stepper-completed-step > p {
    color: var(--racecheck-green);
}

.rig-footer-stepper > .rig-stepper-active-connector {
    background-color: var(--racecheck-green);
}

.rig-footer-stepper > .rig-stepper-active-step {
    border: 1px solid var(--racecheck-green);
    background-color: var(--racecheck-green);
}

.rig-footer-stepper > .rig-stepper-active-step > p {
    color: #fff;
}

.rig-footer-stepper > .rig-stepper-step:hover > .review-card-btn-tooltip, .review-card-bib-no:hover > .review-card-btn-tooltip {
    display: block;
}

.rig-footer-stepper > .rig-stepper-disabled-step > p, .rig-footer-stepper > .rig-stepper-disabled-connector {
    opacity: .5;
}

.rig-footer-stepper > .rig-stepper-disabled-step {
    border: 1px solid #9ca6af7f;
}

.rig-footer-stepper > .rig-stepper-disabled-step {
    cursor: not-allowed;
}

.rig-backgrounds-list {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 15px;
}

.rig-background-option {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background-color: #D9D9D9;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    margin-bottom: 10px;
}

.rig-bo-container:not(:last-child) {
    margin-right: 10px;
}

.rig-bo-container:hover {
    box-shadow: inset 0 -3px #cecece;
}

.rig-bo-selected, .rig-bo-selected:hover {
    box-shadow: inset 0 -3px var(--racecheck-green);
}

.rig-bo-dark {
    background-color: #212B3B;
}

.rig-bo-green {
    background-color: #37AE5A;
}

.rig-bo-icons {
    background-size: 75%;
    background-repeat: no-repeat;
}

.rig-background-option.rig-bo-green.rig-bo-confetti {
    background-image: url("../../images/social-images/bg-previews/bg-preview-dark-confetti.svg");
}

.rig-background-option.rig-bo-green.rig-bo-dots {
    background-image: url("../../images/social-images/bg-previews/bg-preview-dark-dots.svg");
}

.rig-background-option.rig-bo-green.rig-bo-icons {
    background-image: url("../../images/social-images/bg-previews/bg-preview-dark-icons.svg");
}

.rig-background-option.rig-bo-dark.rig-bo-confetti {
    background-image: url("../../images/social-images/bg-previews/bg-preview-green-confetti.svg");
}

.rig-background-option.rig-bo-dark.rig-bo-dots {
    background-image: url("../../images/social-images/bg-previews/bg-preview-green-dots.svg");
}

.rig-background-option.rig-bo-dark.rig-bo-icons {
    background-image: url("../../images/social-images/bg-previews/bg-preview-green-icons.svg");
}

.rig-size-option {
    width: 100%;
    border: 2px solid #e8ecee;
    border-radius: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.rig-size-option:hover, .rig-size-option > *:hover {
    cursor: pointer !important;
}

.rig-so-active {
    border: 2px solid var(--racecheck-green);
}

.rig-so-label {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 8px 10px;
    cursor: pointer;
    pointer-events: none;
}

.rig-so-copy {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.rig-so-title {
    font-size: 17px;
    font-weight: 600;
    margin: 0 0 2px 0 !important;
    color: var(--text-normal);
    display: flex;
    align-items: center;
}

.rig-so-title > svg {
    margin-right: 5px;
    height: 17px;
    width: 17px;
}

.rig-so-desc {
    margin: 0 !important;
    display: flex;
    align-items: center;
    opacity: .8;
    font-size: 15px;
}

.rig-size-option input[type='radio'] {
  position: relative;
  height: 22px;
  width: 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
}

.rig-size-option input[type='radio']::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #e8ecee;
}

.rig-size-option input[type='radio']:checked::before {
    border: 2px solid var(--racecheck-green);
}

.rig-size-option input[type='radio']::before {
    border: 2px solid var(--very-light-grey);
}

.rig-size-option input[type='radio']:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--racecheck-green);
    transform: translate(-50%, -50%);
    visibility: visible;
}

.social-images-footer {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.si-footer-info {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .4;
    color: var(--text-inactive); 
    margin: 0 4px;
}

.si-footer-info > p {
    font-size: 14px;
}

.si-footer-title {
    display: flex;
    align-items: center;
}

.si-footer-title > svg {
    margin-right: 3px;
    height: 15px;
    width: 15px;
}

.si-footer-arrow {
    height: 15px;
    cursor: pointer;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
    opacity: .7;
    transition: all 0.1s ease;
}

.si-footer-arrow:hover {
    opacity: .9;
}

.si-footer-arrow:last-child {
    transform: rotate(180deg);
}

.placeholder-text-container {
    background-color: rgba(255, 255, 255, 0.15);
    padding: 10px;
    width: 75%;
    border-radius: 5px;
    border: 2px dashed rgba(255, 255, 255, 0.33);
    height: auto;
}

.placeholder-review-text {
    font-size: 13px !important;
    font-weight: 500;
    font-style: italic;
}

.spc-square > .spc-review-container > .spc-review-content > .placeholder-text-container > .placeholder-review-text {
    font-size: 11px !important;
}

.spc-portrait > .spc-review-container > .spc-review-content > .placeholder-text-container {
    width: 100%;
}

.spc-portrait > .spc-review-container > .spc-review-content > .placeholder-text-container > .placeholder-review-text {
    font-size: 9px !important;
}

/* END OF SOCIAL IMAGE GENERATION */

/* REVIEW REPLIES UPGRADE BANNER */

.review-no-replies-banner {
    border-left: 2px solid var(--racecheck-green);
    background-color: #f9fffb;
    padding: 10px 10px 10px 15px;
    cursor: pointer;
    text-decoration: none;
    display: block;
    color: inherit;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    margin-bottom: 10px;
}

.review-no-replies-banner:hover {
    background-color: #f7fffa;
}

.review-no-replies-copy {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.review-no-replies-copy > .btn {
    margin: 0;
    min-height: 29px !important;
    height: 29px !important;
    font-size: 14px;
}

.review-no-replies-copy-info {
    display: flex;
    align-items: center;
}

.review-no-replies-copy-info > .text {
    font-weight: 400;
    margin: 0;
}

.review-no-replies-copy-info > .text > span {
    font-weight: 500;
}

.review-no-replies-copy-info > svg {
    width: 25px;
    height: 25px;
    margin-right: 8px;
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    fill: var(--racecheck-green);
}

/* END OF REVIEW REPLIES UPGRADE BANNER */

/* START OF REVIEWS RIGHT COL */

.smart-questions-intro {
    background: -webkit-linear-gradient(268deg, #FFD80E 0.51%, #DB6DD6 53.67%, #41E2EC 98.64%);
    background: linear-gradient(268deg, #FFD80E 0.51%, #DB6DD6 53.67%, #41E2EC 98.64%);
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    width: 300px;
    padding: 1px;
    margin-bottom: 10px;
}

.sq-i-inner {
    padding: 10px 12px 12px 12px;
    background-color: #fffffff8;
    border-radius: 6px;
    position: relative;
}

.sq-i-inner > svg {
    width: 25px;
    height: 25px;
}

.sq-i-inner > .sq-i-title {
    margin: 4px 0 2px 0;
    font-size: 15px;
    font-weight: 500;
}

.sq-i-inner > .sq-i-copy {
    font-size: 14px;
    margin: 0;
    color: var(--text-inactive);
}

.sq-i-inner > a {
    font-weight: 500;
    color: var(--racecheck-green);
    font-size: 14px;
    margin: 5px 0 0 0;
    cursor: pointer;
    display: block;
}

.sq-i-inner > a:hover {
    text-decoration: underline !important;
}

.sq-report-btn-disabled {
    pointer-events: none;
}

.review-rc-divider {
    width: 100%;
    border-top: 1px solid #DBDBDB;
    opacity: .75;
    margin: 10px 0;
}

.award-progress-container, .rating-breakdown-container, .trophy-cabinet-container, .review-count-container, .review-checklist-container, .review-breakdown-container, .review-analytics-container, .review-nps-container {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    width: 300px;
    padding: 10px 15px;
    margin-bottom: 10px;
}

.review-right-header-container {
    position: relative;
}

.review-nps-title {
    display: flex;
    align-items: center;
    width: fit-content;
}

.review-nps-title > svg {
    width: 15px;
    height: 15px;
    opacity: .5;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
    cursor: help;
    margin-bottom: 0;
    margin-left: 2px;
}

.review-nps-wrap {
    display: flex;
    align-items: center;
}

.nps-chart {
    width: 40%;
    height: 100px;
    margin: 0 15px 0 0;
    position: relative;
}

.nps-score-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nps-score-container > span {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
}

.nps-breakdown-container {
    width: 100%;
}

.nps-vg-copy {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0 0 2px 0;
}

.nps-vg-copy > p:first-of-type {
    font-size: 14px;
    opacity: .6;
    color: var(--text-inactive);
    margin: 0;
}

.nps-vg-copy > p:last-of-type {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.nps-value-group > .progress-bar-container {
    height: 8px;
}

.nps-value-group > .progress-bar-container > .shrinker {
    background-color: #E8ECED;
}

.nps-value-group > .pbc-nps-promoters > .progress {
    background-color: #41AC6F;
}

.nps-value-group > .pbc-nps-passives > .progress {
    background-color: #F2AB3C;
}

.nps-value-group > .pbc-nps-detractors > .progress {
    background-color: #CB3D35;
}

.nps-title-tooltip {
    display: none;
    width: 260px;
    background-color: #fff;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    text-align: center;
    border-radius: 6px;
    border: 1px solid #e8ecee;
    position: absolute;
    margin: 5px 0 0 0;
    z-index: 100;
    top: 100%;
    left: 50%;
    margin-left: -130px;
}

.nps-title-tooltip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 53%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #e8ecee transparent;
}

.review-nps-title:hover ~ .nps-title-tooltip {
    display: block;
}

.nps-title-tooltip > .nps-tooltip-desc, .nps-tooltip-extra-row > .nps-tooltip-desc {
    color: var(--text-inactive);
    opacity: .75;
    font-size: 13px;
    text-align: center;
    display: block;
    padding: 8px 6px;
    white-space: normal;
    margin: 0;
}

.nps-tooltip-extra-row > .nps-tooltip-desc > span:first-of-type {
    color: #41AC6F;
    font-weight: 500;
}

.nps-tooltip-extra-row > .nps-tooltip-desc > span:last-of-type {
    color: #CB3D35;
    font-weight: 500;
}

.nps-title-tooltip > .nps-tooltip-extra-row > .nps-tooltip-desc {
    padding: 8px 8px 8px 0;
    font-size: 13px;
}

.nps-tooltip-extra-row {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px dashed #e8ecee;
}

.nps-tooltip-extra-row > .nps-tooltip-desc {
    padding: 8px !important;
}

.review-checklist-container {
    border-left: 2px solid rgb(255, 78, 78);
    background-color: #FFF9F9;
    padding: 0 10px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: inherit;
    height: 40px;
}

.review-checklist-container:hover {
    background-color: #fff5f5;
}

.award-progess-title, .rating-breakdown-title, .trophy-cabinet-title, .review-count-title, .review-checklist-title, .review-analytics-title, .review-nps-title {
    font-weight: 400;
    color: var(--text-inactive);
    margin: 0 0 10px 0;
    opacity: .6;
    font-size: 15px;
}

.review-checklist-copy {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.review-checklist-copy-info {
    display: flex;
    align-items: center;
}

.review-checklist-copy-info > .text {
    font-weight: 500;
    font-size: 15px;
}

.review-checklist-copy-info > .count {
    width: 20px;
    height: 20px;
    background-color: rgb(255, 78, 78);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    color: #fff;
    font-weight: 600;
    margin-right: 8px;
    font-size: 13px;
}

.review-checklist-copy > .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.review-checklist-container:hover > .review-checklist-copy > .arrow > svg, .sq-report-btn:hover > .sq-r-btn-inner > .review-checklist-copy > .arrow > svg {
    opacity: .45;
}

.review-checklist-copy > .arrow > svg {
    height: 12px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
    opacity: .3;
    transition: all ease .2s;
}

.rating-breakdown-container > .rating-stars > svg {
    width: 24px;
    height: 24px;
}

.rating-breakdown-container > .rating-stars {
    justify-content: center;
}

.rating-breakdown-container > .rating-stars > .rating-value {
    font-size: 23px;
    margin-right: 5px !important;
    font-weight: 700;
}

.rating-breakdown-container > .rating-breakdown-count {
    margin: 2px 0 0 0;
    text-align: center;
    color: #58585888;
    font-size: 14px;
}

.rating-breakdown-container > .rating-breakdown-count > span {
    font-weight: 600;
    color: var(--text-normal)
}

.see-full-breakdown, .hide-full-breakdown {
    text-align: center;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 10px;
    color: var(--text-inactive);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}

.hide-full-breakdown {
    margin-top: 20px;
}

.see-full-breakdown:hover, .hide-full-breakdown:hover {
    text-decoration: underline;
    cursor: pointer;
}

.see-full-breakdown > span, .hide-full-breakdown > span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1px;
}

.see-full-breakdown > span > svg {
    width: 15px;
    height: 15px;
    stroke: var(--text-inactive);
    margin: 2px 0 0 2px;
}

.hide-full-breakdown > span > svg {
    width: 15px;
    height: 15px;
    stroke: var(--text-inactive);
    margin: 2px 0 0 2px;
    transform: rotate(180deg);
}

.rating-breakdown-subheader {
    margin: 16px 0 -4px 0;
    font-weight: 500;
    color: var(--text-inactive);
    opacity: .8;
    font-size: 16px;
}

.rating-breakdown-label {
    margin: 15px 0 5px 0;
    font-weight: 500;
    color: var(--text-inactive);
    opacity: .7;
    font-size: 14px;
}

.rating-breakdown-divider {
    border-top: 1px solid #DBDBDB;
    width: 100%;
    height: 1px;
    margin-top: 18px;
}

.review-count-number {
    margin: 0;
    font-size: 30px;
    font-weight: 600;
}

.review-count-copy {
    font-size: 21px;
}

.progress-bar-container {
    width: 100%;
	height: 10px;
	margin: 0 auto 10px 0;
	border-radius: 35px;
    overflow: hidden;
    position: relative;
}

.progress-bar-child {
	width: 100%;
	height: 100%;
}

.progress {
    color: white;
	text-align: center;
	line-height: 75px;
	font-size: 35px;
	font-family: "Segoe UI";
	animation-direction: reverse;
	background: var(--racecheck-green);
}

.shrinker {
	background-color: #d4e9da;
	position: absolute;
	top: 0;
	right: 0;
}

.progress-bar-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    padding-top: 15px;
    border-top: 1px solid #DBDBDB;
    align-items: baseline;
}

.progress-bar-label-group {
    display: flex;
    align-items: baseline;
}

.progress-bar-percentage {
    margin: 0;
    font-weight: 700;
    font-size: 13px;
    color: var(--text-inactive);
    opacity: .5;
}

.progress-bar-value {
    margin: 0 3px 0 0;
    font-weight: 700;
    font-size: 20px;
    color: var(--racecheck-green);
}

.award-progress-bar {
    position: relative;
    margin: 20px 0;
    text-align: center;
}

.award-progress-bar-overflow { /* Wraps the rotating .bar */
    position: relative;
    overflow: hidden; /* Comment this line to understand the trick */
    width: 200px; height: 100px; /* Half circle (overflow) */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.apb-bar {
    position: absolute;
    top: 0; left: 0;
    width: 200px; height: 200px; /* full circle! */
    border-radius: 50%;
    box-sizing: border-box;
    border: 15px solid var(--racecheck-green);     /* half gray, */
    border-bottom-color: #F6F6F6 !important;  /* half azure */
    border-right-color: #F6F6F6 !important;
}

.apb-label {
    margin: 0;
    font-size: 36px;
    font-weight: 600;
}

.apb-sublabel {
    margin: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
    opacity: .5;
}

.award-progress-copy {
    text-align: center;
    margin: 0 0 5px 0;
    font-size: 14px;
}

.ap-top-rated-copy {
    margin-bottom: 5px !important;
}

.apc-rating {
    color: var(--racecheck-gold);
    font-weight: 700;
    font-size: 14px;
    display: inline-block;
    margin-left: 3px;
}

.apc-rating > svg {
    margin-left: 2px;
    margin-bottom: -2px;
    width: 15px;
    height: 15px;
}

.apc-rating-out-of {
    font-size: 14px;
    color: var(--racecheck-gold);
    font-weight: 600;
    margin-left: 2px;
    letter-spacing: 2px;
}

.apc-award {
    /* font-size:  20px; */
    font-weight: 400;   
}

.apc-Gold {
    color: var(--racecheck-gold);
}

.apc-Silver {
    color: var(--racecheck-silver-text);
}

.apc-Bronze {
    color: var(--racecheck-bronze);
}

.apc-Top {
    color: var(--racecheck-green);
}

.progress-bar-group > .progress-bar-label {
    border: none;
    justify-content: initial;
    padding: 5px 0 0 0;
    margin: 0 0 3px 0;
}

.progress-bar-group > .progress-bar-label > .progress-bar-label-group {
    align-items: flex-end;
}

.progress-bar-group > .progress-bar-label > .progress-bar-label-group > .progress-bar-value {
    font-size: 20px;
    color: var(--text-normal);
    font-weight: 700;
}

.progress-bar-group > .progress-bar-label > .progress-bar-label-group > .progress-bar-percentage {
    font-size: 12px;
    color: var(--text-inactive);
    font-weight: 700;
    opacity: .6;
    font-weight: 400;
    margin: 0 0 2px 0;
}

.progress-bar-group > .progress-bar-label > .progress-bar-label-group > svg {
    width: 15px;
    height: 15px;
    color: var(--forest-green);
    stroke: var(--forest-green);
    fill: var(--forest-green);
    margin: 0 0 2px 3px;
}

.progress-bar-group > .progress-bar-container {
    height: 10px;
    border-radius: 5px;
}

.progress-bar-group > .progress-bar-container > .progress {
    background-color: var(--forest-green);
    height: 10px;
    border-radius: 5px;
}

.progress-bar-group > .progress-bar-container > .shrinker {
    background-color: var(--background-color);
    height: 10px;
}

.award-containers {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
}

.individual-award {
    display: flex;
    flex-direction: column;
    width: 45%;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0 0 0;
}

.trophy-cabinet-award {
    width: 65%;
    margin: 0 0 10px 0;
}

.trophy-cabinet-award-name {
    text-align: center;
    margin: 0;
    font-size: 13px;
    color: var(--text-inactive);
}

.trophy-cabinet-btn {
    margin: 20px 0 0 0 !important;
    width: 100%;
}

.trophy-toolkits-link {
    font-weight: 500;
    color: var(--text-inactive);
}

.trophy-toolkits-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.reviews-toolkit-cta {
    background-color: var(--racecheck-green);
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    width: 300px;
    padding: 20px 25px 20px 25px;
    color: #fff;
}

.reviews-toolkit-cta > a {
    color: inherit;
    text-decoration: none;
}

.toolkits-cta-title {
    font-size: 25px;
    margin: 0 0 10px 0;
    font-weight: 500;
}

.toolkits-cta-copy {
    margin: 0 0 25px 0;
}

.toolkits-cta-link {
    font-weight: 500;
    margin: 0;
}

.toolkits-cta-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.review-analytics-btn {
    margin-top: 20px;
}

.review-analytics-wrap {
    align-items: baseline;
}

.review-analytics-wrap > .divider {
    width: 100%;
    border-top: 1px solid #DBDBDB;
    opacity: .5;
    margin: 10px 0;
}

.review-analytics-wrap > .mr-stat-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
    margin: 0;
}

.review-analytics-wrap > .mr-stat-container > img {
    width: 20px;
    height: 20px;
    margin: 4px 5px 0 0;
}

.review-analytics-wrap > .mr-stat-container > .mr-copy-container > .mr-stat {
    font-size: 23px;
    color: var(--text-normal);
    font-weight: 700;
    margin: 0;
    text-align: left;
}

.review-analytics-wrap > .mr-stat-container > .mr-copy-container > .mr-stat-title {
    text-align: left;
    white-space: nowrap;
    font-size: 14px;
}

.review-analytics-wrap > .mr-stat-container > .mr-copy-container > .mr-stat-title > span > svg  {
    width: 15px;
    height: 15px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
    margin-bottom: 0;
    margin-left: -2px;
    cursor: help;
}

.review-analytics-wrap > .mr-stat-container > .mr-copy-container > .mr-stat-title > .mr-title-tooltip {
    display: none;
    width: 180px;
    background-color: #fff;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    text-align: center;
    border-radius: 6px;
    border: 1px solid #e8ecee;
    position: absolute;
    margin: 5px 0 0 0;
    z-index: 100;
    top: 100%;
    left: 50%;
    margin-left: -80px;
}

.review-analytics-wrap > .mr-stat-container > .mr-copy-container > .mr-stat-title:hover > .mr-title-tooltip {
    display: block;
}

.review-analytics-wrap > .mr-stat-container > .mr-copy-container > .mr-stat-title > .mr-title-tooltip > .mr-tooltip-desc {
    color: var(--text-inactive);
    opacity: .75;
    font-size: 13px;
    text-align: center;
    display: block;
    padding: 8px;
    white-space: normal;
}

.review-analytics-wrap > .mr-stat-container > .mr-copy-container > .mr-stat-title > .mr-title-tooltip > .mr-tooltip-extra-row > .mr-tooltip-desc {
    padding: 8px 8px 8px 0;
    font-size: 13px;
}

.review-side-btn {
    border-radius: 5px !important;
    border: 1px solid #E8ECED !important;
    color: var(--text-inactive) !important;
    background-color: #fff;
    box-shadow: 0px 0px 6px -2px rgba(185, 185, 185, 0.25);
    font-size: 15px;
    height: 33px;
    min-height: 30px !important;
    margin: 15px -5px 0 -5px !important;
    width: calc(100% + 10px);
}

.review-side-btn:hover {
    background-color: #fcfcfc;
    box-shadow: 0px 0px 6px -2px rgba(185, 185, 185, 0.35);
}

/* END OF REVIEWS RIGHT COL */

/* END OF REVIEW FEED */

/* REQUEST REVIEWS */

.request-reviews-container {
    display: flex;
    margin: 0 auto;
    max-width: 1000px;
    flex-direction: column;
}

.request-reviews-divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
    padding: 0 3px;
}

.request-reviews-divider > .divider-line {
    border-top: 1px solid #C7C7C7;
    flex: 1 1 auto;
    margin-top: 8px;
    min-width: 1px;
    padding-top: 8px;
}

.request-reviews-divider > .divider-text {
    color: var(--text-normal);
    font-size: 14px;
    margin: 0 8px;
    opacity: .5;
}

.request-reviews-card-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.request-reviews-checklist-container {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    width: 100%;
}

.rr-checklist-header {
    display: flex;
    align-items: center;
    padding: 18px 25px;
}

.rr-checklist-header > svg {
    color: var(--text-normal);
    stroke: var(--text-normal);
    width: 70px;
    height: 70px;
}

.rr-checklist-header-info {
    width: 100%;
    margin-left: 25px;
}

.rr-checklist-title {
    font-size: 25px;
    margin: 0;
    font-weight: 600;
}

.rr-checklist-progress-bar {
    width: 100%;
    border-radius: 10px;
    height: 10px;
    background-color: var(--background-color);
    margin: 13px 0;
}

.rr-checklist-progress-fill {
    border-radius: 10px;
    height: 10px;
    background-color: var(--racecheck-green);
}

.rr-checklist-stats {
    display: flex;
}

.rr-checklist-stats > p {
    margin: 0;
    color: var(--text-inactive)
}

.rr-checklist-item {
    border-top: 1px solid #DBDBDB;
    padding: 18px 25px;
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.rr-checklist-item-complete > .checklist-check {
    border: 2px solid var(--racecheck-green) !important;
    background-color: var(--racecheck-green);
    position: relative;
}

.rr-checklist-item-complete > .checklist-check::after {
    top: 3px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    content: "";
    position: absolute;
    left: 7px;
}

.rr-checklist-item-complete > .rr-checklist-item-body > .checklist-item-header > .checklist-item-header-copy > p:first-child {
    text-decoration: line-through;
}

.rr-checklist-item-complete > .rr-checklist-item-body > .checklist-item-header > .checklist-item-chevron {
    display: none;
}

.rr-checklist-item-complete > .rr-checklist-item-body > .checklist-item-header {
    opacity: .6;
}

.checklist-check {
    min-width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid #DBDBDB;
    margin-right: 25px;
    margin-top: 10px;
}

.rr-checklist-item-body {
    width: 100%;
}

.checklist-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.checklist-item-header-copy {
    width: 100%;
}

.checklist-item-header-copy > p:first-child {
    margin: 0 0 5px 0;
    font-size: 17px;
    font-weight: 500;
}

.checklist-item-header-copy > p:last-child {
    color: var(--text-inactive);
    margin: 0;
    opacity: .8;
}

.checklist-item-chevron > svg {
    width: 25px;
    height: 25px;
    stroke: var(--racecheck-green);
    cursor: pointer;
}

.checklist-item-extra-info {
    /* padding-right: 25px; */
}

.checklist-item-extra-info > p {
    margin: 20px 0 0 0;
}

.checklist-item-link {
    color: var(--racecheck-green);
    font-weight: 500;
    padding: 0;
    text-decoration: none;
}

.checklist-item-link:hover {
    text-decoration: underline !important;
}

.checklist-item-promo {
    background-color: #eff8f2e7;
    border: 2px solid var(--racecheck-green);
    border-radius: 5px;
    padding: 15px;
    margin-top: 25px;
}

.checklist-item-promo-copy > p {
    margin: 0;
    text-align: center;
    padding: 0 30px;
}

.checklist-item-promo-copy > p:first-child {
    margin: 0 0 7px 0;
    font-weight: 600;
    color: var(--racecheck-green-dark);
    font-size: 20px;
}

.checklist-item-promo > .btn {
    margin: 20px auto 0 auto;
    width: 40%;
    display: block;
}

.qr-code-loader, .share-link-loader {
    height: 232px !important;
}

.qr-code-container, .share-link-container {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    /* overflow: hidden; */
    margin: 0 0 20px 0;
    padding: 18px 25px;
    width: 100%;
    min-width: 490px;
    max-width: 48%;
}

.qr-code-header, .share-link-header {
    margin-bottom: 20px;
}

.qr-code-title, .share-link-title {
    font-size: 25px;
    font-weight: 600;
    color: var(--text-normal);
    margin: 0 15px 10px 0;
}

.qr-code-intro, .share-link-intro {
    margin: 0;
    font-size: 16px;
    color: var(--text-inactive);
}

.share-link-code-container {
    margin: 20px 0;
    position: relative;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    background-color: #141414;
    border-radius: 5px;
}

.share-link-code-content {
    border-radius: 5px;
    padding: 12px;
    box-sizing: border-box;
    width: 80%;
}

.share-link-code-pre {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    color: #ededed;
    font-family: monospace;
    font-weight: 500;
    width: 100%;
}

.share-link-copy-code {
    order: 1;
    width: 20%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    padding: 2px 8px 2px 5px;
    top: 0;
    right: 0;
    background-color: rgba(95, 95, 95, 0.466);
    border-radius: 0 5px 0 5px;
}

.share-link-copy-code:hover {
    cursor: pointer;
    background-color: rgba(117, 117, 117, 0.466);
}

.share-link-copy-code > p {
    color: #fff;
    opacity: .9;
    margin: 0 8px 0 5px;
    font-weight: 500;
    font-size: 14px;
}

.share-link-copy-code > svg {
    width: 18px;
    height: 18px;
    stroke: #fff;
    opacity: .9;
}

.share-link-tooltip {
    position: absolute;
    max-width: 100%;
    margin-top: 45px;
    z-index: 3000;
    background-color: var(--background-color);
    border-radius: 5px;
    padding: 4px 10px;
}

.share-link-tooltip:after {
    content: " ";
    position: absolute;
    bottom: 100%;  
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--background-color) transparent;
}

.share-link-tooltip > p {
    box-sizing: border-box;
    font-family: monospace;
    margin: 5px;
    margin-left: 0;
    padding: 0;
    font-size: 13px;
    min-width: 100%;
    word-wrap: break-word;
}

.qr-code-event-info {
    margin-bottom: 20px;
}

.qr-code-preview {
    display: flex;
    flex-direction: row;
}

.qr-code-img {
    width: 140px !important;
    height: 140px !important;
    margin: -8px 0 0 -8px;
}

.qr-code-downloads {
    margin-left: 15px;
    padding: 8px 8px 0 0;
    width: 100%;
}

.qr-code-list-container {
    display: flex;
    flex-wrap: wrap;
}

.qr-download-copy {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.qr-download-copy > svg {
    width: 25px;
    height: 25px;
    margin: 0 5px 0 0;
    fill: var(--text-inactive);
    stroke: var(--text-inactive);
}

.qr-download-copy > p {
    margin: 2px 0 0 0;
}

.qr-download-file-type {
    text-transform: uppercase;
    opacity: .7;
    font-size: 12px;
}

.qr-code-downloads > p {
    font-size: 12px;
    color: var(--text-inactive);
    opacity: .5;
    text-align: center;
}

.request-reviews-placeholder1, .request-reviews-placeholder2 {
    width: 100%;
    border: 2px dashed #C7C7C7;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0 0 20px 20px;
    opacity: .5;
}

.request-reviews-placeholder1 > h3 {
    margin: 0
}

.request-reviews-placeholder1 > p {
    width: 65%;
    text-align: center;
}

.request-reviews-placeholder2 {
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#viewport {
    display: none;
}

.qr-codes-hr {
    height: 1px;
    border: none;
    background: #DBDBDB;
}

.qr-code-container > .no-data-container {
    margin-top: 0 !important;
    border: 2px solid #DBDBDB;
    border-radius: 5px;
    background-color: #f7f7f7;
    margin-bottom: 7px;
}

.qr-code-container > .no-data-container > .no-data-header {
    font-size: 18px;
    margin: 10px 0 5px 0 !important;
}

.qr-code-container > .no-data-container > .no-data-copy {
    font-size: 15px;
    padding: 0 25px;
}

.qr-code-container > .no-data-container > a > .btn {
    margin-top: 10px !important;
    margin-bottom: 15px !important;
}

.request-reviews-wrapped-row {
    width: 100%;
    flex-basis: 100%;
}

.request-reviews-wrapped-row > .request-reviews-placeholder1 {
    margin: 0 !important;
}

.request-reviews-wrapped-row > .request-reviews-placeholder1 > h3 {
    margin-top: 15px;
}

.request-reviews-wrapped-row > .request-reviews-placeholder1 > p {
    margin: 10px 0 20px 0 !important;
}

/* END OF REQUEST REVIEWS */

/* REVIEWS REPLIES MODAL */

.reply-review-modal-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.reply-review-modal-content > h1 {
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 15px 0;
}

.reply-review-modal-content > p {
    width: 90%;
}

.reply-review-modal-content > p > span {
    font-weight: 500;
}

.reply-perks-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0;
}

.reply-perks-container > img {
    width: 50%;
}

.reply-perks-list {
    width: 50%;
    text-align: left;
}

.reply-perks-list > li {
    margin: 0 0 12px 0 !important;
    display: flex;
    align-items: flex-start;
    font-weight: 500;
}

.reply-perks-list > li > svg {
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    margin-right: 3px;
    display: block;
    padding-top: 2px;
}

.reply-review-modal-content > .btn {
    padding: 0 50px !important;
}

/* END OF REVIEW REPLIES MODAL */

/* SHARE REVIEW MODAL */

.share-review-btns-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 18px;
    border-top: 1px solid #E8ECED;
}

.sr-icon-container {
    position: relative;
}

.sr-popup {
    position: absolute;
    top: -38px;
    z-index: 3000;
    background-color: var(--background-color);
    border-radius: 5px;
    padding: 4px 10px;
    white-space: nowrap;
    text-align: center;
}

.sr-popup:after {
    content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 5px solid transparent;
	border-top-color: var(--background-color);
	border-bottom: 0;
	margin-left: -5px;
	margin-bottom: -5px;
}

.sr-popup > p {
    margin: 0;
    font-size: 14px;
}

.sr-popup-copy {
    left: -1px !important;
}

.sr-popup-copied {
    left: -8px !important;
}

.sr-popup-email {
    left: -19px !important;
}

.sr-popup-twitter {
    left: -22px !important;
}

.sr-popup-facebook {
    left: -31px !important;
}

.sr-popup-whatsapp {
    left: -36px !important;
}

.sr-social-icon {
    margin: 0 15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
}

.sr-social-icon > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px !important;
    height: 50px !important;
}

.sr-social-icon > svg {
    width: 40px !important;
    height: 40px !important;
}

.sr-copy {
    background: #d8dcdf;
    margin-left: 13px;
}

.sr-copy:hover {
    background: #d0d7d8;
}

.sr-copy > svg {
    width: 30px !important;
    height: 30px !important;
    stroke: var(--text-normal);
}

.sr-email {
    background: #ec5034;
}

.sr-email:hover {
    background: #e04b30;
}

.sr-email > button > svg {
    width: 30px !important;
    height: 30px !important;
    stroke: #fff;
}

.sr-twitter {
    background: #1DA1F2;
}

.sr-twitter:hover {
    background: rgb(26, 153, 231);
}

.sr-twitter > button > svg {
    width: 26px !important;
    height: 26px !important;
}

.sr-facebook {
    background: #1877F2;
}

.sr-facebook:hover {
    background: rgb(21, 105, 216);
}

.sr-facebook > button > svg {
    width: 29px !important;
    height: 29px !important;
    margin-top: -5px !important;
    margin-left: -2px !important;
}

.sr-facebook:hover > svg {
    fill: rgb(21, 105, 216);
}

.sr-whatsapp {
    background: #25D366;
}

.sr-whatsapp:hover {
    background: #23cc61;
}

.sr-whatsapp > button > svg {
    width: 25px !important;
    height: 25px !important;
    margin-top: -1px;
}

/* END OF SHARE REVIEW MODAL */

/* IMAGE GALLERY MODAL */

.review-photo-gallery {
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;
    border-top: 1px solid #e8ecee;
    justify-content: center;
    margin-bottom: -15px;
}

.review-photo-main {
    width:100%;
    height: 500px;
    background-color: #fff;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review-photo-focus {
    max-width: 100%;
    max-height: 500px;
    display: block;
    width: auto;
    height: auto;
    margin: 0 !important;
}

.review-photo-active {
    box-shadow: 0 0 0 2px var(--racecheck-green);
}

/* END OF IMAGE GALLERY MODAL */

/* SENTIMENT */

.sentiment-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;
    position: relative;
}

.sentiment-placeholder-img {
    filter: opacity(0.25);
    margin: 0 !important;
}

.sentiment-copy {
    position: absolute;
    width: 55%;
    padding: 15px 20px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
}

.sentiment-copy > h3 {
    margin: 0 0 10px 0;
    font-size: 30px;
}

.sentiment-copy > p {
    width: 70%;
    margin: 0 0 10px 0;
}

.sentiment-disclaimer {
    font-size: 12px;
    opacity: .5;
    color: var(--text-inactive);
    margin: 0 !important;
}

.sentiment-copy > .no-data-container > .no-text-decoration > .btn {
    margin: 10px 0 !important;
}

.sentiment-copy > .no-data-container > .no-data-copy {
    padding: 0 40px !important;
}

/* END OF SENTIMENT */

/* RESPONSIVE */

@media screen and (max-width: 900px) {

    /* REVIEW LIST */

    .review-feed-cols {
        display: block;
    }

    .review-feed-right {
        display: none;
    }

    .review-card {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        padding: 0;
    }

    .review-card-main {
        padding: 0 5px;
    }

    .review-card-left {
        display: none;
    }

    .review-card-right {
        padding: 10px;
    }

    .review-card-review-details {
        flex-wrap: wrap;
        position: relative;
    }

    .review-card-review-details > p {
        display: flex;
        align-items: center;
    }

    .review-card-review-details > .review-card-rating-container > .rating-stars > svg:not(:first-of-type) {
        display: none;
    }

    .review-card-review-details > .mobile-bullet {
        display: flex;
    }

    .review-card-mobile-profile {
        display: flex;
        align-items: center;
    }

    .review-card-mobile-profile > .review-card-author-avatar {
        width: 22px;
        height: 22px;
        margin-right: 5px;
    }

    .review-card-mobile-profile > .review-card-author-avatar > .avatar-laurel-frame-left {
        left: -2px;
    }

    .review-card-mobile-profile > .review-card-author-avatar > .avatar-laurel-frame-right {
        right: -2px;
    }

    .review-card-mobile-profile > .review-card-author-avatar > .review-card-profile-img {
        width: 22px;
        height: 22px;
    }

    .review-card-mobile-profile > .review-card-author {
        margin-top: 0;
        margin-bottom: 0;
        color: var(--text-inactive);
        opacity: .8;
        font-size: 15px;
        font-weight: 400;
    }

    .review-card-rating-container {
        position: initial;
    }

    .review-card-rating-tooltip {
        left: -10px;
        width: calc(100% + 20px);
    }

    .review-card-rating-tooltip::after {
        left: 109px;
    }

    .review-card-reply-inline {
        margin: 18px 0 18px 0;
    }

    .review-card-review-buttons {
        justify-content: space-between;
    }

    .review-card-review-buttons > .review-card-btn:not(:last-child) {
        margin-right: 0px;
    }

    .review-card-btn-mobile {
        display: none;
    }

    .review-card-reply-container {
        padding: 10px 10px 10px 10px;
        margin-top: 3px;
    }

    .sq-header > .sq-h-help, .sq-top-banner > .sq-h-help {
        display: none;
    }

    .sq-header > .sq-h-question {
        align-items: start;
    }

    .sq-header > .sq-h-question > svg {
        margin: 2px 3px 0 0;
    }

    /* END OF REVIEW LIST */

    /* REQUEST REVIEWS */

    .request-reviews-card-row {
        display: block;
    }

    .qr-code-container, .share-link-container {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        padding: 18px 25px 10px 25px;
    }

    .qr-code-preview {
        display: block;
    }

    .qr-code-img {
        margin: -10px auto 10px auto;
    }

    .qr-code-downloads {
        margin-left: 0;
        padding: 0;
    }

    .qr-code-event-info > .horizontal-select-group > .horizontal-select-label {
        display: none;
    }

    .qr-code-event-info > .horizontal-select-group > .rounded-dropdown {
        width: 100%;
        margin: 0;
    }

    /* END OF REQUEST REVIEWS */

    /* SENTIMENT */

    .sentiment-container {
        max-width: 100%;
        height: 100%;
    }

    .sentiment-placeholder-img {
        width: 100%;
        transform: scale(1);
    }

    .sentiment-copy {
        top: 80px;
        width: 100%;
    }

    .sentiment-copy > p {
        width: 90%;
    }

    /* END OF SENTIMENT */

    /* REVIEW REPLIES UPGRADE BANNER */

    .review-no-replies-banner {
        width: 100%;
    }

    .review-no-replies-copy {
        flex-direction: column;
    }

    .review-no-replies-copy-info > svg {
        display: none;
    }

    .review-no-replies-copy > .btn {
        width: 100%;
        margin-top: 10px;
    }

    /* END OF REVIEW REPLIES UPGRADE BANNER */

    /* REVIEW PAYWALL */

    .review-card-reply-footer {
        display: block;
    }

    .rc-review-count-container {
        margin-bottom: 12px;
    }

    /* END OF REVIEW PAYWALL */

    /* SMART Q */

    .sq-r-top {
        flex-direction: column;
    }

    .sq-r-h-title {
        width: 100%;
        margin: 10px 0;
    }

    .sq-r-h-title > h1 {
        width: 100%;
        text-align: center;
    }

    .sq-r-filters {
        flex-direction: column;
    }

    .sq-r-filters > select {
        width: 100%;
        margin: 0 0 10px 0 !important;
    }

    /* END OF SMART Q */

}

@media screen and (max-width: 650px) {
    .review-card-rating-tooltip > .rcrt-criteria-container {
        grid-template-columns: auto auto auto;
    }
}

@media screen and (max-width: 490px) {
    .review-card-rating-tooltip > .rcrt-criteria-container {
        grid-template-columns: auto auto;
    }
}

/* END OF RESPONSIVE */