.react-calendar {
    border: none;
}

.react-calendar__navigation {
    margin: 0;
}

.react-calendar__navigation button {
    border-radius: 5px;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: var(--background-color);
}

.react-calendar button {
    font-size: 13px;
}

.react-calendar__tile {
    padding: 0.6em 0.4em;
    border-radius: 8px;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: var(--background-color);
}

.react-calendar__tile--active {
    background-color: var(--racecheck-green) !important;
    font-weight: 600 !important;
    color: #fff !important;
}

.react-calendar__tile:active {
    color: inherit;
}

.react-calendar__tile:disabled {
    background: none;
    color: rgb(212,212,212) !important;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background-color: var(--racecheck-green-dark);
}

.react-calendar__tile--now {
    background: none;
    color: inherit;
    font-weight: 400;
}

.react-calendar__month-view__days__day--weekend {
    color: inherit;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #696969
}

.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    padding: 1em 0.5em;
}

.react-calendar__navigation button[disabled] {
    background: none;
    color: rgb(212,212,212) !important;
}