:root {
  --background-color: #E8ECED;
  --racecheck-green: #4ABF6C;
  --racecheck-green-dark: rgb(68, 184, 101);
  --text-active: #141414;
  --text-inactive: #585858;
  --text-inactive-2: #707070;
  --text-normal: #3E3E3E;
  --text-error: #db4e4e;
  --racecheck-gold: #FFCC00;
  --racecheck-silver: #CCCCC2;
  --racecheck-silver-text: #c0c0b4;
  --racecheck-bronze: #965A38;
  --matterhorn: #505050;
  --forest-green: #1a7c1c;
  --very-light-grey: #DBDBDB;
  --dim-grey: #636363;
  --dim-grey-2: #838383;
  --pastel-green: #81D782;
  --persian-red: #D24040;
  --misty-rose: #FFE3E3;
  --forest-green: #229E23;
  --honeydew: #EBFFF1;

}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  height: 100%;
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--text-normal);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ---------------- */

a {
  cursor: pointer;
}

p {
  font-size: 16px;
}

.btn {
  cursor: pointer;
  transition: background-color .15s,color .15s ease-in-out;
  text-align: center;
  border-radius: 3px;
}

.primary-btn {
  border-radius: 50px !important;
  cursor: pointer;
  border: 1px solid var(--racecheck-green);
  background-color: var(--racecheck-green);
  color: #fff;
  font-weight: 500;
  padding: 0 15px !important;
  height: 35px;
  min-height: 35px !important;
  line-height: 35px;
}

.primary-btn:hover {
  background-color: var(--racecheck-green-dark);
}

.disabled-primary-btn {
  cursor: not-allowed !important;
  border: 1px solid #e8ecee;
  color: rgb(165, 165, 165);
  background: none;
}

.disabled-primary-btn:hover {
  background: none;
}

.secondary-btn {
  cursor: pointer;
  border: 1px solid #9ca6af;
  color: var(--text-inactive);
  font-weight: 500;
  padding: 0 15px !important;
  height: 35px;
  min-height: 35px !important;
  line-height: 35px;
  background: none;
}

.secondary-btn:hover {
  background-color: #f8f8f8;
}

.secondary-btn-rounded {
  border-radius: 50px !important;
}

.disabled-secondary-btn {
  cursor: not-allowed !important;
  border: 1px solid #e8ecee;
  color: rgb(165, 165, 165);
}

.secondary-btn-main-cta {
  border: 1px solid var(--racecheck-green);
  background-color: var(--racecheck-green);
  color: #fff;
}

.secondary-btn-main-cta:hover {
  background-color: var(--racecheck-green-dark);
}

.disabled-secondary-btn:hover {
  background-color: #fff;
}

form {
  text-align: left;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 17px;
  color: var(--text-inactive);
}

.input, select {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #e0e6e8;
  margin: 0;
  padding: 0 0 0 14px;
  font-size: inherit;
  box-sizing: border-box;
  -moz-appearance: none; -webkit-appearance: none; appearance: none
}

select {
  cursor: pointer;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #fff, #fff);
  background-position:
    calc(100% - 20px) 1em,
    calc(100% - 15px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

.input:focus {
  border-color: var(--racecheck-green);
  outline: 0;
}

.input-group {
  margin-bottom: 25px;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bullet {
  font-size: 15px;
  color: var(--text-inactive);
  opacity: .5;
  margin: 0 8px !important;
}

.bullet-small {
  font-size: 15px;
  color: var(--text-inactive);
  opacity: .5;
  margin: 0 2px !important;
}

.required-field {
  color: var(--text-error);
  font-size: 12px;
  font-weight: bold;
}

.autocomplete-form {
  position: relative;
}

.autocomplete-form > input:focus {
  border-color: #e0e6e8;
}

.autocomplete-results {
  width: 100%;
  max-height: 170px;
  position: absolute;
  margin-top: -2px;
  background-color: rgb(248, 248, 248);
  overflow: scroll;
  overflow-x: hidden;
  z-index:100;
  border-left: 2px solid #e0e6e8;
  border-right: 2px solid #e0e6e8;
  border-bottom: 2px solid #e0e6e8;
  border-top: 1px solid #e0e6e8;
  border-radius: 0 0 3px 3px;
}

.modal-autocomplete-results {
  max-height: 140px;
  margin-top: -12px;
  border-left: 1px solid #e0e6e8;
  border-right: 1px solid #e0e6e8;
  border-bottom: 1px solid #e0e6e8;
  border-top: 1px solid #e0e6e8;
  overflow-x: hidden;
}

.modal-autocomplete-results > ul > .autocomplete-item {
  cursor: pointer;
}

.autocomplete-item {
  padding: 6px 10px;
}

.autocomplete-item:hover {
  background-color: rgb(241, 241, 241);
}

.small-form-error {
  color: var(--text-error);
  font-weight: 500;
  padding: 0;
  width: 100%;
  margin: 0 0 10px 0;
  font-size: 14.5px;
}

.text-right {
  text-align: right;
}

.no-text-decoration {
  text-decoration: none;
}

.error-text-red {
  font-size: smaller;
  color: var(--text-error)
}

.error-text-margin-fix{
  margin-top: -20px !important;
  margin-bottom: 10px !important;
}

kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0,0,0,.2),inset 0 2px 0 0 hsla(0,0%,100%,.7);
  color: #333;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}

/* SECTION DROPDOWNS */

.horizontal-dropdowns {
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
}

.horizontal-select-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.horiztonal-select-label {
  margin: 0 10px 0 0;
  white-space: nowrap;
}

.rounded-dropdown {
  height: 40px;
  width: initial;
  border-radius: 50px;
  background-color: #fff;
  border: 2px solid #e0e6e8;
  margin: 0 25px 0 0;
  padding: 0 35px 0 14px;
  font-size: inherit;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.rounded-dropdown:focus {
  border: 2px solid var(--racecheck-green);
  outline: none;
}

.event-name-rounded-dropdown {
  width: 100% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* END OF SECTION DROPDOWNS */

/* RESPONSIVE */

@media screen and (max-width: 900px) {

  .horizontal-dropdowns {
    flex-direction: column;
  }

  .horizontal-select-group:not(:last-child) {
    margin-bottom: 10px;
  }

}

/* END OF RESPONSIVE*/


/* MODAL  */

/* .modal-container {
  background-color: #fff;
  padding: 8px 18px 17px 20px;
} */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.315);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .modal-content {
  padding-top: 12.75px;
  padding-bottom: 14px;
} */

.trial-banner {
  width: 100%;
  background-color: var(--honeydew);
  border: 1px solid var(--racecheck-green);
  border-radius: 5px;
  padding: 9px 8px 6px 10px;  
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trial-banner > p {
  margin: 0;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  color: var(--racecheck-green);
}

.trial-banner__title {
  display: flex;
  align-items: center;
}

.trial-banner__icon {
  display: flex;
  width: 15px;
  height: 15px;
  margin-right: 7px;
}

.trial-banner__icon > svg {
    width: 15px;
    height: 15px;
    stroke: var(--racecheck-green);
    fill: var(--racecheck-green);
}

.trial-banner__get-started {
  background-color: var(--racecheck-green);
  color: white;
  min-width: 147px;
  padding: 3px 10px 5px 25px;
  border-radius: 4px;
  font-family: Lato;
  font-weight: 600;
  font-size: 12px;
  border: none;
  outline: none;
  cursor: pointer;
}