/* END OF EVENT LIST TOGGLE */

.event-cards-container {
    display: flex;
    flex-direction: row;
    max-width: 1160px;
    flex-wrap: wrap;
    margin: 0 auto;
    overflow: initial !important;
}

.events-feed-header {
    display: flex;
    justify-content: space-between;
    max-width: 1160px;
    margin: auto;
    margin-bottom: 15px;
    align-items: center;
    padding: 0 10px;
}

.efh-add-btn {
    width: 25%;
}

.efh-add-btn > button {
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-normal);
    font-size: 16px;
    font-weight: 500;
    float: right;
    display: flex;
    align-items: center;
    padding: 4px 10px;
    border-radius: 7px;
    transition: all ease-in-out .15s;
}

.efh-add-btn > button:hover {
    color: var(--text-active);
    background: rgb(231, 231, 231);
}

.efh-add-btn > button > svg {
    width: 22px;
    height: 22px;
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    margin-right: 5px;
}

.efh-search {
    position: relative;
    width: 50%;
    display: flex;
    align-items: center;
}

.efh-search-input {
    width: 65%;
    margin: 0 auto;
    position: relative;
    justify-self: center;
}

.efh-search-input > svg {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 8px;
    left: 10px;
}

.efh-search-input > input {
    border-radius: 20px;
    outline: none;
    border: 1px solid var(--racecheck-silver);
    padding: 5px 5px 5px 33px;
    font-size: 16px;
    width: 100%;
}

.efh-search-input > input:focus {
    border-color: var(--racecheck-green);
}

.efh-search .events-feed__search-autocomplete {
    z-index: 10;
    position: absolute;
    top: 27px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.events-feed__search-autocomplete > button {
    background: none;
    outline: none;
    border: none;
    text-align: left;
    padding: 10px;
    cursor: pointer;
}

.events-feed__search-autocomplete > button:hover {
    background-color: rgb(201, 201, 201);
}

.hidden {
    display: none !important;
}

.efh-status-btns {
    display: flex;
    width: 25%;
    margin-bottom: -7px;
}

.efh-status-btns > button {
    font-size: 16px;
    color: var(--text-inactive-2);
    margin: 0;
    padding: 0 0 7px 0;
    background: none;
    outline: none;
    border: none;
    text-transform: capitalize;
    font-weight: 500;
}

.efh-status-btns > button:not(:last-child) {
    margin-right: 15px;
}

.efh-status-btns > input:last-child {
    margin: 0;
}

.efh-status-btns > button.active  {
    box-shadow: inset 0 -3px var(--racecheck-green);
    color: var(--text-active);
}

.efh-status-btns > button.active:hover {
    box-shadow: inset 0 -3px var(--racecheck-green);
}

.efh-status-btns > button:hover {
    box-shadow: inset 0 -3px #cecece;
}

.efh-status-btns > button.disabled  {
    opacity: .5;
    cursor: not-allowed;
}

.efh-status-btns > button.disabled:hover  {
    box-shadow: none;
}

.no-data-add-event-btn {
    padding-left: 60px !important;
    padding-right: 60px !important;
    margin-top: 25px;
}

/* BUTTON EVENT CARD */

.button-event-card {
    max-width: 270px;
    min-width: 270px;
    height: 352px;
    border: 2px dashed #C7C7C7;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    margin: 10px;
}

.button-event-card:hover {
    background-color: #eff2f3;
}

.button-event-card > p {
    color: var(--text-inactive);
    padding: 0 70px;
    text-align: center;
}

.button-event-card > svg {
    width: 70px;
    height: 70px;
    fill: var(--text-inactive);
    stroke: var(--text-inactive);
}

/* END OF BUTTON EVENT CARD */

/* EVENT CARD */

.event-card-container {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
}

.event-card {
    max-width: 270px;
    min-width: 270px;
    height: 290px;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 10px 10px 0 10px;
    z-index: 2;
}

.event-header-img {
    width: 100%;
    height: 150px;
    overflow: hidden;
}

.event-header-img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.event-header-img.free > img, .event-header-img-pending > img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.event-header-wrapper {
    position: relative;
}

.event-header-tasks-notification {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 23px;
    height: 23px;
    background-color: rgb(255, 78, 78);
    box-shadow: 0px 1px 2px rgb(207, 95, 95);
    border-radius: 15px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 0 9px;
    transition: width .15s ease-in-out;
    cursor: pointer;
}

.event-header-tasks-notification > p {
    color: #fff;
    font-weight: 600;
    white-space: nowrap;
    text-align: center;
    width: 100%;
    margin-left: -3px;
    font-size: 15px;
}

.multiple-tasks > p {
    margin-left: -4px;
}

.event-header-tasks-notification > p > span {
    /* display: none; */
    color: rgb(255, 78, 78);
}

.event-header-tasks-notification:hover {
    width: 155px;
}

.multiple-tasks:hover {
    width: 165px;
}

.event-header-tasks-notification:hover > p {
    margin-left: 0
}

.event-header-tasks-notification:hover > p > span {
    display: inline;
    color: #fff;
}

.event-label {
    position: absolute;
    z-index: 10;
    bottom: -12px;
    font-size: 11px;
    font-weight: 700;
    padding: 4px 8px 4px 7px;
    border-radius: 0 5px 0 0;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
}

.event-label-icon {
    height: 12px;
    color: #fff;
    fill: #fff;
    margin: 0 3px 0 0;
}

.event-label.label-free {
    background-color: var(--very-light-grey);
    color: var(--dim-grey);
}

.event-label.label-premium {
    color: #14911f;
    background-color: #9ae0a8;
}

.event-label-line {
    width: 100%;
    height: 3px;    
    position: absolute;
    /* bottom: -2.75px; */
}

.event-label-line.free {
    background-color: var(--very-light-grey);
}

.event-label-line.premium {
    background-color: #9ae0a8;
}

.event-info {
    padding: 15px 15px 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 140px;
}

.event-title {
    margin: 0 0 10px 0;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
    align-items: center;
}

.cursor-default {
  pointer-events: none !important;
  cursor: default !important;
}

.event-title > span {
    color: var(--racecheck-green);
    display: inline-block;
}

.event-title > span > svg {
    height: 11px;
    margin-bottom: -1px;
    margin-left: 0px;
}

.event-title:hover > span > svg {
    margin-left: 2px;
    transition: all ease-in-out .2s;
}

.event-date, .event-location, .event-distances {
    font-size: 14px;
    margin: 0 0 5px 0;
}

.event-date-container {
    display: flex;
    width: fit-content;
    cursor: pointer;
}

.edit-event-date {
    width: 10px;
    height: 10px;
    margin-left: 5px;
    margin-bottom: 1px;
    opacity: .25;
    fill: var(--text-inactive);
}

.event-date-container:hover > div > .edit-event-date {
    opacity: 1;
    fill: var(--racecheck-green);
}

.event-date-btn {
    background: none;
    border: none;
    outline: none;
    color: var(--racecheck-green);
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 7px;
    padding: 0;
    cursor: pointer;
    letter-spacing: -.5px;
}   

.event-date-btn:hover {
    text-decoration: underline;
}

.event-distance-icon {
    margin: 0 5px 0 0;
    filter: invert();
    opacity: .7;
    display: inline-block;
    height: 13px;
}

.event-distances {
    margin-bottom: 10px;
    display: inline-block;
}

.event-card-stars {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.event-card-stars .event-card-total {
    font-size: 12px;
    color: var(--text-inactive);
    margin-bottom: -2px;
    opacity: .6;
}

.event-card-start-collecting {
    display: block;
    text-align: center;
    cursor: pointer;
    width: 256px;
    border-radius: 4px;
    background-color: var(--racecheck-green);
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;
    margin-left: -8px;
    margin-bottom: -3px;
}

.event-card-start-collecting:hover {
    background-color: var(--racecheck-green-dark);
}

.event-card-btn-container {
    margin: -5px 10px 10px 10px;
    padding: 10px 5px 5px 5px;
    background-color: #F2F2F2;
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    height: 45px;
    width: 270px
}

.event-card-btns-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}

.event-card-share-list {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: space-around;
}

.ecsl-back {
    border: none;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-radius: 50%;
    cursor: pointer;
    background: none;
}

.ecsl-back:hover > svg {
    fill: var(--text-normal);
    stroke: var(--text-normal);
}

.ecsl-back > svg {
    width: 25px;
    height: 25px;
    margin: 0;
    fill: var(--text-inactive);
    stroke: var(--text-inactive);
}

.ecsl-icon-container {
    position: relative;
}

.ecsl-popup {
    position: absolute;
    top: -38px;
    z-index: 3000;
    background-color: var(--background-color);
    border-radius: 5px;
    padding: 4px 10px;
    white-space: nowrap;
}

.ecsl-popup:after {
    content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 5px solid transparent;
	border-top-color: var(--background-color);
	border-bottom: 0;
	margin-left: -5px;
	margin-bottom: -5px;
}

.ecsl-popup > p {
    margin: 0;
    font-size: 14px;
}

.ecsl-popup-copy {
    left: -13px;
}

.ecsl-popup-copied {
    left: -20px;
}

.ecsl-popup-email {
    left: -37px;
}

.ecsl-popup-twitter {
    left: -40px;
}

.ecsl-popup-facebook {
    left: -48px;
}

.ecsl-popup-whatsapp {
    left: -53px;
}

.escl-social-icon {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    width: 28px;
    height: 28px;
}

.escl-social-icon > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
}

.escl-social-icon > svg {
    width: 25px;
    height: 25px;
}

.escl-copy {
    background: #d8dcdf;
    margin-left: 0;
}

.escl-copy:hover {
    background: #d0d7d8;
}

.escl-copy > svg {
    width: 18px;
    height: 18px;
    stroke: var(--text-normal);
}

.escl-email {
    background: #ec5034;
}

.escl-email:hover {
    background: #e04b30;
}

.escl-email > button > svg {
    width: 18px;
    height: 18px;
    stroke: #fff;
}

.escl-twitter {
    background: #1DA1F2;
}

.escl-twitter:hover {
    background: rgb(26, 153, 231);
}

.escl-twitter > button > svg {
    width: 15px;
    height: 15px;
}

.escl-facebook {
    background: #1877F2;
}

.escl-facebook:hover {
    background: rgb(21, 105, 216);
}

.escl-facebook > button > svg {
    width: 19px;
    height: 19px;
    margin-top: -4px;
    margin-left: -2px;
}

.escl-facebook:hover > svg {
    fill: rgb(21, 105, 216);
}

.escl-whatsapp {
    background: #25D366;
}

.escl-whatsapp:hover {
    background: #23cc61;
}

.escl-whatsapp > button > svg {
    width: 15px;
    height: 15px;
    margin-top: -1px;
}

.event-card-btn {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--text-inactive);
    font-weight: 500;
    text-decoration: none;
}

.event-card-btn.disabled {
    color: var(--racecheck-silver);
    cursor: not-allowed;
}

.event-card-btn.disabled:hover {
    color: var(--racecheck-silver);
}

.event-card-btn:hover {
    color: var(--text-normal);
}

.event-card-btn-separator{
    border-right: 1px solid #e0e0e0;
    height: 100%;
    width: 1px;
}

.event-card-no-reviews {
    line-height: 18px;
    font-size: 13px;
    margin: 0;
    font-style: italic;
    color: rgb(148, 148, 148);
}

.event-card-pending {
    padding: 5px 8px;
    border: 1px dashed #e0e0e0;
    border-radius: 5px;
    margin: 0 0 5px 0;
    width: 256px;
    margin-left: -8px;
    margin-bottom: -3px;
}

.event-card-pending > p {
    margin: 0;
    font-size: 14px;
    color: var(--text-inactive);
    opacity: .8;
    text-align: center;
}

/* END OF EVENT CARD */

/* START OF ANALYTICS */

.analytics-container {
    display: flex;
    margin: 0 auto;
    max-width: 900px;
    flex-direction: column;
}

.analytics-header {
    display: flex;
    margin-bottom: 15px;
}

.analytics-tab-btn {
    font-size: 16px;
    color: var(--text-inactive-2);
    margin: 0;
    padding: 0 0 7px 0;
    font-weight: 500;
}

.analytics-tab-btn:not(:last-child) {
    margin-right: 15px;
}

.analytics-tab-btn.active {
    box-shadow: inset 0 -3px var(--racecheck-green);
    color: var(--text-active);
}

.analytics-tab-btn:hover {
    box-shadow: inset 0 -3px #cecece;
}

.analytics-tab-btn.active:hover {
    box-shadow: inset 0 -3px var(--racecheck-green);
}

.analytics-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    position: relative;
}

.analytics-report-header{ 
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.analytics-report-header > .horizontal-dropdowns {
    margin-bottom: 0;
}

.analytics-report-header > .horizontal-dropdowns > .horizontal-select-group > .rounded-dropdown {
    margin-right: 10px;
}

.analytics-report-header > .horizontal-dropdowns > .horizontal-select-group > .horizontal-select-label {
    margin: 0 5px 0 0;
}

.analytics-report-header > button > svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.analytics-report-row {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #DBDBDB;
}

.analytics-card {
    padding: 20px 25px;
    box-sizing: border-box;
}

.analytics-review-trends {
    width: 100%;
    max-height: 400px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.review-trends-chart {
    width: 80%;
    height: 100%;
    min-height: 220px;
    max-height: 220px;
    margin: 0 auto;
}

.analytics-review-trends .review-trends-title {
    margin-bottom: 20px;
}

.review-trends-description {
    text-align: center;
}

.analytics-comparative {
    width: 100%;
    border-bottom: 1px solid #DBDBDB;
}

.analytics-comparative-chart {
    width: 65%;
    margin: 0 auto;
}
.analytics-comparative-chart.mobile {
    display: none;
}

.analytics-report-row .analytics-card:last-child {
    margin-right: 0;
}

.analytics-card-title {
    font-weight: 500;
    color: var(--text-inactive);
    margin: 0 0 5px 0;
}

.analytics-card-intro {
    color: var(--text-inactive);
    opacity: .6;
    margin: 0 0 20px 0;
    font-size: 15px;
}

.analytics-top-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DBDBDB;
    border-top: 1px solid #DBDBDB;
    padding: 0 !important;
}

.top-bar-divider {
    height: 100%;
    border-left: 1px solid #DBDBDB;
    opacity: .7;
}

.top-bar-stat {
    margin: 5px 0;
    width: 100%;
    padding: 20px;
}

.top-bar-stat > .rating-stars > svg {
    height: 40px;
    width: 40px;
}

.top-bar-stat > .rating-stars > .rating-value {
    font-size: 38px;
    margin: 0 0 0 15px;
}

.top-bar-stat > .rating-stars > .rating-value > .rating-out-of {
    font-size: 20px;
    margin: 0 0 0 5px;
}

.top-bar-stat > .rating-stars > svg > {
    height: 40px;
    width: 40px;
}

.top-bar-stat-title {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-inactive);
    margin: 0 0 10px 0;
    text-align: center;
}

.tb-stat-title-mobile {
    display: none;
}

.tb-stat-title-desktop {
    display: block;
}

.top-bar-stat-info {
    font-size: 38px;
    font-weight: 700;
    margin: 0;
    text-align: center;
}

.analytics-tags {
    width: 100%;
    /*border-bottom: 1px solid #DBDBDB;*/
}

.analytics-breakdown {
    width: 50%;
    border-right: 1px solid #DBDBDB;
}

.tags-breakdown {
    width: 50%;
}

.difficulty-breakdown-chart {
    width: 100%;
    height: 200px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.analytics-placeholder, .no-data-placeholder {
    width: 100%;
    border: 2px dashed #C7C7C7;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    opacity: .5;
    margin: 20px;
}

.analytics-placeholder > h3 {
    margin-top: 15px;
    margin-bottom: 0;
}

.mw-analytics-row {
    width: 100%;
    display: flex;
}

.mw-analytics-row:not(:last-child) {
    border-bottom: 1px solid #e8ecee;
}

.mw-analytics-header-row {
    padding: 5px 20px;
    text-align: center;
}

.mw-analytics-header-row > p {
    width: 100%;
    margin: 0;
}

.mw-analytics-header-row > p > .mw-analytics-dates {
    margin-left: 5px;
    margin-top: 3px;
    display: block;
}

.horizontal-select-group > .checkbox-container {
    margin: 0;
}

.bookmarked-review-checkbox > .checkbox-container {
    margin: 0;
    background-color: #FFF;
    color: #000;
    font-weight: normal;
    font-size: 16px;
    padding: 0px 15px 0px 7px;
    box-shadow: 0px 0px 4px #e0e6e8;
    border: 2px solid #e0e6e8;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    align-items: center;
}

.mw-analytics-dates {
    opacity: .7;
    text-transform: none;
    font-size: smaller;
    margin: 0;
}

.mw-analytics-container {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    text-align: center;
    z-index: 99;
}

.mw-analytics-container:not(:last-child) {
    border-right: 1px solid #e8ecee;
}

.mw-analytics-title {
    font-weight: 500;
    color: rgb(146, 146, 146);
    margin: 0 0 10px 0;
    opacity: 1;
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
}

.mw-analytics-header-row > .mw-analytics-title {
    z-index: 0;
}

.mw-analytics-value {
    font-size: 35px;
    font-weight: 700;
    margin: 0 0 10px 0;
}

.mw-analytics-change {
    font-size: 15px;
    opacity: .8;
    color: var(--text-inactive);
    margin: 0;
    font-weight: 500;
}

.mw-analytics-change-value {
    font-weight: 600;
    font-size: 17px;
}

.mw-analytics-change-value-pos {
    color: rgb(0, 153, 51);
}

.mw-analytics-change-value-neg {
    color: rgb(212, 38, 15);
}

.mw-analytics-change-value-null {
    color: rgb(121, 121, 121);
}

.mw-analytics-coming-soon {
    padding: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mw-analytics-coming-soon-title {
    font-size: 17px;
    font-weight: 600;
    color: var(--text-inactive);
    margin: 0 0 8px 0;
    opacity: .8;
}

.mw-analytics-coming-soon-copy {
    color: var(--text-inactive);
    margin: 0;
    opacity: .7;
    width: 60%;
}

.mw-analytics-disclaimer {
    margin: -5px 0 0 10px;
    font-size: smaller;
    opacity: .7;
}

.analytics-placeholder > p {
    margin: 10px 0 20px!important;
}

.no-data-placeholder {
    height: 100%;
    border: none;
}

.no-data-placeholder .no-data-placeholder__title {
    margin-top: 0;
}

.rating-breakdown-chart {
    width: 100%;
    height: 200px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.rating-breakdown-legend-tag {
    margin: 0 0 5px 0;
    font-weight: 500;
}

.rating-breakdown-legend-color {
    border-radius: 5px;
    margin-right: 10px;
}

.rating-breakdown-legend-range {
    font-weight: 400;
    margin-left: 3px;
    font-size: 15px;
}

.rating-breakdown-legend-percent {
    float: right;
}

.rblc-excellent {
    border-left: 30px solid rgb(0, 153, 51);
}
.rblc-great {
    border-left: 30px solid rgb(51, 204, 0);
}
.rblc-average {
    border-left: 30px solid rgb(255, 232, 23);
}
.rblc-poor {
    border-left: 30px solid rgb(231, 165, 9);
}
.rblc-bad {
    border-left: 30px solid rgb(212, 38, 15);
}

.review-tag-charts {
    margin-top: 30px;
}

.percentage-bar-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    align-items: center;
}

.review-tag-charts-separator {
    text-align: center;
    opacity: .3;
    letter-spacing: 10px;
    margin: 0 0 15px 0;
    font-size: 20px;
}

.analytics-value-explainer {
    text-align: right;
    font-size: 12px;
    font-style: italic;
    margin-bottom: -15px;
}

.empty-card-placeholder {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 370px;
    padding: 45px;
    opacity: .5;
}

.empty-card-placeholder > p {
    text-align: center;
}

.percentage-bar-graph {
    background-color: var(--background-color);
    width: 180px;
    height: 13px;
    border-radius: 10px;
    overflow: hidden;
}

.percentage-bar-graph-filled {
    height: 13px;
    border-radius: 10px 10px 10px 10px;
}

.pbgf-green {
    background-color: rgb(0, 153, 51);
}

.pbgf-red {
    background-color: rgb(212, 38, 15);
}

.percentage-bar-label {
    margin: 0;
    font-size: 15px;
    width: 200px;
}

.percentage-bar-label-right {
    margin: 0;
    font-size: 15px;
    width: 70px;
}

.pblr-value {
    font-weight: 500;
}

.analytics-loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}

/* END OF ANALYTICS */

/* MONTHLY RECAP ANALYTICS */

.mr-report-header {
    width: 100%;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mr-report-header > .mr-rc-logo {
    width: 180px;
}

.mr-report-header > h1 {
    font-size: 25px;
    margin: 10px 0;
    font-weight: 600;
    text-align: center;
}

.mr-download-wrap {
    position: absolute;
    top: 10px;
    right: 10px;
}

.mr-download-btn {
    background: #fdfdfd;
    border: 1px solid #E0E6E8;
    border-radius: 20px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: var(--text-inactive);
    padding: 5px 28px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 35px;
    min-height: 35px !important;
    width: 150px;
}

.mr-download-btn:hover {
    background-color: #FBFBFB;
}

.mr-download-btn:disabled {
    cursor: not-allowed;
}

.mr-download-btn svg {
    color: #4ABF6C;
    width: 17px;
    height: 17px;
    margin-left: 3px;
    vertical-align: text-bottom;
}

.mr-header-info {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mr-header-info > p {
    margin: 0;
    color: var(--text-inactive);
    white-space: nowrap;
}

.mr-period-select {
    margin: 0;
    color: var(--text-inactive);
    height: 35px;
    border-width: 1px !important;
    background-position: calc(100% - 20px) .9em,
    calc(100% - 15px) .9em,
    calc(100% - 2.5em) 0.5em;
}

.mr-period-download {
    padding: 0;
    border: none;
    background-image: none;
    margin-right: -10px;
}

.mr-section-divider {
    width: 100%;
    text-align: center;
    background-color: #d9d9d921;
    border-top: 1px solid #E8ECED;
    border-bottom: 1px solid #E8ECED;
}

.mr-section-divider > p {
    width: 100%;
    color: var(--text-inactive);
    opacity: .5;
    margin: 3px 0;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
}

.mr-section-group-container {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
}

.mr-section-container {
    background-color: #fff;
    border: 1px solid #E8ECED;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    display: flex;
    flex-direction: column;
    margin: 15px 15px 0 15px;
    padding: 15px 15px 30px 15px;
    text-align: center;
    position: relative;
}

.mr-section-container-last {
    margin-bottom: 15px;
}

.mr-section-container-est {
    border: 1.5px dashed #E8ECED;
    background-color: #fdfdfd; 
}

.mr-section-bottom-info {
    background-color: #FBFBFB;
    border: 1px solid #E8ECED;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -8px 15px 0 15px;
    padding: 17px 10px 10px 10px;
}

.mr-info-left {
    display: flex;
    align-items: center;
}

.mr-info-left > svg {
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    fill: var(--racecheck-green);
    width: 23px;
    height: 23px;
    margin-right: 7px;
}

.mr-info-left > .warning-icon {
    color: #f7c615;
    stroke: #f7c615;
    fill: #f7c615;
}

.mr-info-left > p {
    margin: 0;
    color: var(--text-inactive);
    opacity: .7;
    font-size: 15px;
    width: fit-content;
}

.mr-section-bottom-info > .btn {
    margin: 0 0 0 20px;
    white-space: nowrap;
    border: 1px solid #E0E6E8;
    background-color: #fff;
    font-size: 15px;
    opacity: .8;
    display: flex;
    align-items: center;
}

.mr-section-bottom-info > .btn > svg {
    margin: 0 0 0 3px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
    opacity: .8;
    height: 18px;
}

.mr-section-bottom-info > .primary-btn {
    margin: 0 0 0 20px;
    white-space: nowrap;
    color: #fff;
    border: 1px solid var(--racecheck-green);
    background-color: var(--racecheck-green);
    font-size: 15px;
    display: flex;
    align-items: center;
    height: 31px !important;
    min-height: 31px !important;
    opacity: 1;
}

.mr-section-bottom-info > .primary-btn > svg {
    color: #fff;
    stroke: #fff;
    fill: #fff;
    opacity: 1;
}

.mr-section-title {
    font-size: 18px;
    margin: 0 auto 3px auto;
    color: var(--text-inactive);
    opacity: .8;
    font-weight: 500;
    width: fit-content;
}

.mr-title-tag {
    font-size: 11px;
    font-weight: 600;
    padding: 3px 8px;
    border-radius: 25px;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-top: 6px;
}

.mr-title-tag > svg {
    width: 13px;
    margin-right: 3px;
}

.mr-title-tag-est {
    background-color: #f1df8541;
    color: #e1b71d;
}

.mr-title-tag-est > .mr-icon-warning {
    color: #e1b71d;
    stroke: #e1b71d;
    fill: #e1b71d;
}

.mr-title-tag-container > .mr-title-tooltip {
    display: none;
    width: 280px;
    background-color: #fff;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    text-align: center;
    border-radius: 6px;
    border: 1px solid #e8ecee;
    position: absolute;
    margin: 5px 0 0 0;
    z-index: 100;
    top: 30px;
    right: -11.5%;
}

.mr-title-tag-container:hover > .mr-title-tooltip {
    display: block;
}

.mr-section-intro {
    margin: 0 0 40px 0;
    color: var(--text-inactive);
    opacity: .6;
}

.mr-estimated-cta {
    margin-top: 20px;
}

.mr-section-copy {
    color: var(--text-inactive);
    opacity: .6;
    margin: 0 0 15px 0;
}

.mr-estimated-cta > .btn {
    width: fit-content;
    padding: 0 50px !important;
    margin: 0 auto;
}

.mr-data-row {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

/* .mr-data-row-est {
    filter: blur(3px);
} */

.mr-chevron-right {
    color: var(--text-inactive-2);
    stroke: var(--text-inactive-2);
    fill: var(--text-inactive-2);
    opacity: .4;
    margin: auto 0;
}

.mr-stat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 40px;
}

.mr-stat-container-vertical-center {
    margin: auto 40px;
}

.mr-chart-container {
    width: 500px;
    margin: 0 35px 0 0;
}

.mr-chart-container.mobile {
    display: none;
}

.mr-stat {
    font-size: 30px;
    color: var(--text-normal);
    font-weight: 700;
    margin: 5px 0;
}

.mr-stat-stars {
    display: flex;
    align-items: center;
    color: var(--racecheck-gold);
}

.mr-stat-stars > .rating-stars {
    margin-left: 5px;
}

.mr-stat-stars > .rating-stars > svg {
    width: 30px;
    height: 30px;
}

.mr-stat-stars > .rating-stars > .rating-value {
    display: none;
}

.mr-stat-title {
    color: var(--text-inactive);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.mr-stat-title > span {
    opacity: .6;
}

.mr-stat-title > span > svg {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
    opacity: .6;
    cursor: help;
    margin-right: -5px;
    vertical-align: text-bottom;
}

.mr-title-tooltip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #e8ecee transparent;
}

.mr-stat-title  > .mr-title-tooltip {
    display: none;
    width: 280px;
    background-color: #fff;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    text-align: center;
    border-radius: 6px;
    border: 1px solid #e8ecee;
    position: absolute;
    margin: 5px 0 0 0;
    z-index: 100;
    top: 100%;
    left: 50%;
    margin-left: -140px;
}

.mr-stat-title:hover > .mr-title-tooltip {
    display: block;
}

.mr-tooltip-desc {
    color: var(--text-inactive);
    opacity: .75;
    font-size: 14px;
    text-align: center;
    display: block;
    padding: 10px;
}

.mr-tooltip-extra-row {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px dashed #e8ecee;
}

.mr-tooltip-extra-row > .mr-tooltip-desc {
    padding: 10px 10px 10px 0;
}

.mr-tooltip-extra-row > svg {
    width: 16px;
    margin: 0 4px 0 10px;
}

.mr-tooltip-extra-row > .mr-icon-info {
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    fill: var(--racecheck-green);
}

.mr-tooltip-extra-row > .mr-icon-warning {
    color: #F4D11B;
    stroke: #F4D11B;
    fill: #F4D11B;
}

.roi-input-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.roi-input {
    font-size: 30px;
    color: var(--text-normal);
    font-weight: 700;
    margin: 1px 0 3px 0;
    text-align: center;
    width: 180px;
    padding: 0;
    height: auto;
}

.roi-input::-webkit-outer-spin-button,
.roi-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mr-roi-currency {
    margin: 0 5px 0 -20px;
    font-size: 30px;
    font-weight: 600;
    opacity: .5;
}

/* Firefox */
.roi-input[type=number] {
  -moz-appearance: textfield;
}

.roi-btn {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    margin: 0 -40px 0 10px;
}

.roi-btn > svg {
    width: 20px;
}

.roi-edit-btn {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    margin: 0 -25px 0 0;
}

.roi-edit-btn > svg {
    width: 20px;
    height: 20px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    opacity: .4;
}

.roi-edit-btn:hover > svg {
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    opacity: 1;
}

.roi-positive {
    color: var(--forest-green);
}

.mr-award-container {
    display: flex;
    align-items: center;
}

.mr-award-info {
    width: 100%;
}

.mr-award-progress-bars {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mr-award-progress-group {
    width: 48%;
}

.mr-award-badge-container {
    padding: 0 30px;
    height: 100%;
    opacity: .5;
}

.mr-award-badge {
    width: 160px;
}

.mr-award-progress-group > .progress-bar-label {
    border: none;
    justify-content: initial;
    padding: 0;
}

.mr-award-progress-group > .progress-bar-label > .progress-bar-label-group {
    align-items: flex-end;
}

.mr-award-progress-group > .progress-bar-label > .progress-bar-label-group > .progress-bar-value {
    font-size: 30px;
    color: var(--text-normal);
    font-weight: 700;
}

.mr-award-progress-group > .progress-bar-label > .progress-bar-label-group > .progress-bar-percentage {
    font-size: 16px;
    color: var(--text-inactive);
    font-weight: 700;
    opacity: .6;
    font-weight: 400;
    margin: 0 0 4px 0;
}

.mr-award-progress-group > .progress-bar-label > .progress-bar-label-group > svg {
    width: 15px;
    height: 15px;
    color: var(--forest-green);
    stroke: var(--forest-green);
    fill: var(--forest-green);
    margin: 0 0 5px 3px;
}

.mr-award-progress-group > .progress-bar-container {
    height: 14px;
    border-radius: 5px;
}

.mr-award-progress-group > .progress-bar-container > .progress {
    background-color: var(--forest-green);
}

.mr-award-progress-group > .progress-bar-container > .shrinker {
    background-color: var(--background-color);
}

.mr-award-btns {
    margin: 20px auto 0 auto;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mr-award-btns > .btn {
    width: 50%;
    margin: 0 8px;
}

.mr-award-update-year-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -10px;
}

.mr-award-update-year-container > img {
    width: 85%;
    opacity: .10;
    overflow: auto;
    padding: 10px;
    filter: saturate(.55) blur(5px);
}

.mr-award-yu-content {
    position: absolute;
}

.mr-award-yu-content > p {
    margin: 10px 0 15px 0;
}

.mr-award-yu-content > svg {
    width: 30px;
    opacity: .6;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
}

.mr-section-loader > .lds-roller {
    opacity: .5;
    transform: scale(.75);
    margin: 83px auto 93px auto;
}

.mr-mobile-view {
    display: none;
}

/* END OF MONTHLY RECAP ANALYTICS */

/* ANALYTICS FREE PLACEHOLDERS */

.analytics-placeholder-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 30px 30px 30px;
    border: 2px dashed #C7C7C7;
    border-radius: 5px;
    text-align: center;
}

.analytics-placeholder-container > img {
    width: 50%;
}

.analytics-placeholder-container > h2 {
    margin: 15px 0 10px 0;
    font-weight: 600;
    font-size: 24px;
    color: var(--text-normal);
}

.analytics-placeholder-container > p {
    margin: 0 0 30px 0;
    color: var(--text-inactive);
    width: 85%;
    text-align: center;
}

.analytics-placeholder-container > .btn {
    margin: 0;
    width: 40%;
}

/* END OF ANALYTICS FREE PLACEHOLDERS */

/* EDIT EVENT MODAL */

.event-details-ms, .event-location-ms, .event-distances-ms, .event-features-ms, .event-course-ms, .event-header-img-ms, .event-extras-ms, .event-registration-ms {
    margin-top: -5px;
    padding-bottom: 5px;
}

.event-covid-ms, .event-maps-ms, .event-address-map-container, .event-gallery-ms, .event-video-ms {
    padding-top: 15px;
    border-top: 1px solid #e8ecee;
}

.edit-event-modal-header {
    color: var(--text-normal);
    font-weight: 500;
    margin: 0 0 15px 0 !important;
}

.edit-event-modal-error {
    text-align: center;
    color: var(--text-error);
    margin-bottom: 25px;
    padding: 15px 10px;
    background-color: #fff4f4;
    border-radius: 5px;
    font-weight: 600;
}

.edit-event-input-row {
    margin-bottom: 0 !important;
}

.event-desc-textarea {
    height: auto !important;
    padding: 5px 7px !important;
    max-width: 100%;
}

.event-address-map-container {
    width: 100%;
    margin-bottom: 10px;
}

.event-address-map {
    width: 100%;
    height: 200px;
    background-color: bisque;
    border: 1px solid burlywood;
}

.add-new-distance-btn, .add-new-map-btn {
    width: 100%;
    padding: 15px 10px;
    border: 2px dashed #e8ecee;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    color: var(--text-inactive);
}

.add-new-distance-btn:hover, .add-new-map-btn:hover {
    background-color: #fafafa;
    cursor: pointer;
}

.event-distance-container, .event-map-container {
    width: 100%;
    padding: 15px 10px;
    border: 2px solid #e8ecee;
    border-radius: 5px;
    margin-bottom: 15px; 
}

.edc-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edc-left {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.edc-title {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 5px 0 !important;
    color: var(--text-normal);
}

.edc-desc {
    margin: 0 !important;
}

.edc-btns-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
}

.edc-card-btn {
    font-weight: 500;
    cursor: pointer;
    margin-top: 8px !important;
    margin-bottom: 0 !important;
    color: var(--text-inactive);
    text-align: center;
}

.edc-card-btn:first-child {
    margin-right: 13px;
}

.edc-card-btn:last-child {
    margin-left: 13px;
}

.edc-card-btn-red {
    color: var(--text-error) !important;
}

.edc-card-btn:hover {
    text-decoration: underline;
}

.modal-input-group-distance-legs {
    width: 35%;
}

.modal-input-group-distance-type {
    width: 22%;
}

.modal-input-group-distance-type > .modal-input-label {
    margin: 0 0 8px 0 !important;
}

.distance-leg-type {
    line-height: 32px;
    margin: 0 !important;
}

.edit-event-border-top {
    border-top: 1px solid #e8ecee;
    padding-top: 15px;
    margin-top: 10px;
}

.edit-event-checkbox-container {
    display: flex;
}

.event-header-container {
    width: 100%;
    height: 230px;
    border: 2px solid var(--background-color);
    border-radius: 5px 5px 0 0;
    overflow: hidden;
}

.event-header-btn-container {
    width: 100%;
    padding: 10px 0;
    margin-bottom: 15px;
    background-color: var(--background-color);
    border-radius: 0 0 5px 5px;
}

.event-header-btn {
    font-weight: 500;
    cursor: pointer;
    margin: 0 !important;
    color: var(--text-inactive);
    text-align: center;
}

.event-header-btn:hover {
    text-decoration: underline;
}

.event-gallery-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding-top: 5px;
}

.event-gallery-img {
    position: relative;
    margin-right: 18px;
}

.egi-preview {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    background-color: thistle;
}

.egi-btn {
    width: 25px;
    height: 25px;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: -11px;
    right: -11px;
    cursor: pointer;
}

.egi-btn:hover {
    background-color: #dddddd;
}

.egi-btn > svg {
    width: 15px;
    height: 15px;
    margin: 0;
    fill: var(--text-inactive);
    stroke: var(--text-inactive);
}

.event-gallery-new-img-btn {
    width: 80px;
    height: 80px;
    border: 2px dashed #e8ecee;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-inactive);
    text-align: center;
    padding: 6px;
}

.event-gallery-new-img-btn:hover {
    background-color: #fafafa;
    cursor: pointer;
}

.event-gallery-new-img-btn > p {
    margin: 0 !important;
    font-size: 13px;
}

.basic-edit-event-contact {
    width: 100%;
    text-align: center;
    padding: 25px 80px 10px 80px;
    font-size: 15px;
    color: var(--text-inactive);
}

.edit-event-email {
    color: var(--racecheck-green);
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

.edit-event-email:hover {
    text-decoration: underline;
}

/* END OF EDIT EVENT MODAL*/

/* RESPONSIVE */

@media screen and (max-width: 1410px) {
    .event-cards-container, .events-feed-header {
        max-width: 870px !important;
    }
}

@media screen and (max-width: 900px) {

    /* EVENT LIST */

    .mr-chart-container:not(.mobile) {
        display: none;
    }

    .mr-chart-container.mobile {
        display: block;
        height: 250px;
    }

    .analytics-comparative-chart:not(.mobile) {
        display: none;
    }
    .analytics-comparative-chart.mobile {
        display: block;
    }
    
    .events-feed-header {
        width: 100%;
        display: block;
        padding: 0;
    }

    .efh-status-btns {
        width: 100%;
        margin-bottom: 10px;
        justify-content: center;
    }

    .efh-search, .efh-search-input {
        width: 100%;
    }

    .efh-add-btn {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .efh-add-btn > button {
        border: 2px dashed #c7c7c7;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 10px 0;
        border-radius: 7px;
        margin-bottom: 15px;
    }

    .events-feed__header > .events-feed__search-bar {
        max-width: 100%;
    }

    .events-feed__header > .events-feed__add-button {
        justify-self: center;
        padding: 0;
    }

    .event-list-toggle {
        width: 100%;
    }

    .event-toggle-button {
        width: 50%;
    }

    .button-event-card-container {
        width: 100%;
    }

    .button-event-card {
        max-width: 100%;
        height: 50px;
        margin: 0 0 20px 0;
    }

    .button-event-card > svg {
        display: none;
    }

    .button-event-card > p {
        padding: 0;
    }

    .event-card-container {
        width: 100% !important;
    }

    .event-cards-container {
        width: 100% !important;
        justify-content: center;
    }

    .event-card {
        max-width: 100% !important;
        min-width: 100% !important;
        margin: 0;
    }

    .event-card-btn-container {
        margin: -5px 0 20px 0;
        width: 100%;
    }

    .event-card-start-collecting, .event-card-pending {
        width: 100%;
        margin-left: 0;
        margin-bottom: 4px;
    }

    .event-card-share-list > .event-card-btn-separator {
        display: none;
    }

    .ecsl-popup-copy {
        left: -25px;
    }
    
    .ecsl-popup-copied {
        left: -33px;
    }
    
    .ecsl-popup-email {
        left: -45px;
    }
    
    .ecsl-popup-twitter {
        left: -47px;
    }
    
    .ecsl-popup-facebook {
        left: -56px;
    }
    
    .ecsl-popup-whatsapp {
        left: -62px;
    }

    .event-header-tasks-notification {
        top: -6px;
        right: 0px;
    }

    /* END OF EVENT LIST */

    /* EVENT ANALYTICS */

    .analytics-report-header > .horizontal-dropdowns > .horizontal-select-group {
        flex-wrap: wrap;
        justify-content: center;
    }

    .analytics-report-header > .horizontal-dropdowns > .horizontal-select-group > .horizontal-select-label {
        white-space: inherit;
        text-align: center;
        margin: 0 5px 0 0;
    }

    .analytics-report-header > .disabled-primary-btn {
        display: none;
    }

    .analytics-top-bar {
        flex-wrap: wrap;
    }

    .top-bar-stat {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        margin: 0;
    }

    .top-bar-stat:nth-of-type(1) {
        width: 50%;
        border-right: 1px solid #DBDBDB;
    }

    .top-bar-stat:nth-of-type(3) {
        width: 100%;
        order: 2;
        border-top: 1px solid #DBDBDB;
    }

    .top-bar-stat:nth-of-type(5) {
        width: 50%;
    }

    .top-bar-stat-title {
        margin: 5px 0 0 0;
    }

    .tb-stat-title-mobile {
        display: block;
    }

    .tb-stat-title-desktop {
        display: none;
    }

    .top-bar-stat-info, .top-bar-stat > .rating-stars > .rating-value {
        font-size: 25px;
    }

    .top-bar-divider {
        display: none;
    }

    .top-bar-stat > .rating-stars > svg {
        height: 30px;
        width: 30px;
    }

    .top-bar-stat > .rating-stars > svg:not(:first-child) {
        display: none;
    }

    .analytics-report-row {
        display: block;
    }

    .analytics-breakdown {
        width: 100%;
    }

    .tags-breakdown {
        width: 100%;
        border-top: 1px solid #DBDBDB;
        border-right: 0 !important;
    }

    .review-trends-chart {
        width: 100%;
    }

    .percentage-bar-container {
        flex-wrap: wrap;
    }

    .percentage-bar-label {
        flex-basis: 50%;
    }

    .percentage-bar-graph {
        order: 1;
        flex-basis: 100%;
        margin-top: 5px;
    }

    .analytics-comparative-chart {
        width: 100%;
    }

    /* END OF EVENT ANALYTICS */

    /* MONTHLY RECAP */

    .mr-download-wrap {
        width: 100%;
        padding: 10px;
        position: relative;
        top: auto;
        right: auto;
    }

    .mr-download-btn {
        width: 100%;
    }

    .mr-report-header {
        padding-top: 10px;
    }

    .mr-header-info {
        flex-direction: column;
    }

    .mr-data-row {
        flex-direction: column;
        align-items: center;
    }

    .mr-section-intro {
        margin: 0 0 30px 0;
    }

    .mr-chevron-right {
        margin: 30px 0;
        transform: rotate(90deg);
    }

    .mr-section-bottom-info {
        flex-direction: column;
    }

    .mr-info-left {
        margin-bottom: 13px;
    }

    .mr-header-info > .bullet {
        opacity: 0;
    }

    .mr-section-bottom-info > .btn {
        width: 100%;
        margin: 0;
        justify-content: center;
    }

    .mr-award-container {
        flex-direction: column;
    }

    .mr-award-progress-bars {
        flex-direction: column;
    }

    .mr-award-progress-group {
        width: 100%;
    }

    .mr-award-btns {
        margin: 10px auto 0 auto;
        width: 100%;
        flex-direction: column;
    }

    .mr-award-btns > .btn {
        width: 100%;
        margin: 10px 8px 0 8px;
    }

    .mr-award-badge {
        width: 60%;
    }

    .mr-mobile-hide {
        display: none;
    }

    .mr-mobile-view {
        display: block;
    }

    .mr-chart-container {
        margin: 0;
        width: auto;
    }

    /* END OF MONTHLY RECAP */

    /* EDIT EVENT MODAL */

    .modal-split-container {
        display: block;
    }

    .msch-date-halves {
        min-height: 100%;
        max-height: 100%;
    }

    .modal-split-child-half {
        width: 100%;
        padding: 15px 0 15px 0;
    }

    .modal-split-child-half:nth-child(1) {
        border-bottom: 1px solid #e8ecee;
        border-right: none;
        padding: 0 0 20px 0;
    }

    .basic-edit-event-contact {
        padding: 0;
        margin-bottom: 25px;
    }

    /* END OF EDIT EVENT MODAL */

    /* REPORT REVIEW MODAL */

    .report-review-modal-review-details {
        margin-bottom: 15px;
        flex-wrap: wrap;
    }

    .report-review-modal-review-data > .inline-select-container {
        display: block;
    }

    .report-review-modal-review-data > .inline-select-container > .rrmrd-label {
        width: 100%;
        margin: 0 0 5px 0;
    }

    .report-review-modal-review-data > .inline-select-container > select {
        margin: 0;
        width: 100%;
    }

    .rrmrd-info {
        width: auto;
    }

    .rrmrd-row > p {
        margin: 0 10px 10px 0;
    }

    /* END OF REPORT REVIEW MODAL */

}
/* END OF RESPONSIVE */