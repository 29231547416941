.error-message-container {
    position: absolute;
    top: -32px;
    margin: 0 auto;
    background-color: var(--text-error);
    text-align: center;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 0 50px;
    transition: top .15s ease-out;
}

.display-error-message {
    top: 0 !important;
    z-index: 3000;
}

.error-message-text {
    margin: 0 0 2px 0;
    color: #fff;
    line-height: 30px;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: .1px;
}

/* RESPONSIVE */

@media screen and (max-width: 900px) {

    .error-message-container {
        padding: 10px;
    }

    .error-message-text {
        line-height: 20px;
    }

}

/* END OF RESPONSIVE */