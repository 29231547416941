/* START OF ARTICLES */

.articles-list {
    display: flex;
    flex-direction: row;
    width: 1160px;
    flex-wrap: wrap;
    margin: 0 auto;
}

.article-card {
    max-width: 270px;
    min-width: 270px;
    height: 300px;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 10px;
    z-index: 2;
}

.article-card:hover {
    box-shadow: 0px 0px 6px -2px rgba(124, 124, 124, 0.5);
}

.article-card-img {
    width: 100%;
    height: 170px;
    overflow: hidden;
}

.article-card-img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.article-info {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 130px;
}

.article-info-top > h2 {
    font-size: 17px;
    font-weight: 600;
    line-height: 18px;
    margin: 0 0 10px 0;
    color: var(--text-normal);
}

.article-info-bottom > p {
    font-size: 14px;
    color: var(--text-inactive);
    display: flex;
    align-items: center;
    margin: 0;
}

.article-info-bottom > p:not(:last-child) {
    margin-bottom: 4px;
}

.article-info-bottom > p > svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    fill: var(--text-inactive);
    stroke: var(--text-inactive);
}

/* END OF ARTICLES */

/* START OF FAQS */

.faq-container {
    display: flex;
    margin: 0 auto;
    max-width: 800px;
    flex-direction: column;
}

.faq-section {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    z-index: 2;
}

.faq-section:not(:last-child) {
    margin-bottom: 15px;
}

.faq-section-header {
    font-size: 22px;
    font-weight: 600;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 1px solid #DBDBDB;
}

.faq-section-item {
    cursor: pointer;
}

.faq-section-item:not(:last-child) {
    border-bottom: 1px solid #DBDBDB;
}

.faq-section-item > a {
    color: var(--text-inactive);
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.faq-section-item:hover > a {
    color: var(--text-active);
}

.faq-section-item:hover > a > svg {
    display: block;
}

.faq-section-item > a > svg {
    stroke: var(--racecheck-green);
    width: 15px;
    height: 15px;
    margin-left: auto;
    display: none;
}

/* END OF FAQS */

/* START OF TOOLKITS */

.toolkits-container {
    display: flex;
    margin: 0 auto;
    max-width: 1000px;
    flex-direction: column;
}

.toolkits-documents-container {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
}

.toolkits-header {
    padding: 5px 20px 0 20px;
    border-bottom: 1px solid #e8ecee;
    position: relative;
}

.toolkits-header-nav {
    display: flex;
    margin-top: 10px;
}

.toolkits-nav-list {
    display: flex;
}

.toolkits-nav-item {
    color: var(--text-inactive);
    font-size: 16px;
    font-weight: 500;
}

.toolkits-nav-item:not(:last-child) {
    margin-right: 30px;
}

.toolkit-nav-item-link {
    color: var(--text-inactive);
    text-decoration: none;
    position: relative;
    cursor: pointer;
}

.toolkit-link {
    display: inline-block;
    padding-bottom: 15px;
}

.toolkit-link-selected {
    box-shadow: inset 0 -3px var(--racecheck-green);
    color: var(--text-active);
}

.toolkit-link:hover {
    box-shadow: inset 0 -3px #cecece;
}

.toolkit-link-selected:hover {
    box-shadow: inset 0 -3px var(--racecheck-green);
}

.toolkit-content {
    padding: 10px 19px;
}

.toolkit-section-header {
    margin: 10px 0;
    font-size: 24px;
    font-weight: 600;
}

.toolkit-section-subheader {
    margin: 25px 0 0 0;
    font-size: 22px;
    font-weight: 600;
}

.toolkit-p-first {
    margin-top: 10px;
}

.toolkit-content > p {
    line-height: 21px;
}

.toolkit-subsection-header {
    font-size: 19px;
    font-weight: 600;
    margin: 10px 0 0 0;
}

.toolkit-span {
    font-style: italic;
    font-weight: 500;
}

.toolkit-content-link {
    color: var(--racecheck-green);
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
}

.toolkit-content-link:hover {
    text-decoration: underline;
}

.toolkit-quote {
    font-size: 19px;
    color: var(--text-inactive);
    font-style: italic;
    text-align: center;
}

.toolkit-img {
    width: 80%;
    margin: 30px auto;
    box-shadow: 0px 0px 10px -2px rgba(184, 184, 184, 0.5);
    display: block;
}

.toolkit-img-smaller {
    width: 60%;
    margin: 30px auto;
    box-shadow: 0px 0px 10px -2px rgba(184, 184, 184, 0.5);
    display: block;
}

.toolkit-double-imgs {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.toolkit-double-imgs > .toolkit-img {
    width: 48% !important;
    height: 100%;
    margin: 0;
}

.tdi-margin {
    margin: 30px auto !important;
}

.toolkit-triple-imgs {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 15px auto;
}

.toolkit-img-caption-box {
    width: 32%;
    display: flex;
    flex-direction: column;
}

.toolkit-img-caption-box > .toolkit-img {
    margin: 0;
    width: 100%;
    height: 100%;
}

.toolkit-img-caption {
    background-color: #e8ecee;
    padding: 7px 0px 7px 10px;
    margin: 0;
    font-weight: 600;
}

.toolkits-top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.toolkits-awards-card, .toolkits-assets-card {
    width: 49%;
    height: 180px;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    overflow: hidden;
    margin: 0 0 20px 0;
    padding: 18px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: flex-end;
}

.toolkits-awards-card > .btn, .toolkits-assets-card > .btn {
    width: 350px;
    position: relative;
}

.toolkits-assets-card::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../images/assets_img.jpg");
    background-color: #fff;
    background-size: cover;
    background-position: center -70px;
    filter: grayscale(100%) brightness(150%);
}

.toolkits-awards-card::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../images/awards_img.jpg");
    background-color: #fff;
    background-size: cover;
    background-position: center -70px;
    filter: grayscale(100%) brightness(190%);
}

.toolkits-assets-card > img, .toolkits-awards-card > img {
    position: relative;
    margin: 0 auto 25px auto;
}

.toolkits-assets-card > .toolkit-header-btn, .toolkits-awards-card > .toolkit-header-btn {
    position: relative;
    text-decoration: none;
    width: 350px;
}

/* END OF TOOLKITS */

/* START OF AWARDS MODAL */

.award-modal-loading-container {
    height: 358px !important;
}

.award-badges-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 25px;
}

.award-badge-container {
    width: 22%;
    background-color: var(--background-color);
    border: 2px solid var(--background-color);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.award-badge-selected {
    border: 2px solid var(--racecheck-green);
}

.award-badge-container > .badge-img {
    width: 100%;
    height: 100%;
}

.award-badge-ineligible {
    cursor: not-allowed;
    pointer-events: none;
}

.award-badge-ineligible > .badge-img {
    opacity: .4;
}

.award-badge-ineligible > svg {
    width: 40%;
    height: 40%;
    color: var(--text-active);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.award-checkbox {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 3px;
    background-color: #fff;
    border: 2px solid #e0e6e8;
}

.award-badge-ineligible > .award-checkbox {
    display: none;
}

.award-badge-selected > .award-checkbox {
    background-color: var(--racecheck-green);
    border: 2px solid var(--racecheck-green);
}

.award-badge-selected > .award-checkbox:after {
    top: 2px;
    left: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    content: "";
    position: absolute;
}

.award-file-types-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}

.award-file-type {
    background-color: var(--background-color);
    border: 2px solid var(--background-color);
    padding: 5px 8px 5px 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
}

.award-file-type:not(:last-child) {
    margin-right: 20px;
}

.award-file-type-selected {
    border: 2px solid var(--racecheck-green);
}

.file-type-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: #fff;
    border: 2px solid #e0e6e8;
    margin-right: 5px;
    position: relative;
}

.award-file-type-selected > .file-type-checkbox {
    background-color: var(--racecheck-green);
    border: 2px solid var(--racecheck-green);
}

.award-file-type-selected > .file-type-checkbox:after {
    top: 2px;
    left: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    content: "";
    position: absolute;
}

/* END OF AWARDS MODAL */

/* RESPONSIVE */

@media screen and (max-width: 900px) {

    /* TOOLKITS */

    .toolkits-top-row {
        display: block;
    }

    .toolkits-awards-card, .toolkits-assets-card {
        width: 100%;
        height: 100%;
        padding: 10px;
    }

    .toolkits-assets-card > img, .toolkits-awards-card > img {
        margin: 0 auto 20px auto;
        width: 100%;
    }

    .toolkit-header-btn {
        width: 100% !important;
    }

    .toolkits-assets-card > a, .toolkits-awards-card > a {
        width: 100%;
    }

    .toolkit-img, .toolkit-img-smaller {
        width: 100%;
        margin: 30px 0;
    }

    .toolkit-triple-imgs {
        width: 100%;
        align-items: flex-start;
    }

    .toolkit-double-imgs {
        width: 100%;
    }

    /* END OF TOOLKITS */

    /* ARTICLES */

    .articles-list {
        flex-direction: column;
        width: 100% !important;
    }

    .article-card {
        max-width: 100% !important;
        min-width: 100% !important;
        margin: 12px 0;
    }

    /* END OF ARTICLES */

}

/* END OF RESPONSIVE */