.widgets-container {
    display: flex;
    max-width: 280px;
    min-width: 280px;
    flex-direction: column;
}

.manage-widget-parent-container {
    display: flex;
    flex-direction: row;
}

/* RACECHECK WIDGETS */

.racecheck-widgets-container {
    display: flex;
    margin: 0 auto;
    flex-direction: row;
}

.rw-left-col {
    width: calc(100% - 420px);
    padding: 0 20px 0 0;
}

.rw-preview {
    width: 420px !important;
}

.rw-preview-title {
    padding: 0 20px;
}

.rw-header {
    margin: 0 0 20px 0;
    font-size: 40px;
    font-weight: 700;
}

.rw-copy {
    margin: 0 0 10px 0;
}

/* END OF RACECHECK WIDGETS */

/* MANAGE WIDGET */

.manage-widget-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px;
}

.manage-widget-header > h2 {
    font-size: 25px;
    font-weight: 600;
    color: var(--text-normal);
    margin: 0 15px 0 0;
}

.manage-widget-back-link {
    margin-right: 5px;
    cursor: pointer;
    text-decoration: none;
    color: var(--text-normal)
}

.manage-widget-status {
    padding: 2px 13px;
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
    display: inline-block;
}

.manage-widget-status-active {
    background-color: #81d7946b;
    color: #14911f;
}

.manage-widget-status-inactive {
    background-color: rgba(233, 164, 155, 0.274);
    color: rgb(194, 36, 15);
}

.manage-widget-status-no-widget {
    background-color: rgba(207, 207, 207, 0.274);
    color: rgb(100, 100, 100);
}

.mw-widget-type {
    margin: 0 0 0 15px;
    color: var(--text-inactive);
    opacity: .6;
    font-style: italic;
}

.mw-widget-id {
    color: var(--text-inactive);
    opacity: .6;
    margin: 0 0 0 auto;
}

.mw-multirace-list-intro {
    font-weight: 600;
    color: var(--text-normal);
}

.mw-multirace-list {
    color: var(--text-inactive);
    margin-top: -10px;
    margin-bottom: 20px;
}


.mw-container-header {
    padding: 5px 20px 0 20px;
    border-bottom: 1px solid #e8ecee;
    position: relative;
}

.mw-header-nav {
    display: flex;
    justify-content: space-between;
}

.mw-nav-list {
    display: flex;
    margin-top: 10px;
}

.mw-nav-item {
    color: var(--text-inactive);
    font-size: 16px;
    font-weight: 500;
}

.mw-nav-item:not(:last-child) {
    margin-right: 30px;
}

.mw-nav-item-link {
    color: var(--text-inactive);
    text-decoration: none;
    position: relative;
    cursor: pointer;
}

.mw-link {
    display: inline-block;
    padding-bottom: 15px;
}

.mw-link:hover {
    box-shadow: inset 0 -3px #cecece;
}

.manage-widget-link-selected {
    box-shadow: inset 0 -3px var(--racecheck-green);
    color: var(--text-active);
}

.manage-widget-link-selected:hover {
    box-shadow: inset 0 -3px var(--racecheck-green);
}

.mw-setup {
    display: flex;
    flex-direction: column;
}

.mw-setup-options-container {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    display: flex;
    flex-direction: column;
}

.mw-setup-options-container:not(:last-child) {
    margin-bottom: 15px;
}

.mw-setup-options-container > .wpc-content-section {
    padding: 10px 15px !important;
    border-top: 1px solid #E8ECED !important;
    margin: 0 !important;
}

.mw-soc-static {
    display: block;
}

.mw-soc-mobile {
    display: none;
}

.mw-setup-options-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 15px 10px 12px;
}

.mw-setup-options-header > svg {
    width: 15px;
    height: 15px;
    fill: var(--text-inactive);
    stroke: var(--text-inactive);
    margin-left: 5px;
    opacity: .75;
}

.mw-setup-options-header > .sectionArrowActive {
    transform: rotate(180deg);
}

.mw-soc-static > .mw-setup-options-header > svg {
    transform: rotate(-90deg);
}

.mw-setup-options-header:hover > svg {
    fill: var(--racecheck-green);
    stroke: var(--racecheck-green);
    opacity: 1;
}

.mw-setup-options-header-left {
    display: flex;
    align-items: center;
}

.mw-setup-options-header-left > .section-icon {
    width: 22px;
    height: 22px;
}

.mw-setup-options-header-copy {
    margin: 0 0 0 10px;
}

.mw-setup-options-header-copy > h3 {
    margin: 0 0 1px 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--text-normal);
}

.mw-setup-options-header-copy > p {
    margin: 0;
    color: var(--text-inactive);
    opacity: .8;
    font-size: 14px;
}

.mw-setup-options-body {
    padding: 10px 15px;
    border-top: 1px solid #E8ECED;
}

.mw-setup-row {
    margin-bottom: 5px;
}

.mw-setup-row:not(:last-child) {
    margin-bottom: 10px;
}

.mw-setup-row-label {
    font-size: 15px;
    color: var(--text-inactive);
    font-weight: 500;
    min-height: 18px;
    display: flex;
    align-self: center;
    position: relative;
    width: fit-content;
}

.mw-setup-row-label > svg {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
    opacity: .5;
    cursor: help;
}

.mw-previous .mw-previous-tooltip::after, .mw-setup-tooltip::after {
    content: "";
    position: absolute;
    top: 10%;
    right: 100%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #e8ecee transparent transparent;
}

.mw-setup-row-label  > .mw-setup-tooltip, .mw-code-header > .mw-setup-tooltip {
    display: none;
    width: 300px;
    background-color: rgb(253, 253, 253);
    text-align: center;
    border-radius: 6px;
    border: 1px solid #e8ecee;
    padding: 5px;
    position: absolute;
    top: 0%;
    left: 100%;
    margin-left: 10px;
    z-index: 100;
}

.mw-setup-row-label > svg:hover + .mw-setup-tooltip, .mw-code-header > svg:hover + .mw-setup-tooltip {
    display: block;
}

.mw-setup-row-select {
    height: 32px;
    border: 1px solid #e0e6e8;
    background-position: calc(100% - 20px) 0.8em,
    calc(100% - 15px) 0.8em,
    calc(100% - 1.5em) 1em !important;
    padding: 0 7px;
    margin-bottom: 10px;
}

.mw-setup-row-input {
    height: 32px;
    padding: 0 7px;
    margin-bottom: 10px;
    border: 1px solid #e0e6e8;
}

.mw-setup-row-url > .mw-setup-row-input {
    padding-right: 0;
}

.mw-tooltip {
    width: 16px;
    height: 16px;
    overflow: hidden;
    background-color: var(--background-color);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mw-tooltip:hover {
    cursor: pointer;
}

.mw-tooltip-icon {
    margin: 0 auto;
    font-size: 13px;
    color: var(--text-inactive);
    font-weight: 500;
    opacity: .7;
    width: 20px;
    text-align: center;
}

.mw-setup-row > .checkbox-with-label > .checkbox-container {
    flex-direction: row;
}

.mw-setup-row > .checkbox-with-label > .checkbox-container > .checkmark {
    height: 26px;
    width: 26px;
    border: 1px solid #d8dee0;
}

.checkbox-container:hover input ~ .checkmark {
    background-color: #edf2f5;
}

.checkbox-container input:checked ~ .checkmark {
    background-color: var(--racecheck-green);
    border: 1px solid var(--racecheck-green) !important;
}

.mw-setup-row > .checkbox-with-label > .checkbox-container > .checkmark:after {
    top: 6px;
    width: 6px;
    height: 12px;
    border-width: 0 2px 2px 0;
}

.mw-setup-row > .checkbox-with-label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.mw-setup-row > .checkbox-with-label > p {
    margin: 0;
}

.mw-setup-structured-tooltip {
    position: absolute !important;
    left: 265px;
    margin-top: 20px;
    z-index: 2000;
}

.mw-setup-url-tooltip {
    position: absolute !important;
    left: 470px;
    margin-top: 10px;
    z-index: 2000;
}

.mw-setup-tooltip-text {
    background-color: var(--background-color);
    border-radius: 5px;
    padding: 3px 10px;
    position: relative;
}

.mw-setup-tooltip-text:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid var(--background-color);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    left: -14px;
}

.mw-setup-tooltip-text > p {
    margin: 0;
    color: var(--text-inactive);
    font-weight: 400;
    font-size: 15px;
}

.mw-setup-inline-error {
    color: var(--text-error);
    font-weight: 500;
    padding: 0;
    margin: 0;
    font-size: 12px;
}

.mw-setup-row > .checkbox-with-label > .checkbox-container {
    background: none;
    padding: 0;
    /* margin: 0 10px 0 -10px; */
    margin: 0;
}

.mw-setup-row > div > .checkbox-container > .checkmark {
    top: 4px;
}

.mw-setup-row > div > .checkbox-container > .checkmark:after {
    top: 4px;
}

.mw-container-footer {
    padding: 0;
    margin: 0 0 10px 0;
    position: relative;
}

.mw-divider {
    border-top: 1px solid #E0E6E8;
    margin: 25px 0;
}

.mw-code-header {
    font-size: 17px;
    color: var(--text-normal);
    font-weight: 600;
    min-height: 18px;
    margin: 0 0 -5px 0;
    display: flex;
    align-items: center;
    position: relative;
    width: fit-content;
}

.widget-tracking-status {
    font-size: 11px;
    font-weight: 600;
    padding: 3px 8px;
    border-radius: 25px;
    text-align: center;
    width: fit-content;
    margin-left: 10px;
}

.wt-tracking {
    background: rgba(129, 215, 148, 0.4);
    color: #14911F;
}

.wt-not-tracking {
    background: rgba(219, 219, 219, 0.4);
    color: #636363;
}

.mw-code-header > svg {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    fill: var(--text-inactive);
    opacity: .5;
    cursor: help;
}

.mw-code-step {
    font-weight: 500;
}

.mw-code-p:first-child {
    margin: 5px 0 0 0;
}

.mw-code-p:last-child {
    margin: 0 0 5px 0;
}

.mw-code-container {
    margin: 20px 0;
    position: relative;
    overflow: hidden;
}

.mw-code-content {
    background-color: #141414;
    border-radius: 5px;
    padding: 12px;
    overflow-x: auto;
    padding-right: 82px;
}

.mw-forward-button {
    background-color: var(--racecheck-green);
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px 32px;
    font-weight: 500;
    font-size: 13px;
    border-radius: 13.5px;
    align-self: flex-end;
    width: max-content;
}

.mw-code-pre {
    margin: 0;
    color: #ededed;
    font-family: monospace;
    font-weight: 500;
    white-space: pre-wrap;
}

.mw-copy-code {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 2px 8px 2px 5px;
    top: 0;
    right: 0;
    height: 35px;
    background-color: rgb(63, 63, 63);
    border-radius: 0 5px 0 5px;
}

.mw-copy-code:hover {
    cursor: pointer;
    background-color: rgb(79, 79, 79);
}

.mw-copy-code > p {
    color: #fff;
    opacity: .9;
    margin: 0 5px 0 5px;
    font-weight: 500;
    font-size: 14px;
}

.mw-copy-code > svg {
    width: 17px;
    height: 17px;
    stroke: #fff;
    opacity: .9;
}

.mw-inline-link {
    color: var(--racecheck-green);
    font-weight: 500;
    padding: 0;
    text-decoration: none;
}

.mw-inline-link:hover {
    cursor: pointer;
    text-decoration: underline !important;
}

.mw-premium-feature-placeholder-wrapper {
    margin-top: 55px;
    margin-bottom: 45px;
}

.mw-analytics {
    background-color: #fff;
    border-radius: 15px;
}

.mw-analytics-row {
    width: 100%;
    display: flex;
}

.mw-analytics-row:not(:last-child) {
    border-bottom: 1px solid #e8ecee;
}

.mw-analytics-header-row {
    padding: 5px 20px;
    text-align: center;
}

.mw-analytics-header-row > p {
    width: 100%;
    margin: 0;
}

.mw-analytics-header-row > p > .mw-analytics-dates {
    margin-left: 5px;
    margin-top: 3px;
    display: block;
}

.mw-analytics-dates {
    opacity: .7;
    text-transform: none;
    font-size: smaller;
    margin: 0;
}

.mw-analytics-container {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    text-align: center;
    z-index: 99;
}

.mw-analytics-container:not(:last-child) {
    border-right: 1px solid #e8ecee;
}

.mw-analytics-title {
    font-weight: 500;
    color: rgb(146, 146, 146);
    margin: 0 0 10px 0;
    opacity: 1;
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
}

.mw-analytics-header-row > .mw-analytics-title {
    z-index: 0;
}

.mw-analytics-value {
    font-size: 35px;
    font-weight: 700;
    margin: 0 0 10px 0;
}

.mw-analytics-change {
    font-size: 15px;
    opacity: .8;
    color: var(--text-inactive);
    margin: 0;
    font-weight: 500;
}

.mw-analytics-change-value {
    font-weight: 600;
    font-size: 17px;
}

.mw-analytics-change-value-pos {
    color: rgb(0, 153, 51);
}

.mw-analytics-change-value-neg {
    color: rgb(212, 38, 15);
}

.mw-analytics-change-value-null {
    color: rgb(121, 121, 121);
}

.mw-analytics-coming-soon {
    padding: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mw-analytics-coming-soon-title {
    font-size: 17px;
    font-weight: 600;
    color: var(--text-inactive);
    margin: 0 0 8px 0;
    opacity: .8;
}

.mw-analytics-coming-soon-copy {
    color: var(--text-inactive);
    margin: 0;
    opacity: .7;
    width: 60%;
}

.mw-analytics-disclaimer {
    margin: -5px 0 0 10px;
    font-size: smaller;
    opacity: .7;
}

.mw-previous {
    position: relative;
    opacity: 1 !important;
    color: rgb(146, 146, 146);
    text-decoration: underline;
    text-decoration-style: dotted;
    cursor: default;
}

.mw-previous .mw-previous-tooltip, .mw-title-tooltip {
    display: none;
    width: 200px;
    background-color: #fff;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #e8ecee;
    padding: 5px 0;
    position: absolute;
    top: 150%;
    left: 50%;
    margin-left: -100px;
}

.mw-previous .mw-previous-tooltip::after, .mw-title-tooltip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #e8ecee transparent;
}

.mw-previous:hover .mw-previous-tooltip, .mw-analytics-title:hover .mw-title-tooltip {
    display: block;
}

.mw-analytics-tt-data {
    font-size: 30px;
    font-weight: 600;
    margin: 5px 0 0 0;
    color: var(--text-active);
    opacity: 1;
}

.mw-nav-analytics-options {
    display: flex;
}

.mw-nav-analytics-btn:first-child {
    font-size: 11px !important;
}

.mw-nav-analytics-btn {
    width: 30px !important;
    height: 30px !important;
    margin: 4px 0 0 10px !important;
    font-size: 14px;
    font-weight: 800;
}

.mw-nav-analytics-btn:active {
    color: var(--text-normal);
}

.mw-nav-analytics-btn-active {
    border: 2px solid #4abf6c !important;
}

.mw-analytics-help-icon > svg {
    width: 19px;
    height: 19px;
    stroke: rgb(146, 146, 146);
    fill: rgb(146, 146, 146);
    opacity: 1;
    margin-bottom: -3px;
    cursor: pointer;
}

.mw-title-tooltip {
    z-index: 2;
    padding: 5px;
}

.mw-title-desc {
    font-size: 13px;
    text-transform: none;
    font-weight: 500;
    color: var(--text-inactive);
    line-height: normal;
    display: block;
}

.mw-tooltip-extra-row {
    margin-top: 5px;
    padding: 5px 5px 0 5px;
    border-top: 1px dashed #e8ecee;
}

.widget-settings-loader {
    height: 281px !important;
}

.wsl-dark > .lds-roller > div:after {
    background: #344158;
}

.mobile-preview-warning {
    display: none;
    text-align: center;
    padding: 10px;
    border-top: 1px solid #e0e0e0;
}

.mobile-preview-warning > p {
    opacity: .3;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    padding: 0 20px;
}

/* END OF MANAGE WIDGET */

/* FWD TO DEV TEAM */

.fwd-to-dev-info {
    font-size: 14px;
    margin: 8px 0 8px 0 !important;
}

.fwd-to-dev-email-input > div {
    padding: 0 !important;
    border: none !important;
    padding: 0 7px !important;
    height: 32px !important;
    border: 1px solid #e0e6e8 !important;
    border-radius: 3px !important;
    font-family: inherit !important;
}

.fwd-to-dev-email-input > div > div > input {
    font-size: 16px !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
}

.fwd-to-dev-email-input > div:focus {
    border: 1px solid var(--racecheck-green) !important;
}

.fwd-to-dev-email-input > div > div:not(:last-child) {
    background-color: #eeeeee !important;
    font-size: 16px !important;
    padding: 3px 8px !important;
    color: #000 !important;
    margin: 0 10px 0 0 !important;
    font-family: inherit !important;
}

.fwd-to-dev-email-input > div > div:not(:last-child):hover > span {
    color: var(--text-error) !important;
}

/* END OF FWD TO DEV TEAM */

/* WIDGET LIST */

.widget-list-container {
    display: flex;
    max-width: calc(100vw - 230px);
    flex-direction: column;
}

.widget-list-container-main-nav-closed {
    max-width: 100% !important;
}

.widget-list-sublist:not(:last-child) {
    border-bottom: 1px solid #DBDBDB;
    margin-bottom: 15px;
    padding-bottom: 10px;
}

.widget-sublist-title {
    font-size: 25px;
    font-weight: 600;
    color: var(--text-normal);
    margin: 0 15px 15px 0;
}

.widget-list-count {
    color: var(--text-inactive);
    font-size: 16px;
    margin-left: 5px;
    opacity: .6;
}

.widget-list-free-intro {
    margin: -10px 0 15px 0;
    font-size: 15px;
    color: var(--text-inactive);
}

.widget-list-cards {
    display: flex;
    flex-wrap: wrap;
    min-width: 1000px;
}

.widget-list-upgrade-prompt {
    font-size: 15px;
    margin-left: 10px;
    color: var(--text-error);
    opacity: .8;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    margin-bottom: -5px;
}

.wlup-exclamation-point > svg {
    width: 22px;
    height: 22px;
    stroke: var(--text-error);
    opacity: 1;
    margin-bottom: -4px;
    margin-right: 3px;
}

/* PROMPT TO UPGRADE CARD */

.widgets-premium-perks-container {
    width: 936px;
    height: 140px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    margin: 0 0 10px 0;
    padding: 15px 20px;
}

.wppc-perks > ul > li {
    margin: 5px 0 !important;
}

.wppc-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 10px 0;
}

.wppc-cta {
    margin-left: auto;
    margin-right: 10px;
}

.wppc-cta > a {
    text-decoration: none;
}

/* END OF PROMPT TO UPGRADE CARD */

/* MANAGE WIDGET CARD */

.manage-widget-card {
    width: 300px;
    height: 140px;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    margin: 0 18px 18px 0;
    position: relative;
    z-index: 1;
}

.manage-widget-card, .mwc-multirace > .manage-widget-card-inner, .mwc-multirace::after, .mwc-multirace::before {
    transition: transform 0.2s;
    border: 1px solid var(--background-color);
}

.mwc-multirace::before, .mwc-multirace::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

.mwc-multirace::before {
    transform: rotate(-3.5deg);
    background-color: rgb(252, 252, 252);
}

.mwc-multirace::after {
    transform: rotate(3.5deg);
    background-color: rgb(252, 252, 252);
}

.mwc-multirace:hover::before, .mwc-multirace:hover::after {
  transform: none;
}

.manage-widget-card-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    padding: 10px;
    border-radius: 7px;
    z-index: 1;
    background-color: #fff;
}

.mwc-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mwc-status {
    font-size: 14px !important;
}

.mwc-type {
    font-size: 14px;
    color: var(--text-inactive);
    opacity: .6;
    margin: 0 0 0 10px;
}

.mwc-id {
    font-size: 14px;
    color: var(--text-inactive);
    opacity: .6;
    margin: 0 0 0 auto;
}

.mwc-event-name {
    margin: 10px 0;
    padding: 0 5px;
    max-height: 46px;
    overflow: hidden;
}

.mwc-bottom {
    border-top: 1px solid #e8ecee;
    display: flex;
    flex-direction: row;
    padding: 0 5px 0 5px;
}

.mwc-link {
    cursor: pointer;
    position: relative;
    text-decoration: none;
}

.mwc-link:not(:last-child) {
    margin-right: 20px;
}

.mwc-link > p {
    margin: 10px 0 0 0;
    font-weight: 500;
    color: var(--text-inactive);
}

.mwc-link:hover {
    text-decoration: underline !important;
    text-decoration-color: var(--text-inactive);
}

.mwc-extra-count {
    font-weight: 400;
    opacity: .7;
    font-style: italic;
}

.widget-card-loader {
    height: 50px;
    margin: -1px 0 0 0;
    opacity: .9;
    position: absolute;
    right: -38px;
    top: -15px;
}

.eligible-widget-card {
    transition: opacity .18s ease, filter .18s ease;
}

.eligible-widget-card:hover {
    filter: grayscale(0%);
    opacity: 1;
}

.eligible-widget-card-btn {
    color: #fff !important;
    width: 100%;
    margin: 0 !important;
}

.mwc-eligible-bottom {
    padding: 0;
    background-color: #4abf6c;
    text-align: center;
    border-radius: 5px;
}

.mwc-eligible-bottom:hover {
    background-color: var(--racecheck-green-dark);
}

.mwc-eligible-bottom > .mwc-link {
    width: 100%;
    text-decoration: none !important;
    padding: 7px;
}

.upgrade-widget-card {
    width: 300px;
    height: 140px;
    border-radius: 7px;
    overflow: hidden;
    border: 2px dashed #C7C7C7;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upgrade-widget-card:hover {
    background-color: #eff2f3;
}

.uwc-no-hover {
    cursor: default;
}

.uwc-no-hover:hover {
    background: none !important;
}

.upgrade-widget-card > p {
    color: var(--text-inactive);
    text-align: center;
    font-weight: 500;
    margin: 5px 0;
}

.upgrade-widget-card > svg {
    width: 50px;
    height: 50px;
    fill: var(--text-inactive);
    stroke: var(--text-inactive);
    opacity: .8;
    margin-right: 10px;
}

.uwc-counter {
    margin: 0;
    opacity: .4;
    text-transform: uppercase;
    font-size: 14px;
    color: var(--text-inactive) !important;
}

.buy-additional-widgets-card {
    width: 300px;
    height: 140px;
    border-radius: 7px;
    overflow: hidden;
    border: 2px dashed #C7C7C7;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 18px 18px 0;
    padding: 10px;
    background-color: #ebebeb;
}

.buy-additional-widgets-card:hover {
    background-color: #e7e7e7;
}

.buy-additional-widgets-card > svg {
    width: 50px;
    height: 50px;
    fill: var(--racecheck-green);
    stroke: var(--racecheck-green);
    opacity: .9;
    margin-right: 10px;
}

.buy-additional-widgets-card > p {
    color: var(--racecheck-green);
    text-align: center;
    font-weight: 500;
    margin: 5px 0;
}

/* END OF MANAGE WIDGET CARD */

/* END OF WIDGET LIST */

/* UPGRADE WIDGET MODAL */

.upgrade-widget-modal-content {
    margin: 0 !important;
}

.upgrade-widget-event-list {
    overflow: hidden;
}

.uwel-section:not(:last-child) {
    margin-bottom: 40px;
}

.uwel-section > .uwel-list-item:last-child {
    border-bottom: 1px solid #e8ecee
}

.uwel-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    font-weight: 500;
    color: var(--text-inactive);
    border-top: 1px solid #e8ecee;
    position: relative;
    height: 48px;
}

.uwel-list-item:hover {
    background-color: #eff2f3;
    cursor: pointer;
}

.uwel-list-item > .uwel-item-arrow {
    opacity: 0;
}

.uwel-list-item:hover > .uwel-item-arrow {
    opacity: 1;
}

.uwel-list-item > p {
    margin: 0;
}

.widget-modal-loader {
    height: 50px;
    margin: -1px 0 0 0;
    opacity: .9;
    position: absolute;
    right: 0;
    top: -1;
}

.uwel-title {
    text-transform: uppercase;
    margin: 15px 20px 15px 20px !important;
    font-weight: 600;
    font-size: 14px;
    opacity: .6;
}

.uwel-item-arrow {
    font-size: 23px;
}

/* END OF UPGRADE WIDGET MODAL */

.widget-preview-container {
    margin-left: 15px;    
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    width: 100%;
    height: calc(100vh - 151px);
    transition: background-color .2s ease-in-out;
    background-color: #FFF;
    position: relative;
}

.wpc-background-light {
    background-color: #F9F9F9;
}

.wpc-background-dark {
    background-color: #1f232d;
}

.widget-preview-container-free {
    height: calc(100vh - 190px);
}

#racecheck-widget {
    display: none;
    right: 76px !important;
}

.wp-title {
    font-size: 20px;
    font-weight: 500;
    color: var(--text-inactive);
    opacity: .6;
    text-align: center;
    margin: 20px 0 5px 0;
    padding: 0 20px;
}

.wp-intro {
    font-size: 14px;
    color: var(--text-inactive);
    opacity: .6;
    text-align: center;
    margin: 0 auto;
    padding: 0 10px;
    max-width: 80%;
}

.wp-inactive-warning-container {
    width: 100%;
    text-align: center;
    margin: 0;
    background-color: #ffe7e7;
    padding: 10px;
    border-bottom: 1px solid #ffc4c4;
}

.wp-inactive-warning {
    margin: 0;
    font-size: 15px;
    color: var(--text-error);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.wp-warning-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.wp-warning-icon > svg {
    width: 23px;
    height: 23px;
    stroke: var(--text-error);
    fill: var(--text-error);
}

.wpc-header {
    width: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E8ECED;
    background-color: #fff;
    padding: 12px 15px;
    border-radius: 7px 7px 0 0;
    position: sticky;
    top: 0;
    z-index: 10;
}

.wpc-header-left {
    display: flex;
    align-items: center;
}

.wpc-header-left > svg {
    width: 22px;
    height: 22px;
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    stroke-opacity: 1;
}

.wpc-header-left > h3 {
    margin: 0 0 0 10px;
    font-size: 16px;
    font-weight: 600;
    color: var(--text-normal);
}

.wpc-bg-btns {
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid #E8ECED;
    display: flex;
    align-items: center;
}

.wpc-bg-btn {
    width: 22px;
    height: 22px;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wpc-bg-btn:not(:first-child) {
    margin-left: 10px;
}

.wpc-bg-btn-dark {
    background-color: #1f232d;
    border: 1px solid #1f232d;
}

.wpc-bg-btn-light {
    background-color: #F9F9F9;
    border: 1px solid #E8ECED;
}
/* 
.wpc-bg-btn:hover {
    border: 1px solid var(--racecheck-green);
} */

.wpc-bg-btn-selected {
    border: 1px solid var(--racecheck-green);
}

.wpc-bg-btn-selected > svg {
    width: 14px;
    height: 14px;
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
}

.wpc-header > .btn {
    height: 32px !important;
    min-height: 32px !important;
    font-size: 15px;
    width: 200px;
}

.wpc-content {
    padding: 20px 25px;
    width: 100%;
    height: calc(100% - 57px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

#racecheck-review-carousel, #racecheck-free-review-banner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wpc-content-code {
    height: calc(100% - 57px);
    display: block;
    overflow-y: scroll;
}

.wpc-content-section > h1 {
    margin: 0 0 10px 0;
    font-size: 17px;
}

.widget-code-step {
    margin: 0 0 25px 0;
}

.widget-code-step > h2 {
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: 600;
}

.widget-code-step > p, .wpc-content-section > p {
    margin: 0;
}

.widget-code-step > p > .wc-code {
    font-family: monospace;
    background-color: #f5f2eb;
    padding: 2px 3px;
    border-radius: 3px;
    font-weight: 500;
}

.wpc-content-section:not(:first-child) {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #E8ECED;
}

.wpc-content-section:last-child {
    padding-bottom: 20px;
}

.wpc-content-section > .mw-container-footer {
    margin-top: 20px;
}

.manage-widget-header {
    margin: -7px 0 15px 0;
    display: flex;
    align-items: center;
}

.manage-widget-header > h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: var(--text-normal);
}

.manage-widget-header > a {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-normal);
    margin-right: 5px;
    cursor: pointer;
}

.wpc-wireframe {
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: -100; */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    border-radius: 0 0 7px 7px;
}

.wpc-wireframe-light {
    background-color: #f1f1f1;
}

.wpc-wireframe-dark {
    background-color: #14161d;
}

.wpc-w-page {
    width: calc(100% - 30px);
    height: calc(100% - 14px);
    border-radius: 7px 7px 0 0;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
}

.wpc-w-page-reviewbox {
    justify-content: flex-start;
}

.wpc-w-page-reviewbox .wpc-w-bottom {
    margin-top: 15px;
}

.wpc-wireframe-light .wpc-w-page {
    background-color: #F9F9F9;
    box-shadow: 0px 0px 6px -1px rgba(222, 222, 222, 0.75), inset 0 -10px 10px -10px rgba(222, 222, 222, 0.75);
}

.wpc-wireframe-dark .wpc-w-page {
    background-color: #1f232d;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2), inset 0 -10px 10px -10px rgba(0, 0, 0, 0.2);
}

.wpc-w-widgetspacer {
    width: 100%;
    margin: -26px 0 20px 0;
}

.wpc-w-page-carousel .wpc-w-widgetspacer {
    height: 320px;
}

.wpc-w-page-freebanner .wpc-w-widgetspacer {
    height: 75px;
}

.wpc-w-page-reviewbox .wpc-w-widgetspacer {
    display: none;
}

.wpc-w-text {
    font-family: Poppins;
    margin: -5px 0 15px 0;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
}

.wpc-wireframe-light .wpc-w-text {
    color: #D9D9D9;
}

.wpc-wireframe-dark .wpc-w-text {
    color: #3a3f4b;
}

.wpc-w-top > .wpc-w-l4 {
    margin-top: 15px;
}

.wpc-w-bottom > .wpc-w-l4, .wpc-w-bottom > .wpc-w-l3 {
    margin-bottom: 15px;
}

.wpc-w-line {
    display: flex;
    flex-wrap: wrap;
}

.wpc-w-textblock {
    height: 15px;
    border-radius: 2px;
    margin: 0 10px 10px 0;
    transition: all 0.2s ease-in-out;
}

.wpc-wireframe-light .wpc-w-textblock {
    background-color: #D9D9D9;
    opacity: .3;
}

.wpc-wireframe-dark .wpc-w-textblock {
    background-color: #3a3f4b;
    opacity: .5;
}

.wpc-w-tb-1 {
    width: 100px;
}

.wpc-w-tb-2 {
    width: 160px;
}

.wpc-w-tb-3 {
    width: 190px;
}

@media screen and (max-height: 800px) {
    .wpc-w-l3 {
        display: none;
    }
}

@media screen and (max-height: 750px) {
    .wpc-w-l2 {
        display: none;
    }
}

@media screen and (max-height: 650px) {
    .wpc-w-top, .wpc-w-bottom, .wpc-w-widgetspacer {
        display: none;
    }
}

/* WIDGET LIBRARY */

.widget-library-container {
    display: flex;
    margin: 0 auto;
    max-width: 750px;
    flex-direction: column;
}

.wl-section {
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    width: 100%;
    background-color: #FFF;
    position: relative;
    padding: 15px 15px 15px 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
}

.wl-section-group > .wl-section {
    margin-bottom: 0 !important
}

.wl-section:not(:last-child) {
    margin-bottom: 15px;
}

.wl-section-group > .mr-section-bottom-info {
    background-color: rgba(251, 251, 251, 0.6);
    border: 2px dashed #E8ECED;
    margin: -8px 0 0 0;
}

.wl-section-group > .mr-section-bottom-info > .mr-info-left > svg {
    margin-right: 10px;
    width: 28px;
    height: 28px;
}

.wl-s-copy {
    margin-right: 25px;
    padding-top: 7px;
}

.wl-s-copy > h1 {
    font-size: 17px;
    margin: 8px 0 5px 0;
    display: flex;
    align-items: flex-start;
}

.wl-s-copy > h1 > svg {
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    width: 17px;
    height: 17px;
    margin-left: 4px;
}

.wl-s-copy > p {
    color: var(--text-inactive);
    margin: 0;
    font-size: 15px;
    opacity: .8;
}

.wl-s-widget-list {
    display: flex;
    flex-wrap: wrap;
    width: 508px;
    min-width: 508px;
}

.wl-card-link {
    text-decoration: none;
    color: inherit;
}

.wl-widget-card {
    width: 240px;
    height: 160px;
    margin: 7px;
    border-radius: 7px;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    background-color: #F3F3F3;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #e8eced80;
}

.wl-widget-card-locked {
    cursor: not-allowed;
}

.wl-widget-card > .wc-header {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.wl-widget-card > .wc-header > img {
    width: 80%;
    margin-bottom: -5px;
}

.wl-widget-card > .wc-footer {
    background-color: #fff;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 7px 7px;
    border-top: 1px solid #e8eced80;
}

.wl-widget-card > .wc-footer > h2 {
    font-size: 15px;
    margin: 0;
    font-weight: 600;
}

.wl-widget-card > .wc-footer > svg {
    width: 13px;
    height: 13px;
    color: var(--text-inactive);
    stroke: var(--text-inactive);
    transition: all ease-in-out .2s;
}

.wl-widget-card > .wc-footer > .wc-footer-lock {
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
    height: 17px;
    width: 17px;
}

.wl-widget-card:hover > .wc-footer > svg {
    color: var(--racecheck-green);
    stroke: var(--racecheck-green);
}

/* END OF WIDGET LIBRARY */

.mw-free-placeholder {
    width: 60%;
    margin: 0 auto;
}

/* RESPONSIVE */

@media screen and (max-width: 900px) {

    .mw-free-placeholder {
        width: 95%;
        margin: 0 auto;
    }

    /* WIDGET LIBRARY */

    .wl-section {
        padding: 15px;
    }

    .wl-s-widget-list {
        width: 100%;
        min-width: 100%;
    }

    .wl-widget-card {
        width: auto;
        height: auto;
    }

    .wl-widget-card > .wc-header > img {
        width: 75%;
        margin-bottom: -6px;
    }

    /* END OF WIDGET LIBRARY */

    /* WIDGET LIST */

    .widget-list-upgrade-prompt {
        margin: 5px 0 10px 0;
    }

    .widget-list-cards {
        flex-direction: column;
        min-width: 100%;
    }

    .manage-widget-card, .buy-additional-widgets-card {
        width: 100%;
        margin: 0 0 18px 0;
    }

    .widgets-premium-perks-container {
        width: 100%;
        height: 100%;
        display: block;
    }

    .wppc-cta {
        margin-top: 20px;
        margin-right: 0;

    }

    .wppc-cta > a > .btn {
        width: 100%;
    }

    .upgrade-widget-card {
        width: 100%;
    }

    /* END OF WIDGET LIST */

    /* WIDGET LIBRARY */

    .wl-section {
        flex-direction: column;
    }

    .wl-s-copy {
        margin-bottom: 15px;
    }

    /* END OF WIDGET LIBRARY */

    /* MANAGE WIDGET */

    .manage-widget-header > h2 {
        margin-bottom: 10px;
    }

    .manage-widget-status-tag, .mw-widget-id, .mw-widget-type {
        display: inline;
    }

    .mw-widget-id {
        margin-left: 5px;
    }

    .widgets-container {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .mw-setup-code {
        padding: 15px 20px !important;
    }

    .mw-setup-row {
        width: 100%;
    }

    .mw-setup-options-container > .inline-select-container {
        justify-content: space-between;
        position: relative;
    }

    .mw-container-footer > .modal-btn-row {
        justify-content: space-around;
    }

    .mw-setup-tooltip-text {
        position: absolute;
        width: 100%;
        right: 10px;
        top: -35px;
    }

    .mw-setup-structured-tooltip {
        position: absolute;
        width: 100%;
        right: 10px;
        left: auto;
        top: -90px;
    }

    .mw-setup-structured-tooltip > p > br {
        display: none;
    }

    .mw-setup-url-tooltip {
        position: absolute;
        width: 100%;
        right: 10px;
        left: auto;
        top: -60px;
    }

    .mw-setup-tooltip-text:before {
        display: none;
    }

    .widget-preview-container {
        display: none;
    }

    .mw-analytics-row {
        flex-direction: column;
    }

    .mw-analytics-container {
        border: none !important;
        padding: 15px 20px;
        width: 100%;
    }

    .mw-soc-static {
        display: none;
    }

    .mw-soc-mobile {
        display: block;
    }

    .mobile-preview-warning {
        display: block;
    }

    /* END OF MANAGE WIDGET */

}

@media screen and (max-width: 565px) {

    .mw-free-placeholder > .analytics-placeholder-container > .btn {
        width: 95%;
    }
}

/* END OF RESPONSIVE */