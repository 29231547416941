.cookie-banner {
    /* position: fixed;
    top: 0;
    left: 0;
    z-index: 100000000000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(22,29,38,0.78); */
}

.cookie-banner-content {
    width: 660px;
    padding: 20px;
    margin: 20px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #ececec;
    box-shadow: 0px 0px 6px -2px rgba(184, 184, 184, 0.5);
    text-align: left;
}

.cookie-banner-content > h1 {
    padding: 0 5px;
    font-size: 22px;
    font-weight: 600;
    margin: 0 0 15px 0;
}

.cookie-banner-content > p {
    margin: 0;
    padding: 0 5px;
}

.cookie-banner-content > p > a {
    color: var(--racecheck-green);
}

.cookie-banner-content > p > a:hover {
    text-decoration: underline !important;
}

.cookie-banner-content > ul {
    margin: 15px 0 15px 20px;
    padding: 0 5px;
    list-style-type: initial !important;
    list-style: initial !important;
}

.cookie-banner-content > ul > li {
    list-style: initial !important;
}

.cookie-banner-content > ul > li:not(:last-child) {
    margin-bottom: 5px;
}

.cookie-banner-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;
}

.cookie-banner-btns > button {
    width: 50%;
    margin: 5px;
    font-size: 15px;
    padding: 5px;
}

.cookie-banner-btns > button:hover {
    text-decoration: none !important;
}

/* .btn-secondary {
    color: #2f2f2f !important;
    background-color: #fff;
}

.btn-secondary:hover {
    background-color: #eeeeee;
} */

@media screen and (max-width: 600px) {
    .cookie-banner-btns {
        flex-direction: column;
    }

    .cookie-banner-btns > .btn {
        width: 100% !important;
    }
}